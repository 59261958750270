import { Component, OnInit, Inject, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { Constant } from './../../constant';

@Component({
  selector: 'app-multiple-select-dialog',
  templateUrl: './multiple-select-dialog.component.html',
  styleUrls: ['./multiple-select-dialog.component.css']
})
export class MultipleSelectDialogComponent implements OnInit, AfterViewInit {
  public companyMaster;
  public form: FormGroup;
  public isMultiRow = false;
  public title = '';
  public fontSize = Constant.elementValGeneralFontSize;
  public rowWidth = Constant.elementVal4RowWidth;
  private currentData;
  private htmlElement;

  constructor(
    public dialogRef: MatDialogRef<MultipleSelectDialogComponent>,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.companyMaster = data.masterData;
    this.currentData = data.currentData;
    this.isMultiRow = data.isMultiRow;
    this.isMultiRow = data.isMultiRow;
    this.fontSize = data.fontSize;
    this.rowWidth = data.rowWidth;
    this.title = data.title;

    this.htmlElement = elementRef.nativeElement;
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.companyMaster.length; i++) {
      const elementId = Constant.elementIdItem + i;
      const itemElement = this.htmlElement.querySelector(elementId);
      const labelElement = this.htmlElement.querySelector(elementId + ' .mdc-label');
      this.renderer.setStyle(labelElement, Constant.elementfontSize, this.fontSize);
      this.renderer.setStyle(itemElement, Constant.elementWidth, this.rowWidth);
    }
  }
  // publicメソッド
  public onOK() {
    const checkedIds = [];
    for (let i = 0; i < this.form.value.datas.length; i++) {
      if (this.form.value.datas[i]) {
        checkedIds.push(this.companyMaster[i].id);
      }
    }
    this.dialogRef.close(checkedIds);
  }
  public onCancel() {
    this.dialogRef.close(null);
  }

  // privateメソッド
  private initForm() {
    const checkboxs = this.companyMaster.map(element => {
      const selected = this.checkDefault(element.id);
      return new FormControl(selected);
    });
    this.form = new FormGroup({
      datas: new FormArray(checkboxs)
    });
  }

  private checkDefault(id) {
    let isChecked = false;
    const currentDatas = this.currentData.split(',');
    const result = currentDatas.filter(function(selectedId) {
      return Number(selectedId) === id;
    });
    if (result.length > 0) {
      isChecked = true;
    }
    return isChecked;
  }

  getFormData() {
    return (<FormArray>this.form.get('datas')).controls;
  }
}
