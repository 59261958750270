import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { Constant } from './../../constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';

@Component({
  selector: 'app-talenttag-edit-dialog',
  templateUrl: './talenttag-edit-dialog.component.html',
  styleUrls: ['./talenttag-edit-dialog.component.css']
})
export class TalenttagEditDialogComponent implements OnInit {
  public form: FormGroup;
  public showSpinner = true;
  public isSaving = false;
  public btnEnable = false;
  public tags;
  public orderNum = Array(30);  // 表示順選択リスト用
  public titleMaxLen = 20;

  constructor(
    private dialogRef: MatDialogRef<TalenttagEditDialogComponent>,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private formBuilder: FormBuilder,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getMaster();
    this.initForm();
  }

  // 保存
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    let delDialog = false;

    const sendData = [];
    for (let i = this.lists.value.length - 1; i >= 0; i--) {
      // 新規追加し、削除したものは除く
      if (this.lists.value[i].del_flg === 1) {
        delDialog = true;
        if (!this.lists.value[i].id ) {
          continue;
        }
      }
      sendData.push(
        {id: this.lists.value[i].id, title: this.lists.value[i].title, order_no: this.lists.value[i].order_no, del_flg: this.lists.value[i].del_flg}
      );
    }

    if (delDialog) {
      // 削除確認ダイアログ表示
      const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
        width: Constant.dialogWidth,
        autoFocus: false,
        data: { msg: Constant.msgConfirmDelTalenttag }
      });
      dialogRef.afterClosed().subscribe(isOK => {
        if (isOK) {
          // 登録実行
          this.postTagMaster(sendData);
        }
      });
    } else {
      this.postTagMaster(sendData);
    }
  }

  // 追加
  onAdd() {
    const item = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(this.titleMaxLen)]],
      order_no: [0],
      id: [''],
      del_flg: [0],
      cnt: ['0人']
    });

    // フォーム（中身）をフォーム（枠）に追加します
    this.lists.insert(0, item);
  }

  // 削除
  onDel(index) {
    const row = document.querySelector('.row-' + index);
    row.classList.add('del');

    // 必須エラーを外すため、disabledにする
    this.lists.controls[index]['controls'].title.disable();
    this.lists.controls[index]['controls'].order_no.disable();

    // 削除フラグをセット
    this.lists.value[index].del_flg = 1;

  }

  // 削除の取消
  onDelCancel(index) {
    const row = document.querySelector('.row-' + index);
    row.classList.remove('del');

    this.lists.controls[index]['controls'].title.enable();
    this.lists.controls[index]['controls'].order_no.enable();
    this.lists.value[index].del_flg = 0;
  }

  private initForm() {
    this.form = this.formBuilder.group({
      lists: this.formBuilder.array([]),
    });
  }

  // マスタ取得
  private getMaster() {
    Auth.currentSession().then(session => {
      const apiPath = '/talenttag/master';
      const options = this.auth.createApiHeader(session);

      this.commonUtil.apiGet(apiPath, options).then(res => {
        this.tags = res.data;
        this.createFormData();
        this.showSpinner = false;
        this.btnEnable = true;
        if (this.tags.length === 0) {
          // 追加
          this.onAdd();
        }
      })
      .catch(err => {
        this.showSpinner = false;
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  // 登録
  private postTagMaster(sendData) {
    this.isSaving = true;
    Auth.currentSession().then(session => {
      const apiPath = '/talenttag/master';
      const options = this.auth.createApiHeader(session);
      options['body'] = {tags: sendData};

      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
        // ダイアログ閉じる
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.toastUtil.clearAllShowingToast();
        // 保存失敗エラー
        if (err.message === Constant.msgResultSaveFailed) {
          this.toastUtil.showErrorToast('', Constant.msgResultSaveFailed, Constant.toastShowMiliSec);
          this.dialogRef.close(true);
        } else {
          this.isSaving = false;
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
        }
      });
    });
  }

  private createFormData() {
    this.tags.forEach((obj) => {
      const item = {
        title: ['', Validators.required],
        order_no: [obj.order_no],
        id: [obj.id],
        del_flg: 0,
        cnt: [obj.cnt.toString() + '人'],
      }
      const formItem = this.formBuilder.group(item);
      setTimeout(() => {
        formItem.patchValue({
          title: obj.title
        })
      });
      this.lists.push(formItem);
    });
  }

  get lists(): FormArray {
    return this.form.get('lists') as FormArray;
  }

}
