import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Constant } from './../constant';

export interface OnBeforeunload {
  shouldDeleteLocalStorageBeforeunload: () => void;
}

@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<OnBeforeunload> {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated().pipe(
      tap(loggedIn => {
        const companyCode = localStorage.getItem(Constant.lsCompanyCode);
        if (!loggedIn || companyCode === '') {
          this.auth.toLoginPage();
        }
      })
    );
  }

  canDeactivate(component: OnBeforeunload) {
    // 各画面側でローカルストレージをクリアする
    component.shouldDeleteLocalStorageBeforeunload();
    return true;
  }
}
