import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { Constant } from './../../constant';

@Component({
  selector: 'app-talent-del-dialog',
  templateUrl: './talent-del-dialog.component.html',
  styleUrls: ['./talent-del-dialog.component.css']
})
export class TalentDelDialogComponent implements OnInit {
  public message;
  public safeHtml: SafeHtml;
  public showSpinner = false;
  private talentId;

  constructor(
    public dialogRef: MatDialogRef<TalentDelDialogComponent>,
    private domSanitizer: DomSanitizer,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.msg;
    this.talentId = data.talentId;

    // メッセージテキストの一部色変更のためにHTMLコードを変換
    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close(null);
  }

  onOK() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    // タレント削除
    this.showSpinner = true;
    this.delTalentProfile();

  }

  // タレントカルテ削除実行
  private delTalentProfile() {
    Auth.currentSession().then(session => {
      const apiPath = '/talentprofile';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: this.talentId
      };

      this.commonUtil
        .apiDel(apiPath, options)
        .then(res => {
          // 削除成功
          this.dialogRef.close(true);

        })
        .catch(err => {
          // 削除失敗
          this.commonUtil.debug().log(err);
          this.dialogRef.close(false);
        });
    });
  }

}
