import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { environment } from './../../../environments/environment';
import { ImageUtil } from './../../util/image-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { PdfviewerDialogComponent } from '../pdfviewer-dialog/pdfviewer-dialog.component';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-company-message-dialog',
  templateUrl: './company-message-dialog.component.html',
  styleUrl: './company-message-dialog.component.css'
})
export class CompanyMessageDialogComponent implements OnInit {
  public form: FormGroup;
  public companyInfo;
  public readonly Constant = Constant;
  public isSaving = false;
  public showSpinner = true;
  public companyDocumentPath = null;
  public companyDocumentTotalPage = 0;
  public companyDocumentPage = 1;
  public showDocumentSpinner = false;
  private apiPath = '/company';
  private companyDocumentData = null;
  private uploadedCompanyDocumentPath = null;

  public title = 'Message（トップページ）'
  public maxLenHeadline = 50;
  public maxLenHeadDesc = 300;

  @ViewChild('documentFileInput')
  private documentFileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<CompanyMessageDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getCompanyData();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();
    if (this.companyDocumentData) {
      // PDFが変更されていた場合はS3に保存し、データを更新
      const config = environment.amplify.Storage.companyDocument;
      this.uploadPdfData(this.companyDocumentData, config);
    } else {
      this.dataUpdate();
    }
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.companyMsgConfirmEditCancel, this.title), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // 画像選択
  onChangeImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function() {
      // 表示前にファイル形式チェック
      if (file.type !== Constant.fileFormatPdf) {
        this.toastUtil.showErrorToast('', Constant.msgErrorInvalidFile, Constant.toastShowMiliSec);
        return;
      }
      this.companyDocumentPath = fileReader.result;
      this.companyDocumentData = file;
      this.companyDocumentPage = 1;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 画像削除
  onImageDelete() {
    this.companyDocumentPath = '';
    this.companyDocumentData = null;
    this.documentFileInput.nativeElement.value = '';
  }

  // PDF関連
  public pdfLoadComplete(pdfData) {
    this.companyDocumentTotalPage = pdfData.numPages;
    this.showDocumentSpinner = false;
  }
  public pdfError() {
    if (this.companyDocumentTotalPage === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
    }
  }
  public prevBtn() {
    this.companyDocumentPage--;
  }
  public nextBtn() {
    this.companyDocumentPage++;
  }
  // PDF全画面ダイアログ表示
  public onCompanyPdf() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(PdfviewerDialogComponent, {
      width: '100vw',
      maxWidth: '100vw',
      autoFocus: false,
      disableClose: true,
      panelClass: 'pdf-dialog',
      data: { url: this.companyDocumentPath }
    });
  }

  // PDFをS3にアップロードする
  private uploadPdfData(pdfData, config) {
    this.imageUtil.uploadPdfDataToS3(pdfData, config).then(pdf_path => {
      this.uploadedCompanyDocumentPath = pdf_path;
      this.dataUpdate();
    })
    .catch(err => {
      this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSecErr);
      this.isSaving = false;
    });
  }


  // 企業情報を取得する
  private getCompanyData() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          console.log(res.data)
          if (res.data) {
            this.companyInfo = res.data.main;
            this.getImageData();
            this.initForm();

          }
          this.commonUtil.debug().log(this.companyInfo);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }


  // 画像データを取得する
  private getImageData() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const companyimageApiPath = this.apiPath + '/url';

      this.commonUtil
        .apiGet(companyimageApiPath, options)
        .then(res => {
          this.showSpinner = false;
          if (res.data.document_path !== '') {
            this.companyDocumentPath = res.data.document_path;
            this.showDocumentSpinner = true;

            // 30秒読み込みできなければネットワークエラー表示
            setTimeout(() => {
              this.pdfError();
            }, Constant.apiTimeoutSecond);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.dialogRef.close();
        });
    });
  }

  private initForm() {
    this.form = new FormGroup({
      headline: new FormControl(this.companyInfo.message_headline, [Validators.required, Validators.maxLength(this.maxLenHeadline)]),
      desc: new FormControl(this.companyInfo.message_desc, [Validators.required, Validators.maxLength(this.maxLenHeadDesc)]),
    });
  }

  // 更新実行
  private dataUpdate() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    Auth.currentSession().then(session => {
      const pdfPath = this.getImagePathParam();

      const options = this.auth.createApiHeader(session);
      options['body'] = {
        type: Constant.companyUpdateTypeMessage,
        document_path: pdfPath,
        message_headline: this.commonUtil.replaceSpace(this.form.value.headline),
        message_desc: this.commonUtil.replaceSpace(this.form.value.desc),
      };
      this.commonUtil
        .apiPut(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
            this.dialogRef.close(res.status);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
          this.isSaving = false;
        });
    });
  }


  private getImagePathParam() {
    if (this.uploadedCompanyDocumentPath) {
      // PDFが更新されていた場合
      return this.uploadedCompanyDocumentPath;
    }
    if (this.companyDocumentPath === '') {
      // PDFが削除されていた場合
      return this.companyDocumentPath;
    } else {
      // 変更なし
      return this.companyInfo.document_path;
    }
  }

}

