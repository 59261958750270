<div class="title-area">
  <h2 mat-subheader class="title">告知用URL（エントリーページ）</h2>
  <span class="btn-new">
    <button mat-raised-button type="button" color="warn" (click)="onEdit()" [disabled]="showSpinner || notices?.length >= noticesMaxLen">新規作成</button>
    <button mat-raised-button type="button" (click)="onQuestion()" [disabled]="showSpinner" class="quest-btn">質問 管理</button>
  </span>
</div>
<div class="desc">
  追加で作成したエントリーページでは、タレントプール登録の前に「アンケート」が表示されます。 <br>
  アンケートに「はい」と回答があれば、「タイトル」が接点・経路タグとしてタレントに付与され、紐付けてある「質問」が表示されます。 <br>
  デフォルトURLのエントリーページに設定した質問は、つながった全てのタレントに共通で表示されます。<br>
  <a [routerLink]="['/company']">企業情報</a>を登録していない場合、エントリーページは表示されません。
</div>

<div *ngIf="showSpinner" class="spinner"><mat-spinner></mat-spinner></div>
<div class="count" *ngIf="!showSpinner && notices">{{ notices.length }} / {{ noticesMaxLen }}</div>
<table *ngIf="!showSpinner && notices" class="notice">
  <ng-container *ngFor="let notice of notices; index as idx">
    <div class="row-spacer"></div>
    <tr class="inuse{{ notice.inuse }}">
      <td rowspan="3" class="use" *ngIf="notice.inuse == 1">
        <img
          src="assets/notice/use-check.png"
          srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x
          "
        /><br />利用中
      </td>
      <td rowspan="3" class="use" *ngIf="notice.inuse == 0">
        <img
          src="assets/notice/stop-check.png"
          srcset="
            assets/notice/stop-check.png    1x,
            assets/notice/stop-check@2x.png 2x,
            assets/notice/stop-check@3x.svg 3x
          "
        /><br />停止中
      </td>
      <td class="question" colspan="6">
        <div class="question-title" *ngIf="!notice.tag">
          デフォルトURLのエントリーページ
        </div>
        <div class="question-desc gray" *ngIf="!notice.tag">※ タレントにアンケートは表示されず、接点・経路タグも付与されません。</div>
        <div class="question-title" *ngIf="notice.tag">{{ notice.title }}</div>
        <div class="flex" *ngIf="notice.question">
          <div class="label-enq">アンケート：</div>
          <div class="question-desc enq">{{ notice.question }}</div>
        </div>
        <div class="flex" *ngIf="notice.add_question">
          <div class="label-quest">質問：</div>
          <div class="question-add">
            <ng-container *ngFor="let q of notice.add_question_ary; last as last">
              <span class="item">{{ q }}<ng-container *ngIf="!last">、</ng-container></span>
            </ng-container>
          </div>
        </div>
      </td>
      <td class="btn-edit">
        <div class="menu-text">
          <span>Menu</span>
        </div>
        <ng-container *ngIf="notice.title">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" overlapTrigger="false">
            <button mat-menu-item (click)="onEdit(idx)">編集</button>
            <button mat-menu-item (click)="onUse(idx, false)" *ngIf="notice.inuse == 1">停止する</button>
            <button mat-menu-item (click)="onUse(idx, true)" *ngIf="notice.inuse == 0">利用する</button>
            <button mat-menu-item (click)="onDel(idx)">削除</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="!notice.title">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" overlapTrigger="false" class="notice-menu">
            <button mat-menu-item (click)="onEdit(idx)">編集</button>
          </mat-menu>
        </ng-container>
      </td>
    </tr>
    <tr class="inuse{{ notice.inuse }}">
      <td class="url-title">URL</td>
      <td colspan="3" class="url">
        <a href="{{ notice.url }}" target="_blank">{{ notice.url }}</a><br>
        <span class="url-login">※ログインフォーム {{ notice.login_url }}</span>
      </td>
      <td class="url-copy">
        <div class="copy" (click)="onCopy('url', idx)">
          <img
            src="assets/notice/copy-icon.png"
            srcset="
              assets/notice/copy-icon.png    1x,
              assets/notice/copy-icon@2x.png 2x,
              assets/notice/copy-icon@3x.svg 3x
            "
          />
          URLをコピー
        </div>
        <input id="url-code-{{ idx }}" type="hidden" value="{{ notice.url }}" />
      </td>
      <td class="pageview" colspan="2" rowspan="2">
        <table class="pageview-table">
          <tr class="month-title">
            <th></th>
            <td>今月</td>
            <td>先月</td>
            <td>先々月</td>
            <td>累計</td>
          </tr>
          <tr [class.border-bottom]="notice.title" class="title"><td rowspan="2">閲覧数</td></tr>
          <tr [class.border-bottom]="notice.title">
            <td>{{ notice.cnt1 }}</td>
            <td>{{ notice.cnt2 }}</td>
            <td>{{ notice.cnt3 }}</td>
            <td>{{ notice.cnt_all }}</td>
          </tr>
          <ng-container *ngIf="notice.title">
            <tr class="padding-top title"><td rowspan="3">タグの<br>付与数</td></tr>
            <tr class="padding-top cnt-tag">
              <td>{{ notice.cnt_tag1 }}</td>
              <td>{{ notice.cnt_tag2 }}</td>
              <td>{{ notice.cnt_tag3 }}</td>
              <td>{{ notice.cnt_tag_all }}</td>
            </tr>
            <tr class="cnt-tag">
              <td>（<a (click)="onToList(notice.id, Constant.tlPeriodTypeThisMonth)">{{ notice.cnt_tag_talent1 }}</a>）</td>
              <td>（<a (click)="onToList(notice.id, Constant.tlPeriodTypeLastMonth)">{{ notice.cnt_tag_talent2 }}</a>）</td>
              <td>（<a (click)="onToList(notice.id, Constant.tlPeriodTypeTwoMonthAgo)">{{ notice.cnt_tag_talent3 }}</a>）</td>
              <td>（<a (click)="onToList(notice.id)">{{ notice.cnt_tag_talent_all }}</a>）</td>
            </tr>
          </ng-container>
        </table>
      </td>
    </tr>
    <tr class="inuse{{ notice.inuse }}">
      <td class="qr" colspan="2"><img src="{{ notice.qr_path }}" /></td>
      <td class="qr-text">
        <div class="copy" (click)="onQrDownload(idx)"><img src="assets/notice/kakudai.png" /> 拡大表示</div>
        <div class="copy" (click)="onCopy('qr', idx)">
          <img
            src="assets/notice/copy-icon.png"
            srcset="
              assets/notice/copy-icon.png    1x,
              assets/notice/copy-icon@2x.png 2x,
              assets/notice/copy-icon@3x.svg 3x
            "
          />
          埋め込みタグをコピー
        </div>
        <input
          id="qr-code-{{ idx }}"
          [hidden]="true"
          type="text"
          value="<img src=&quot;{{ notice.qr_path }}&quot; />"
          readonly
          (click)="onCodeClick($event)"
        />
      </td>
      <td class="button" colspan="2">
        <button
          (click)="open(idx)"
          style="width: 160px; height: 40px; color: #fff; background: #5269FF;
        border-radius: 100px; border-style: hidden;"
        >
          つながる
        </button>
        <div class="copy" (click)="onCopy('btn', idx + '2')">
          <img
            src="assets/notice/copy-icon.png"
            srcset="
              assets/notice/copy-icon.png    1x,
              assets/notice/copy-icon@2x.png 2x,
              assets/notice/copy-icon@3x.svg 3x
            "
          />
          埋め込みタグをコピー
        </div>
        <input
          id="btn-code-{{ idx }}2"
          [hidden]="true"
          type="text"
          value="<button onclick=&quot;window.open('{{
            notice.url
          }}')&quot; style=&quot;width: 160px; height: 40px; color: #fff; background: #5269FF; border-radius: 100px; border-style: hidden;&quot;>つながる</button>"
          readonly
          (click)="onCodeClick($event)"
        />
      </td>
    </tr>
  </ng-container>
</table>
<div class="bottom-text" *ngIf="!showSpinner && notices && notices.length>1">
  「タグの付与数」の上段は「全体数」で、タレントプール解除済のタレントに付与した数を含みます。<br>
  下段の（　）内は、タレントプール登録中のタレントに付与している接点・経路タグの数です。
</div>
