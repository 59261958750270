import { Component, OnInit } from '@angular/core';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';

@Component({
  selector: 'app-lp-newgrad',
  templateUrl: './lp-newgrad.component.html',
  styleUrls: ['./lp-newgrad.component.css']
})
export class LpNewgradComponent implements OnInit {
  public readonly Constant = Constant;

  constructor(
    private commonUtil: CommonUtil,
  ) { }

  ngOnInit(): void {
  }

  // リンククリック時のAnalyticsEvent
  onClickGAEvent(action) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, action);
  }

}
