<div class="title-area">
  <h2 mat-subheader class="title">企業情報</h2>
  <div class="btn-new">
    <button mat-raised-button [disabled]="isLoading || !companyInfo" (click)="onChangeColor()">カラー変更</button>
  </div>
</div>
<div class="desc">
  <a [routerLink]="['/notice_url']">エントリーページ</a>を公開するために、Profile、Message、About、を登録してください。
</div>
<div *ngIf="isLoading" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!isLoading && companyInfo">
  <div class="btn-area">
    <div>
      <button mat-raised-button type="button" (click)="onProfile()">
        Profile<br>企業概要
      </button>
      <div class="check" *ngIf="registProfile">
        <img src="assets/notice/use-check.png" srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x
          " />
      </div>
    </div>
    <div>
      <button mat-raised-button type="button" (click)="onMessage()">
        Message<br>トップページ
      </button>
      <div class="check" *ngIf="registMessage">
        <img src="assets/notice/use-check.png" srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x
          " />
      </div>
    </div>
    <div>
      <button mat-raised-button type="button" (click)="onAbout()">
        About<br>詳細ページ
      </button>
      <div class="check" *ngIf="registAbout">
        <img src="assets/notice/use-check.png" srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x
          " />
      </div>
    </div>
  </div>
  <div class="msg-area" *ngIf="!registAll">
    エントリーページは公開されていません。
  </div>
  <div class="msg-area blue" *ngIf="registAll">
    エントリーページは公開されています。
  </div>
</ng-container>
