import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from './../../auth/auth.service';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'app-question-edit-dialog',
  templateUrl: './question-edit-dialog.component.html',
  styleUrls: ['./question-edit-dialog.component.css']
})

export class QuestionEditDialogComponent implements OnInit {
  public readonly Constant = Constant;
  public form: FormGroup;
  public question;
  public orderNum = Array(20);
  public isSaving = false;
  public selectItemAry;
  private selectItemLen = 20;

  // validation
  public maxLenTitle = 15;
  public maxLenQuestion = 200;
  public maxLenTypeItem = 30;

  constructor(
    public dialogRef: MatDialogRef<QuestionEditDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.question = data.question;
    if (this.question.select_item.length === 0) {
      // デフォルトの選択肢作成（order_no1,2は入力済みにする）
      this.selectItemAry = [];
      for (let i = 0; i < this.selectItemLen; i++) {
        this.selectItemAry.push({
          item_no: i + 1,
          item: '',
          order_no: i > 1 ? 0 : i + 1
        });
      }
    } else {
      this.selectItemAry = this.question.select_item;
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.dataUpdate();

  }

  // 保存ボタンの無効化制御
  disabledSaveBtn() {
    if (this.form.invalid || this.isSaving) {
      return true;
    }

    // テキスト入力の場合チェックなし
    if (this.form.value['type'] === Constant.aqAnswerTypeTextId) {
      return false;
    }

    // 単一選択or複数選択の場合
    let cnt = 0;
    for (let i = 1; i <= this.selectItemLen; i++) {
      // 表示順ありでタイトル未入力の場合エラー
      if (this.form.value['itemorderno_' + i] > 0 && this.form.value['itemtitle_' + i] === '') {
        return true;
      }
      // 入力済み選択肢をカウント
      if (this.form.value['itemorderno_' + i] > 0 && this.form.value['itemtitle_' + i] !== '') {
        cnt++;
      }
    }
    // 2つ以上あればエラーなし
    if (cnt >= 2) {
      return false;
    }

    return true;
  }

  onTypeChange(type) {
    if (type === Constant.aqAnswerTypeTextId) {
      this.selectItemAry.forEach(item => {
        this.form.controls['itemtitle_' + item.item_no].setErrors(null);
      });
    }
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    const formControl = {};
    this.selectItemAry.forEach(item => {
      formControl['itemorderno_' + item.item_no] = new FormControl(item.order_no);
      formControl['itemtitle_' + item.item_no] = new FormControl(item.item, [Validators.maxLength(this.maxLenTypeItem)]);
    });
    formControl['title'] = new FormControl(this.question.title, [Validators.required, Validators.maxLength(this.maxLenTitle)]);
    formControl['question'] = new FormControl(this.question.question, [Validators.required, Validators.maxLength(this.maxLenQuestion)]);
    formControl['type'] = new FormControl(this.question.type, [Validators.required]);
    formControl['order_no'] = new FormControl(this.question.order_no, [Validators.required]);

    this.form = new FormGroup(formControl);
  }

  // 保存実行
  private dataUpdate() {
    const apiPath = '/question';

    // 選択肢データ作成
    const selectAry = [];
    this.selectItemAry.forEach(item => {
      if (this.form.value.type !== Constant.aqAnswerTypeTextId) {
        const select = {
          order_no: this.form.value['itemorderno_' + item.item_no] === 0 ? null : this.form.value['itemorderno_' + item.item_no],
          item: this.commonUtil.replaceSpace(this.form.value['itemtitle_' + item.item_no]),
          item_no: item.item_no
        };
        selectAry.push(select);
      }
    });

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        title: this.commonUtil.replaceSpace(this.form.value.title),
        question: this.commonUtil.replaceSpace(this.form.value.question),
        type: this.form.value.type,
        order_no: this.form.value.order_no,
        select_item: selectAry
      };

      if (this.question.id) {
        // 更新
        options['body']['id'] = this.question.id;
      }

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.toastUtil.showInformationToast(
            Constant.empty,
            Constant.msgResultSaveSuccess,
            Constant.toastShowMiliSec
          );
          this.dialogRef.close(res.status);
          this.isSaving = false;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.data?.cnt === 0 || err.data?.max === true) {
            // 削除済み
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgResultSaveFailed,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(true);
          } else {
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgSaveFailedRetry,
              Constant.toastShowMiliSec
            );
          }

          this.isSaving = false;
        });
    });
  }

}
