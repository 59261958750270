<div class="title-area">
  <h2 mat-subheader>タレントリスト</h2>
  <div class="btn-area">
    <a (click)="onCsvTalent()" class="link-csv" [class.disabled]="showSpinner">CSV取込</a>
    <button mat-raised-button type="button" color="warn" [disabled]="showSpinner" (click)="onEditTalent()">
      新規作成
    </button>
    <button mat-raised-button type="button" [disabled]="showSpinner" (click)="onTagRegist()" class="white">
      管理タグ 登録
    </button>
  </div>
</div>
<div class="container">
  <div class="search" [hidden]="showSpinner">
    <app-search-container [searchData]="searchContainerData" (searchTalent)="searchTalent($event)"></app-search-container>
  </div>
  <div *ngIf="showSpinner" class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  <!-- 人数カウンター -->
  <ng-container *ngIf="!showSpinner">
    <div class="persons-cnt" *ngIf="talents">
      {{ config.itemsPerPage * (config.currentPage - 1) + 1 }} -
      {{ config.itemsPerPage > talents.length ? config.totalItems : config.itemsPerPage * config.currentPage }}/
      {{ config.totalItems }}人
    </div>

    <!-- タレントリストを表示 -->
    <app-talent-list-common *ngIf="talents" [talentData]="talentData"></app-talent-list-common>

    <div class="not-hit-talent" *ngIf="talents && talents.length === 0 && condition.length > 1">
      入力された検索条件にヒットするタレントはいませんでした。
    </div>
    <div class="has-no-talent" *ngIf="talents && talents.length === 0 && condition.length <= 1">
      タレントプールへの登録がありません。
    </div>

    <!-- ページング -->
    <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="getSelectedPageData($event)" maxSize="11">
      <div class="custom-pagination">
        <div class="pagination-container">
          <ng-container *ngIf="talents && talents.length > 0">
            <div *ngIf="p.isFirstPage()" class="paging-step paging-step-disable">
              <span>＜ 前へ</span>
            </div>
            <button *ngIf="!p.isFirstPage()" class="paging-step" (click)="p.previous()">
              <span>＜ 前へ</span>
            </button>
            <div *ngFor="let page of p.pages">
              <div mat-button class="paging-link-container" (click)="p.setCurrent(page.value)"
                *ngIf="p.getCurrent() !== page.value">
                <span class="paging-text">{{ page.label }}</span>
              </div>
              <div class="current-page" *ngIf="p.getCurrent() === page.value">
                <span class="paging-text">{{ page.label }}</span>
              </div>
            </div>
            <div *ngIf="p.isLastPage()" class="paging-step paging-step-disable">
              <span>次へ ＞</span>
            </div>
            <button *ngIf="!p.isLastPage()" class="paging-step" (click)="p.next()">
              <span>次へ ＞</span>
            </button>
          </ng-container>
        </div>
      </div>
    </pagination-template>
  </ng-container>
</div>
