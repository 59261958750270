<div class="flex">
  <div class="side">
    <div class="button-container">
      <button mat-raised-button (click)="onClose()">閉じる</button>
    </div>
  </div>
  <div class="side-main">
    <div class="title-area">
      <h2 mat-subheader>タレントデータ CSV取込</h2>
      <div class="btn-area">
        <button mat-raised-button color="warn" [disabled]="showSpinner" (click)="onNew()">
          新規取込
        </button>
      </div>
    </div>
    <div class="container">
      <div class="desc">
      <div class="flex">
        <div class="col1"><b>１）</b></div>
        <div class="col2">
          <b>「新規取込」からCSVファイルをアップロードしてください。</b><br>
          <span class="red-text">・ファイルの1行目にはそのカラム（列）が何のデータであるか【見出し】を必ず付けてください。</span>
        </div>
      </div>
      <div class="flex">
        <div class="col1"><b>２）</b></div>
        <div class="col2">
          <b>CaLin運営サポートがデータを整形し、取り込みます。完了はメールで報告します。</b><br>
          ・データの取り込みには最大5営業日いただきます。<br>
          ・データの取り込み件数に応じて、CaLinの利用料金が変更になる場合があります。料金は <a (click)="onLpTop()">こちら</a> をご覧ください。<br>
          ・料金が変更になる場合や、データに確認/修正が必要な場合は担当よりご連絡します。<br>
          ・ファイルアップロード後の変更/取消は <a (click)="onRequestForCaLin()">こちら</a> から急ぎご連絡ください。
        </div>
      </div>
      </div>

      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>

      <table *ngIf="!showSpinner" class="table">
        <tr>
          <th>ファイル名</th>
          <th>ファイル出力元</th>
          <th>タレント管理タグ</th>
          <th>接点・経路</th>
          <th class="date">申請日</th>
          <th class="date">取込日</th>
        </tr>
        <tr *ngFor="let csv of csvData; index as idx">
          <td>{{ csv.file_name }}</td>
          <td>{{ csv.file_from }}</td>
          <td>{{ csv.talenttag_title ? csv.talenttag_title : "-" }}</td>
          <td>{{ csv.contacttag_title ? csv.contacttag_title : "-" }}</td>
          <td class="date">{{ csv.created_at }}</td>
          <td class="date">{{ csv.import_date }}</td>
        </tr>
      </table>

      <div class="no-data" *ngIf="csvData?.length == 0">
        アップロードしたCSVファイルがありません。
      </div>

    </div>
  </div>
</div>
