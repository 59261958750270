<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">タレント管理タグ</div>
      <div class="desc">※上限30、付与数はタレントプール登録中のみカウント</div>
      <div class="right"><button mat-raised-button type="button" (click)="onAdd()" class="add-btn" [disabled]="!btnEnable ||  lists.controls.length>29">追加</button></div>
    </div>
    <div class="list-area">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <form [formGroup]="form" *ngIf="!showSpinner">
        <table *ngIf="tags" class="tag-table">
          <tr>
            <th class="order-no">表示順</th>
            <th class="title">タイトル <span class="red-text">※必須</span></th>
            <th class="cnt">付与数</th>
            <th></th>
          </tr>
          <ng-container formArrayName="lists">
            <ng-container *ngFor="let val of lists.controls; let i = index;">
              <ng-container [formGroupName]="i">
                <tr class="row-{{i}}">
                  <td>
                    <mat-form-field class="input-select no-line">
                      <mat-select formControlName="order_no" panelWidth="">
                        <mat-option [value]="0"></mat-option>
                        <ng-container *ngFor="let num of orderNum; let n = index">
                          <mat-option [value]="n+1">
                            {{ n+1 }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td class="title">
                    <div class="label-count" [class.length-red]="title.value.length>20">{{ title.value.length }}/{{ titleMaxLen }}</div>
                    <mat-form-field class="input-area no-line">
                      <input matInput type="text" formControlName="title" [maxlength]="titleMaxLen" #title />
                    </mat-form-field>
                  </td>
                  <td class="cnt">
                    {{ val.value.cnt }}
                  </td>
                  <td class="btn">
                    <button type="button" mat-raised-button (click)="onDel(i)" class="del-btn">削除</button>
                    <button type="button" mat-raised-button (click)="onDelCancel(i)" class="cancel-btn">取消</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </table>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="!btnEnable || form.invalid || isSaving" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
