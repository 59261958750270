<div class="wrap">
  <app-lp-header page="request"></app-lp-header>
  <div class="contents-area">
    <div class="title-area">
      <h2 class="title">{{ title }}</h2>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="!isSendFinish" class="privacy-area">
      タレントプール採用サービス「CaLin」の販売・サポート業務は<a [href]="Constant.zhieniusUrl" target="_blank" class="text-link">株式会社Zhienius</a>に委託しています。<br><br>
      <span *ngIf="mode == Constant.modeNewAccount">
        CaLinの<a href="{{Constant.footerUrlAgreement}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionAccountAgreement)" class="text-link">利用規約</a>、
      </span>
      キャリン株式会社の<a *ngIf="mode == Constant.modeNewAccount" href="{{Constant.footerUrlPrivacy}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionAccountPrivacy)" class="text-link">プライバシーポリシー</a><a *ngIf="mode == Constant.modeWhitePaper" href="{{Constant.footerUrlPrivacy}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionWhitepaperPrivacy)" class="text-link">プライバシーポリシー</a>
      および株式会社Zhieniusの<a [href]="Constant.zhieniusPrivacy" target="_blank" class="text-link">プライバシーポリシー</a>に同意の上、送信してください。<br>
    </div>
    <div *ngIf="!isSendFinish" class="warning-area">
      <span *ngIf="mode == Constant.modeWhitePaper">
        <br>1営業日以内に株式会社Zhieniusの担当者より資料を送付いたします。
      </span>
      <span *ngIf="mode == Constant.modeNewAccount">
        <br>キャリン株式会社にてアカウント登録審査を行い、審査通過の企業様には１営業日以内にアカウントを発行します。<br><br>
        導入後のサポートなどについて、株式会社Zhieniusから連絡がある場合があります。予めご承知おきください。
      </span>
    </div>
    <div *ngIf="!isSendFinish" class="form-area">
      <form [formGroup]="form">
        <!-- 企業名 -->
        <div class="input-container">
          <div class="input-label">
            <span class="font-bold">企業名</span>
            <span class="required">{{ Constant.prefixRequireMark }}</span>
          </div>
          <div class="input-area">
            <input
              type="text"
              class="text"
              formControlName="company_name"
              placeholder=" 例）キャリン株式会社"
            />
          </div>
        </div>
        <!-- 部署 -->
        <div class="input-container">
          <div class="input-label">
            <span class="font-bold">部署</span>
            <span class="required">{{ Constant.prefixRequireMark }}</span>
          </div>
          <div class="input-area">
            <input
              type="text"
              class="text"
              formControlName="department"
              placeholder=" 例）人事部採用グループ"
            />
          </div>
        </div>
        <!-- 名前 -->
        <div class="input-container">
          <div class="input-label">
            <span class="font-bold">お名前</span>
            <span class="required">{{ Constant.prefixRequireMark }}</span>
          </div>
          <div class="input-area">
            <input type="text" class="text" formControlName="applicant_name" placeholder=" 例）片岡 卓也" />
          </div>
        </div>
        <!-- 電話番号 -->
        <div class="input-container">
          <div class="input-label">
            <span class="font-bold">電話番号（半角、ハイフンなし）</span>
            <span class="required">{{ Constant.prefixRequireMark }}</span>
          </div>
          <div class="input-area">
            <input type="text" class="text" formControlName="tell_no" placeholder=" 例）0353242271" />
          </div>
        </div>
        <!-- メールアドレス -->
        <div class="input-container">
          <div class="input-label">
            <span class="font-bold">メールアドレス</span>
            <span class="required">{{ Constant.prefixRequireMark }}</span>
          </div>
          <div class="input-area">
            <input
              type="text"
              class="text"
              formControlName="mail_address"
              name="mail_address"
              placeholder=" 例）example@calin.co.jp"
            />
          </div>
        </div>
        <!-- 企業ホームページ -->
        <div class="input-container">
          <div class="input-label">
            <span class="font-bold">企業ホームページ</span>
            <span class="required">{{ Constant.prefixRequireMark }}</span>
          </div>
          <div class="input-area">
            <input
              type="text"
              class="text"
              formControlName="homepage"
              placeholder=" 例）https://www.corp.calin.co.jp"
            />
          </div>
        </div>
        <!-- 利用シーン -->
        <div class="input-container">
          <div class="select-installation-area">
            <div>
              <span class="font-bold">想定している主な利用シーン</span>
              <span class="required">{{ Constant.prefixRequireMark }}</span>
            </div>
            <div class="input-area">
              <mat-radio-group class="radio-group" formControlName="scene">
                <mat-radio-button [value]=Constant.useSceneNewgrad>新卒採用</mat-radio-button>
                <mat-radio-button [value]=Constant.useSceneCareer>中途採用</mat-radio-button>
                <mat-radio-button [value]=Constant.useSceneBoth>どちらも</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <!-- 送信ボタン -->
        <mat-error *ngIf="errorMessage" class="error-message">{{ errorMessage }}</mat-error>
        <div class="button-area">
          <ng-container *ngIf="mode == Constant.modeNewAccount">
            <button mat-button type="button" (click)="onSend()" class="send-button new-account-btn">
              送信する
            </button>
          </ng-container>
          <ng-container *ngIf="mode == Constant.modeWhitePaper">
            <button mat-button type="button" (click)="onSend()" class="send-button whitepaper-btn">
              送信する
            </button>
          </ng-container>
        </div>
      </form>
      <mat-divider></mat-divider>
      <div class="caution-area">
        <ng-container *ngIf="mode == Constant.modeNewAccount">
          <p class="indent">
            ※以下に該当するご登録は現在控えさせていただいております。予めご了承ください。
          </p>
        </ng-container>
        <ng-container *ngIf="mode == Constant.modeWhitePaper">
          <p class="indent">
            ※以下に該当すると思われる場合は、資料の送付を控えさせていただくことがございます。予めご了承ください。
          </p>
        </ng-container>
        <div class="contents">
          <p class="indent">・携帯電話またはフリーのメールアドレスの場合</p>
          <p class="indent">・ホームページに会社概要や事業内容の記載がない場合</p>
          <p class="indent">・個人事業主など法人格を所有していない場合</p>
          <p class="indent">・日本国内に現地法人がない場合</p>
          <p class="indent"></p>
          <ng-container *ngIf="mode == Constant.modeNewAccount">
            <p class="indent">・採用業務に携わらない方からの申請の場合</p>
          </ng-container>
          <p class="indent">・CaLinと競合する事業を行う、または行っていると思われる場合</p>
        </div>
      </div>
    </div>
    <div *ngIf="isSendFinish" class="result-area">
      <div *ngIf="mode == Constant.modeNewAccount" class="account-result">
        お申し込みいただき、ありがとうございます！<br />
        <br />
        ご入力いただいた内容をもとに審査を行い、審査通過の場合は１営業日以内にアカウント発行のご連絡をいたします。<br />
        お待たせして申し訳ございません。<br />
        <br />
        なお、審査通過とならなかった場合、その旨のご連絡などはしておりませんので予めご了承ください。<br />
        また、審査通過とならなかった理由などについてのお問い合わせには応じかねます。<br /><br>
        導入後のサポートなどについて、<a [href]="Constant.zhieniusUrl" target="_blank" class="text-link">株式会社Zhienius</a>から連絡がある場合があります。予めご承知おきください。
      </div>
      <div *ngIf="mode == Constant.modeWhitePaper" class="whitepaper-result">
        ご検討いただき、ありがとうございます！<br />
        <br />
        1営業日以内に<a [href]="Constant.zhieniusUrl" target="_blank" class="text-link">株式会社Zhienius</a>の担当者より資料を送付いたします。<br>
        お待たせして申し訳ございません。
      </div>
      <div class="move-lp">
        <a class="text-link" [routerLink]="['/']">トップページに戻る</a>
      </div>
    </div>
  </div>
  <div id="{{ Constant.lpHrnoteScriptId }}"></div>
  <app-lp-footer></app-lp-footer>
</div>
