<div class="flex">
  <div class="side">
    <div class="button-container">
      <button mat-raised-button (click)="onClose()">閉じる</button>
    </div>
  </div>
  <div class="right">
    <div class="title-area">
      <h2 mat-subheader class="title">採用PRコンテンツ カテゴリー管理</h2>
      <div class="btn-new">
        <button mat-raised-button color="warn" (click)="onEdit()" [disabled]="showSpinner || !categories || categories.length>=categoryMaxLen">
          新規作成
        </button>
      </div>
    </div>
    <div class="container">
      <div class="desc">
        採用PRコンテンツは、ここで作成したカテゴリーごとに、ページ分けして表示できます。<br>
        ページ分けを有効にするには、採用PRコンテンツにカテゴリーを紐付けてください。
      </div>
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <div class="count" *ngIf="!showSpinner && categories">{{ categories.length }} / {{ categoryMaxLen }}</div>
      <table *ngIf="!showSpinner && categories?.length>=0" class="table">
        <tr>
          <th class="" colspan="2">カテゴリー</th>
          <th class="cnt">登録数</th>
          <th class="menu">Menu</th>
        </tr>
        <ng-container *ngFor="let cat of categories; index as idx">
          <tr>
            <td class="image-container" rowspan="3">
              <img src="{{ cat.image_url }}" />
            </td>
            <!-- カテゴリタイトル -->
            <td class="title-row">
              <div class="title-container">
                <a (click)="onEdit(idx)">{{ cat.title }}</a>
              </div>
            </td>
            <td class="cnt" rowspan="3">{{ cat.cnt }}</td>
            <td class="menu" rowspan="3">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <button mat-menu-item (click)="onEdit(idx)">編集</button>
                <button mat-menu-item (click)="onDel(cat.id)">削除</button>
              </mat-menu>
            </td>
          </tr>
          <tr>
            <!-- 説明 -->
            <td class="desc-row">
              {{ cat.description }}
            </td>
          </tr>
          <tr class="bottom">
            <!-- URL -->
            <td class="url-container">
              <div class="url-text-container">
                <div class="text">URL</div>
                <div class="url"><a href="{{ urlBase }}{{ cat.url }}" target="_blank">{{ urlBase }}{{ cat.url }}</a></div>
                <div class="copy" (click)="onUrlCopy(idx)">
                  <img
                    src="assets/notice/copy-icon.png"
                    srcset="
                      assets/notice/copy-icon.png    1x,
                      assets/notice/copy-icon@2x.png 2x,
                      assets/notice/copy-icon@3x.svg 3x
                    "
                  />
                  URLをコピー
                </div>
                <input id="url-code-{{ idx }}" type="hidden" value="{{ urlBase }}{{ cat.url }}" />
              </div>
            </td>
          </tr>
        </ng-container>
      </table>
      <div class="data-none" *ngIf="!showSpinner && categories?.length==0">カテゴリーがありません。</div>
    </div>
  </div>
</div>
