import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUtil } from '../../util/common-util';
import { Constant } from './../../constant';

@Component({
  selector: 'app-question-detail-dialog',
  templateUrl: './question-detail-dialog.component.html',
  styleUrls: ['./question-detail-dialog.component.css']
})
export class QuestionDetailDialogComponent implements OnInit {
  public index;
  public question;
  public readonly Constant = Constant;

  constructor(
    public dialogRef: MatDialogRef<QuestionDetailDialogComponent>,
    private commonUtil: CommonUtil,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.index = data.index;
    this.question = data.question;
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  // 編集画面に切り替え
  onEdit() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const param = {
      isEdit: true,
      index: this.index
    };
    this.dialogRef.close(param);
  }

}
