import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-relogin-dialog',
  templateUrl: './relogin-dialog.component.html',
  styleUrls: ['./relogin-dialog.component.css']
})
export class ReloginDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ReloginDialogComponent>) {}

  ngOnInit() {}

  onOK() {
    this.dialogRef.close(true);
  }
}
