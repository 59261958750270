import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Constant } from './../../constant';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { MatDialogRef } from '@angular/material/dialog';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-prcontent-priority-dialog',
  templateUrl: './prcontent-priority-dialog.component.html',
  styleUrl: './prcontent-priority-dialog.component.css'
})
export class PrcontentPriorityDialogComponent {
  public readonly Constant = Constant;
  public form: FormGroup;
  public showSpinner = true;
  public isSaving = false;
  public btnEnable = false;
  public contents;
  public orderNum = Array(3);  // 表示順選択リスト用
  public prefixAll = 'conall-';
  public prefixCat = 'concat-';

  constructor(
    private dialogRef: MatDialogRef<PrcontentPriorityDialogComponent>,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) {

  }

  ngOnInit() {
    this.getContents();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.checkOnline()) {
      this.isSaving = false;
      return;
    }

    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.postPriority();
  }

  onSortChange(val, id, allFlg, category) {
    // 同順位があればリセット
    if (val > 0) {
      const key_prefix = allFlg ? this.prefixAll : this.prefixCat;
      this.contents.forEach(element => {
        if (this.form.value[key_prefix + element.id] === val && element.id !== id) {
          if (allFlg) {
            // 全体
            this.form.controls[key_prefix + element.id].setValue(0);
          } else if (element.category_id === category) {
            // カテゴリありは同カテゴリの場合
            this.form.controls[key_prefix + element.id].setValue(0);
          }
        }
      });
    }
  }

  /* API */
  private getContents() {
    Auth.currentSession().then(session => {
      const apiPath = '/prcontent/priority';
      const options = this.auth.createApiHeader(session);

      this.commonUtil.apiGet(apiPath, options).then(res => {
        this.contents = res.data;
        this.createFormData();
        this.showSpinner = false;
        this.btnEnable = true;
      })
      .catch(err => {
        this.showSpinner = false;
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  private postPriority() {
    const sortAry = [];
    this.contents.forEach(element => {
      sortAry.push({
        id: element.id,
        category_id: element.category_id,
        sort_no: this.form.value[this.prefixAll + element.id],
        category_sort_no: this.form.value[this.prefixCat + element.id]
      })

    });

    Auth.currentSession().then(session => {
      const apiPath = '/prcontent/priority';
      const options = this.auth.createApiHeader(session);
      options['body'] = {sort_ary: sortAry};

      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
        // ダイアログ閉じる
        this.dialogRef.close(true);
      })
      .catch(err => {
        // 保存失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
        this.isSaving = false;
      });
    });
  }
  /* private */
  private createFormData() {
    // データ整形
    let currentCategory = -1;
    this.contents.forEach((element, index) => {
      if (element.category_id !== currentCategory) {
        element.categoryFirst = true;
        if (index > 0) {
          this.contents[index - 1].categoryLast = true;
        }
        currentCategory = element.category_id;
      } else {
        element.categoryFirst = false;
      }
    });

    // form作成
    const controles = {};
    this.contents.forEach(element => {
      controles[this.prefixAll + element.id] = new FormControl(element.sort_no);
      controles[this.prefixCat + element.id] = new FormControl(element.category_sort_no);
    });

    this.form = new FormGroup(controles);
  }
}
