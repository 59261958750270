<h2 mat-subheader>アナリティクス</h2>

<div *ngIf="showSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>

<div class="condition-area" *ngIf="type_master">
  <div class="desc">
    集計単位「日」は3ヶ月以内を期間範囲の目安としてください。出力数が多過ぎる場合、実行エラーとなります。
  </div>
  <form [formGroup]="form">
    <div class="condition">
      <mat-form-field class="input-select no-line">
        <mat-select formControlName="type" panelClass="analyticsSelect" panelWidth="">
          <ng-container  *ngFor="let type of type_master">
            <mat-option  [value]="type.id">
              {{ type.item_value }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-area input-date no-line" (click)="datepicker1.open()" floatLabel="never">
        <input matInput [matDatepicker]="datepicker1" formControlName="start"
          placeholder="期間" [(ngModel)]="period.start" name="periodStart" [min]="getMinDate('')" [max]="getMaxDate(period.end)">
        <mat-datepicker-toggle matSuffix [for]="datepicker1" class="date-pick-icon"></mat-datepicker-toggle>
        <mat-datepicker #datepicker1></mat-datepicker>
      </mat-form-field>
      <div class="separator">〜</div>
      <mat-form-field class="input-area input-date no-line" (click)="datepicker2.open()" floatLabel="never">
        <input matInput [matDatepicker]="datepicker2" formControlName="end"
          placeholder="期間" [(ngModel)]="period.end" name="periodEnd" [min]="getMinDate(period.start)" [max]="getMaxDate('')">
        <mat-datepicker-toggle matSuffix [for]="datepicker2" class="date-pick-icon"></mat-datepicker-toggle>
        <mat-datepicker #datepicker2></mat-datepicker>
      </mat-form-field>
      <!-- 集計単位 -->
      <div class="sub-title">集計単位</div>
      <mat-form-field class="input-select unit no-line">
        <mat-select formControlName="unit" class="no-line" panelWidth="">
          <ng-container *ngFor="let unit of unit_master">
            <mat-option [value]="unit.id">
              {{ unit.item_value }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div class="search-button">
        <button mat-raised-button color="warn" [disabled]="form.invalid" (click)="onSearch()">
          実行
        </button>
      </div>
    </div>
  </form>
</div>
