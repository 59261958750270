import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Constant } from './../constant';
import { CommonUtil } from './../util/common-util';
import { ToastUtil } from '../util/toast-util';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(public auth: AuthService, private commonUtil: CommonUtil, private toastUtil: ToastUtil) {}

  ngOnInit() {}

  onClickLogout() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogout);

    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    this.auth.signOut();
  }
}
