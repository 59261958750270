import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Amplify設定
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import { Storage } from '@aws-amplify/storage';
Auth.configure(environment.amplify.Auth);
API.configure(environment.amplify.API);
Storage.configure(environment.amplify.Storage);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
