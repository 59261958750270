<div class="header">
  <div class="buttons">
    <button mat-raised-button type="button" (click)="onEdit()">編集</button>
  </div>
  <div mat-icon-button (click)="onClose()">
    <img src="assets/common/close_button.png" srcset="
        assets/common/close_button.png    1x,
        assets/common/close_button@2x.png 2x,
        assets/common/close_button@3x.svg 3x
      " class="close-button" />
  </div>
</div>
<mat-dialog-content>
  <div class="content">
    <div class="titlearea">
      <div class="generalfont bold">
        <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
          " /> 管理用タイトル
      </div>
      <div class="value pre-line">{{ question.title }}</div>
    </div>
    <div class="contentsmargin">
      <div class="generalfont bold">
        <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
          " /> 質問
      </div>
      <div class="value pre-line">{{ question.question }}</div>
    </div>
    <div class="contentsmargin">
      <div class="generalfont bold">
        <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
          " /> 回答形式
      </div>
      <div class="value">{{ question.type_name2 }}</div>

      <!-- 回答形式選択肢 -->
      <div class="indent" *ngIf="question.type != Constant.aqAnswerTypeTextId">
        <div class="generalfont bold">
          <img src="assets/notice/mark_gray.png" srcset="
              assets/notice/mark_gray.png    1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            " /> 選択肢
        </div>
        <div class="value">
          <span *ngFor="let item of question.select_item" class="select-item">
            <ng-container *ngIf="item.order_no>0" >{{ item.item }}</ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="contentsmargin">
      <div class="generalfont bold">
        <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
          " /> この質問の表示順
      </div>
      <div class="value">{{ question.order_no }}</div>
    </div>
  </div>
</mat-dialog-content>
