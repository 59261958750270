<mat-dialog-content>
  <div class="content">
    <form [formGroup]="form">
      <ng-container *ngIf="!defaultUrlEditFlg">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span> タイトル </span>
        <span class="required">※必須</span>
      </div>
      <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
      <input
        class="input-text"
        formControlName="title"
        #title
        placeholder="タイトル"
        type="text"
        [maxlength]="maxLenTitle"
      />

      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span> アンケート</span>
        <span class="small-label">（改行不可） </span>
        <span class="required">※必須</span>
      </div>
      <div id="description" class="label-count" [class.length-red]="question.value.length>maxLenQuestion">{{ question.value.length }}/{{ maxLenQuestion }}</div>
      <textarea
        class="input-textarea"
        formControlName="question"
        #question
        placeholder="アンケート内容"
        [maxlength]="maxLenQuestion"
        (keydown)="onKeyDown($event)"
        (input)="onInput()"
        rows="3"
      ></textarea>
      <div class="text-right">
        ※ アンケート回答の選択肢：<span class="normal">いいえ / はい</span>
      </div>
      </ng-container>
      <ng-container *ngIf="defaultUrlEditFlg">
        <div class="label">デフォルトURLのエントリーページ</div>
        <div class="def-desc">ここで設定した質問は、つながった全てのタレントに共通で表示されます。</div>
      </ng-container>

      <div class="spinner" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
      </div>
      <ng-container *ngIf="!showSpinner && addQuestions">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x" />
          <span> プロフィール追加収集の質問</span>
        </div>
        <ul class="questions">
          <ng-container *ngFor="let data of addQuestions; let i = index">
            <li>
              <mat-checkbox color="primary" [formControlName]="'addquestion_'+data.id">{{ data.title }}</mat-checkbox>
            </li>
          </ng-container>
        </ul>
        <div *ngIf="addQuestions.length == 0" class="no-question">
          質問がまだ作成されていません。<br>
          <a routerLink="question" target="_blank">質問を作成する</a>
        </div>
      </ng-container>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button
    mat-raised-button
    (click)="onSave()"
    [disabled]="form.invalid || !addQuestions || isSaving"
    class="yes-btn"
    *ngIf="data.type == Constant.noticeTypeAdd"
  >
    作成
  </button>
  <button
    mat-raised-button
    (click)="onSave()"
    [disabled]="form.invalid || !addQuestions || (defaultUrlEditFlg && addQuestions.length==0) || isSaving"
    class="yes-btn"
    *ngIf="data.type == Constant.noticeTypeEdit"
  >
    更新
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
