<h2 mat-subheader>CaLinの使い方</h2>
<div class="container">
  <div class="row flex">
    <img src="assets/userguide/step1.png" class="step-icon">
    <div (click)="onScroll(1)" class="pagelink">企業情報を登録して、エントリーページのベースを作りましょう！</div>
  </div>
  <div class="row flex">
    <img src="assets/userguide/step2.png" class="step-icon">
    <div (click)="onScroll(2)" class="pagelink">エントリーページへの導線を準備して、タレントを獲得しましょう！</div>
  </div>
  <div class="row flex">
    <img src="assets/userguide/step3.png" class="step-icon">
    <div (click)="onScroll(3)" class="pagelink">会社の魅力をタレントへ伝えましょう！</div>
  </div>
  <div class="row flex">
    <img src="assets/userguide/step4.png" class="step-icon">
    <div (click)="onScroll(4)" class="pagelink">つながっているタレントにアプローチしましょう！</div>
  </div>
  <div class="text top bottom">
    <p class ="indent_userguide">
      具体的な活用例については<a (click)="onCaseForCalin()">CaLinの活用例</a>をご覧ください。<br><br><br>
      ・もっと詳しく使い方を説明してほしい<br>
      ・自社サイトにリンクを設置する良いアイデアがほしい<br>
      ・タレントへの効果的なアプローチ方法を教えてほしい<br>
    </p>
    ・・・など、何かございましたら遠慮なく<a (click)="onRequestForCaLin()">質問・要望はこちら</a>からお申し付けください！
  </div>

  <mat-divider></mat-divider>

  <div class="title flex" id="step1">
    <img src="assets/userguide/step1.png" class="step-icon">
    <div>企業情報を登録して、エントリーページのベースを作りましょう！</div>
  </div>
  <div class="image">
    <img src="assets/userguide/tutorial1.jpg">
  </div>
  <div class="desc">
    <p>まずは、<span class="red-text">企業情報</span>から企業の概要や紹介文などを登録しましょう。</p>
    <p>採用ピッチ（スライド資料）の掲載も可能です。
  </div>

  <mat-divider></mat-divider>

  <div class="title flex" id="step2">
    <img src="assets/userguide/step2.png" class="step-icon">
    <div>エントリーページへの導線を準備して、タレントを獲得しましょう！</div>
  </div>
  <div class="image">
    <img src="assets/userguide/tutorial2.jpg">
  </div>
  <div class="desc">
    <p>タレントを獲得するために、<span class="red-text">告知用URL</span>にあるエントリーページのリンクを自社のサイトに<br>設置しましょう。</p>
  </div>

  <mat-divider></mat-divider>

  <div class="title flex" id="step3">
    <img src="assets/userguide/step3.png" class="step-icon">
    <div>会社の魅力をタレントへ伝えましょう！</div>
  </div>
  <div class="image">
    <img src="assets/userguide/tutorial3.jpg">
  </div>
  <div class="desc">
    <p>タレントの興味や志望度を高めるために、<span class="red-text">採用PRコンテンツ</span>や<span class="red-text">求人情報</span>を登録しましょう。</p>
  </div>

  <mat-divider></mat-divider>

  <div class="title flex" id="step4">
    <img src="assets/userguide/step4.png" class="step-icon">
    <div>つながっているタレントにアプローチしましょう！</div>
  </div>
  <div class="image">
    <img src="assets/userguide/tutorial4.jpg">
  </div>
  <div class="desc">
    <p>タレントの情報は<span class="red-text">タレントリスト</span>から確認できます。</p>
    <p>詳細画面ではメッセージの送受信やタレントの行動履歴を確認できます。
  </div>

  <mat-divider></mat-divider>
</div>

