<div class="close-btn-area">
  <button mat-icon-button mat-dialog-close class="close-button" *ngIf="page != 0">
  <img
    src="assets/common/close_button.png"
    srcset="
      assets/common/close_button.png    1x,
      assets/common/close_button@2x.png 2x,
      assets/common/close_button@3x.svg 3x
    "
  />
  </button>
</div>
<mat-dialog-content>
  <div class="content page{{ page }}">
    <ng-container *ngIf="page==0">
      <div class="text-center top">CaLinへのログインありがとうございます！</div>
      <div class="text-center">CaLinの使い方を簡単にご紹介しますので、是非ご覧ください。</div>
      <div class="button-container">
        <button mat-raised-button class="start-btn" (click)="onNext()">チュートリアルを見る</button>
        <div class="gray-link" (click)="onClose()">今はSKIPする</div>
      </div>
    </ng-container>
    <ng-container *ngIf="page==1">
      <div class="title">CaLinの使い方　全体の流れ</div>
      <div class="row flex">
        <img src="assets/userguide/step1.png" class="step-icon">
        <div>企業情報を登録して、エントリーページのベースを作りましょう！</div>
      </div>
      <div class="row flex">
        <img src="assets/userguide/step2.png" class="step-icon">
        <div>エントリーページへの導線を準備して、タレントを獲得しましょう！</div>
      </div>
      <div class="row flex">
        <img src="assets/userguide/step3.png" class="step-icon">
        <div>会社の魅力をタレントへ伝えましょう！</div>
      </div>
      <div class="row flex">
        <img src="assets/userguide/step4.png" class="step-icon">
        <div>つながっているタレントにアプローチしましょう！</div>
      </div>
    </ng-container>
    <ng-container *ngIf="page==2">
      <div class="title flex">
        <img src="assets/userguide/step1.png" class="step-icon">
        <div>企業情報を登録して、エントリーページのベースを作りましょう！</div>
      </div>
      <div class="image">
        <img src="assets/userguide/tutorial1.jpg">
      </div>
      <div class="desc">
        <p>まずは、<span class="red-text">企業情報</span>から企業の概要や紹介文などを登録しましょう。</p>
        <p>採用ピッチ（スライド資料）の掲載も可能です。
      </div>
    </ng-container>
    <ng-container *ngIf="page==3">
      <div class="title flex">
        <img src="assets/userguide/step2.png" class="step-icon">
        <div>エントリーページへの導線を準備して、タレントを獲得しましょう！</div>
      </div>
      <div class="image">
        <img src="assets/userguide/tutorial2.jpg">
      </div>
      <div class="desc">
        <p>タレントを獲得するために、<span class="red-text">告知用URL</span>にあるエントリーページのリンクを自社のサイトに<br>設置しましょう。</p>
      </div>
    </ng-container>
    <ng-container *ngIf="page==4">
      <div class="title flex">
        <img src="assets/userguide/step3.png" class="step-icon">
        <div>会社の魅力をタレントへ伝えましょう！</div>
      </div>
      <div class="image">
        <img src="assets/userguide/tutorial3.jpg">
      </div>
      <div class="desc">
        <p>タレントの興味や志望度を高めるために、<span class="red-text">採用PRコンテンツ</span>や<span class="red-text">求人情報</span>を登録しましょう。</p>
      </div>
    </ng-container>
    <ng-container *ngIf="page==5">
      <div class="title flex">
        <img src="assets/userguide/step4.png" class="step-icon">
        <div>つながっているタレントにアプローチしましょう！</div>
      </div>
      <div class="image">
        <img src="assets/userguide/tutorial4.jpg">
      </div>
      <div class="desc">
        <p>タレントの情報は<span class="red-text">タレントリスト</span>から確認できます。</p>
        <p>詳細画面ではメッセージの送受信やタレントの行動履歴を確認できます。
      </div>
    </ng-container>
    <ng-container *ngIf="page==6">
      <div class="text top">ここまでご覧いただきありがとうございます！<br>チュートリアルは以上です。</div>
        <div class="text list">
          <p class ="indent_userguide">
            具体的な活用例については<a (click)="onCaseForCalin()">CaLinの活用例</a>をご覧ください。<br><br><br>
            ・もっと詳しく使い方を説明してほしい<br>
            ・自社サイトにリンクを設置する良いアイデアがほしい<br>
            ・タレントへの効果的なアプローチ方法を教えてほしい<br>
          </p>
          ・・・など、何かございましたら遠慮なく<a (click)="onRequestForCaLin()">質問・要望はこちら</a>からお申し付けください！
        </div>
      <div class="text bold top">さぁ！まずは、企業情報の登録からスタートしましょう！</div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="page!=0">
  <ng-container *ngIf="page>1">
    <button mat-raised-button (click)="onPrev()">前へ</button>
  </ng-container>
  <ng-container *ngIf="page>=1 && page<6">
    <button mat-raised-button class="next-btn" (click)="onNext()">次へ</button>
  </ng-container>
  <ng-container *ngIf="page==6">
    <button mat-raised-button class="orange-btn" (click)="onCompany()">＞ 企業情報</button>
  </ng-container>
  <div class="check red-text" *ngIf="page==6">
    <mat-checkbox color="primary" [(ngModel)]="checkFlg">今後チュートリアルを表示しない場合は、こちらをチェックして企業情報へ進んでください。</mat-checkbox>
  </div>
</mat-dialog-actions>
