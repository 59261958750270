import { Component } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from './../../util/common-util';
import { ToastUtil } from './../../util/toast-util';
import { Constant } from './../../constant';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userguide-dialog',
  templateUrl: './userguide-dialog.component.html',
  styleUrls: ['./userguide-dialog.component.css']
})
export class UserguideDialogComponent {
  public readonly Constant = Constant;
  public page = 0;
  public checkFlg = false;

  constructor(
    public dialogRef: MatDialogRef<UserguideDialogComponent>,
    private auth: AuthService,
    private router: Router,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) { }

  // 次へ
  onNext() {
    this.page++;
  }

  // 前へ
  onPrev() {
    this.page--;
  }

  // 閉じる
  onClose() {
    this.dialogRef.close();
  }

  onRequestForCaLin() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionRequest);
    window.open(Constant.requestForCalinUrl);
  }

  // 企業情報へ
  onCompany() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    if (this.checkFlg) {
      // 非表示フラグ更新
      this.hideTutorial();
    }
    this.dialogRef.close();
    // 企業情報へ
    this.router.navigate(['/company']);
  }

  // チュートリアルを非表示にするフラグを更新
  private hideTutorial() {
    Auth.currentSession().then(session => {
      const apiPath = '/operator';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        tutorial_hide_flag: Constant.flagOn
      };

      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => { })
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    });
  }

  onCaseForCalin(){
    window.open('case');
  }

}
