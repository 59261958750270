<mat-dialog-content class="dialog-padding">
  <div class="title">{{ title }}</div>
  <mat-divider></mat-divider>
  <form [formGroup]="form">
    <div class="employtype-area">
      <div class="item-container">
        <ul>
          <ng-container *ngFor="let data of getFormData(); let i = index">
            <li id="item{{ i }}" class="item" [class.multi-row]="isMultiRow" [class.single-row]="!isMultiRow">
              <mat-checkbox color="primary" [formControl]="data">{{
                companyMaster[i].item_value
              }}</mat-checkbox>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </form>
  <mat-divider></mat-divider>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onOK()" class="yes-btn">OK</button>
</mat-dialog-actions>
