import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Constant } from './../constant';

@Injectable()
export class MomentUtil {
  constructor() {}

  // 日時の文字列を取得する
  getDateFormat(date) {
    return moment(date).format('YYYY-MM-DD');
  }
  getDateFormatDay(date) {
    return moment(date).format('D日');
  }
  getDateFormatMonthDay(date) {
    return moment(date).format('M月D日');
  }

  // 現在日時を取得する
  public createToday(): string {
    return moment().format('YYYY-MM-DD');
  }

  // 現在日時を取得するスラッシュ区切り
  public createTodaySlash(): string {
    return moment().format('YYYY/MM/DD');
  }

  // 今月の開始日
  public createThisMonthStartDate(): string {
    return moment().startOf('month').format('YYYY-MM-DD');
  }

  // 先月の開始日
  public createLastMonthStartDate(): string {
    return moment().add('M', -1).startOf('month').format('YYYY-MM-DD');
  }

  // 先月の最終日
  public createLastMonthEndDate(): string {
    return moment().add('M', -1).endOf('month').format('YYYY-MM-DD');
  }

  // 先々月の開始日
  public createTwoMonthAgoStartDate(): string {
    return moment().add('M', -2).startOf('month').format('YYYY-MM-DD');
  }

  // 先月の最終日
  public createTwoMonthAgoEndDate(): string {
    return moment().add('M', -2).endOf('month').format('YYYY-MM-DD');
  }

  // 指定月の最終日
  getEndDateMonth(date) {
    return moment(date + '-01').endOf('month').format('YYYY-MM-DD');
  }

  // 日時のdiffを取得
  getDiff(start, end, unit) {
    if (unit === Constant.alTermUnitMonth) {
      start = moment(start).startOf('month');
      end = moment(end).endOf('month');
    } else if (unit === Constant.alTermUnitYear) {
      start = moment(start).startOf('year');
      end = moment(end).endOf('year');
    }
    return moment(end).diff(start, unit);
  }

}

