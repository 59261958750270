<div class="flex">
  <div class="side">
    <div class="talent-button-area">
      <span>現在 {{selectedTalentsSet.size}} 人を選択しています。<br>
        ※上限100人</span>
      <div class="button-container">
        <button mat-raised-button (click)="onSave()" class="yes-btn" [disabled]="showSpinner || !talents || selectedTalentsSet.size>100">保存</button>
        <button mat-raised-button (click)="onCancel()">キャンセル</button>
      </div>
    </div>
  </div>
  <div>
    <h2 mat-subheader class="title">一斉メッセージ　送信対象の選択</h2>
    <div class="container">
      <div class="desc">
        現時点で一斉メッセージを送信できるタレントを表示・カウントします。タレントプールの登録を解消したタレントや、除外リストに登録中のタレントは表示・カウントされません。
      </div>
      <div class="search">
        <app-search-container [searchData]="searchContainerData" (searchTalent)="searchTalent($event)" [class.hide]="showSpinner"></app-search-container>
      </div>
      <div *ngIf="showSpinner" class="spinner"><mat-spinner></mat-spinner></div>

      <!-- 人数カウンター -->
      <ng-container *ngIf="!showSpinner">
        <div class="persons-cnt" *ngIf="talents">
          {{ config.itemsPerPage * (config.currentPage - 1) + 1 }} -
          {{ config.itemsPerPage > talents.length ? config.totalItems : config.itemsPerPage * config.currentPage }}/
          {{ config.totalItems }}人
        </div>

        <!-- タレントリストを表示 -->
        <app-talent-list-common [talentData]="talentData" (checkTalent)="onCheckTalent($event)" (checkTalentAll)="onCheckTalentAll($event)"></app-talent-list-common>
      </ng-container>
    </div>
    <div class="list-footer">
      <!-- ページング -->
      <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="getSelectedPageData($event)" maxSize="11">
        <div class="custom-pagination">
          <div class="pagination-container">
            <ng-container *ngIf="this.talents && this.talents.length > 0">
              <div *ngIf="p.isFirstPage()" class="paging-step paging-step-disable">
                <span>＜ 前へ</span>
              </div>
              <button *ngIf="!p.isFirstPage()" class="paging-step" (click)="p.previous()">
                <span>＜ 前へ</span>
              </button>
              <div *ngFor="let page of p.pages">
                <div mat-button class="paging-link-container" (click)="p.setCurrent(page.value)"
                  *ngIf="p.getCurrent() !== page.value">
                  <span class="paging-text">{{ page.label }}</span>
                </div>
                <div class="current-page" *ngIf="p.getCurrent() === page.value">
                  <span class="paging-text">{{ page.label }}</span>
                </div>
              </div>
              <div *ngIf="p.isLastPage()" class="paging-step paging-step-disable">
                <span>次へ ＞</span>
              </div>
              <button *ngIf="!p.isLastPage()" class="paging-step" (click)="p.next()">
                <span>次へ ＞</span>
              </button>
            </ng-container>
          </div>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
