<h2 color="primary">パスワードを変更してください</h2>
<mat-divider></mat-divider>
<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div class="login-container" *ngIf="changeForm">
  <form [formGroup]="changeForm" (ngSubmit)="onSubmit(changeForm.value)" autocomplete="on">
    <div class="category-container">
      <div>
        <label>新パスワード</label>
      </div>
      <div>
        <input class="text-input" type="password" formControlName="newPassword" required />
      </div>
      <mat-hint>{{ Constant.msgPassInvalid }}</mat-hint>
    </div>

    <div class="category-container">
      <div>
        <label>新パスワード（確認）</label>
      </div>
      <div>
        <input class="text-input" type="password" formControlName="newPasswordConfirm" required />
      </div>
      <mat-error class="error-text" *ngIf="newPasswordConfirm.invalid && newPasswordConfirm.touched">
        パスワード（確認）を入力してください。
      </mat-error>
    </div>

    <div class="password-error">
      <mat-error class="error-text" *ngIf="newPasswordConfirm.dirty && changeForm.errors">
        パスワードとパスワード（確認）が異なります。
      </mat-error>
    </div>

    <div class="footer-container">
      <button mat-raised-button class="change-button" type="submit" [disabled]="!changeForm.valid">
        パスワード変更
      </button>
    </div>

    <div class="attention">
      CaLinの <a (click)="onAgreement()">利用規約</a>および
      <a (click)="onPrivacy()">プライバシーポリシー</a
      >に同意のうえ、「パスワード変更」を押してください。
    </div>
  </form>
</div>
