import { Component, Inject } from '@angular/core';
import { Constant } from './../../constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';

@Component({
  selector: 'app-company-color-dialog',
  templateUrl: './company-color-dialog.component.html',
  styleUrl: './company-color-dialog.component.css'
})
export class CompanyColorDialogComponent {
  public readonly Constant = Constant;
  public form: FormGroup;
  public isSaving = false;
  public maxLenColor = 6
  public mainColor
  public colorPreviewId = 'main-color-preview'
  private apiPath = '/company';

  constructor(
    public dialogRef: MatDialogRef<CompanyColorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) {
    this.mainColor = data.mainColor
  }

  ngOnInit() {
    this.initForm()
  }

  onSave() {
    if (!this.commonUtil.checkOnline()) {
      this.isSaving = false;
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.postColor();
  }

  onInput() {
    const colorText = this.form.value.main_color;

    if (colorText.length < 6) {
      return
    }

    // カラーコードチェック
    if (!this.checkColorCode(colorText)) {
      return
    }

    this.mainColor = colorText
    this.colorPreview()
  }

  onCompositionEnd() {
    this.onInput()
  }

  // 無効の場合保存済みに戻す
  onBlur() {
    if (!this.checkColorCode(this.form.value.main_color)) {
      this.form.patchValue({
        main_color: this.data.mainColor
      })
      this.mainColor = this.data.mainColor
      this.colorPreview()
    }
  }

  private initForm() {
    this.form = new FormGroup({
      main_color: new FormControl('', [Validators.required, Validators.minLength(this.maxLenColor), Validators.maxLength(this.maxLenColor)
        ,Validators.pattern(/^[0-9A-F]{6}$/i)
      ]),
    })

    setTimeout(() => {
      this.form.patchValue({
        main_color: this.mainColor
      })
      this.colorPreview()
    })

  }

  // プレビューの色変更
  private colorPreview() {
    const prev = document.getElementById(this.colorPreviewId)
    if (prev) {
      prev.setAttribute('style', 'background-color:#' + this.mainColor)
    }
  }

  // カラーの16進数チェック
  private checkColorCode(colorText) {
    return /^[0-9A-F]{6}$/i.test(colorText)
  }

  // 登録実行
  private postColor() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        type: Constant.companyUpdateTypeColor,
        main_color: this.mainColor
      };
      this.commonUtil
        .apiPut(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
            this.dialogRef.close(res.status);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
          this.isSaving = false;
        });
    });
  }
}
