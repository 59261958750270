import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonUtil } from '../util/common-util';
import { Constant } from '../constant';

@Component({
  selector: 'app-lp-faq',
  templateUrl: './lp-faq.component.html',
  styleUrls: ['./lp-faq.component.css']
})
export class LpFaqComponent implements OnInit {
  readonly Constant = Constant;

  constructor(
    private commonUtil: CommonUtil,
    private title: Title
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(Constant.pageTitleRoot);
  }

  // GAイベント
  onClickGAEvent(action) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, action);
  }
}
