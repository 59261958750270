import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';

@Component({
  selector: 'app-notice-del-dialog',
  templateUrl: './notice-del-dialog.component.html',
  styleUrls: ['./notice-del-dialog.component.css']
})
export class NoticeDelDialogComponent implements OnInit {
  public readonly Constant = Constant;

  constructor(
    public dialogRef: MatDialogRef<NoticeDelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
}
