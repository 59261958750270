import { Injectable } from '@angular/core';
import { ReloginDialogComponent } from '../dialog/relogin-dialog/relogin-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import { Constant } from './../constant';
import { environment } from './../../environments/environment';
import { Router } from '../../../node_modules/@angular/router';
import { ToastUtil } from './toast-util';

@Injectable()
export class CommonUtil {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private toastUtil: ToastUtil) {}

  // オンライン状態かどうかチェックする
  public isOnline() {
    return navigator.onLine;
  }

  // オンライン状態をチェックしてトースト表示
  public checkOnline() {
    if (!this.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return false;
    } else {
      return true;
    }
  }

  // アナリティクスのイベント送信
  public sendGAEvent(categoryName: string, actionName: string) {
    gtag(Constant.gaEvent, Constant.gaEventName, {
      [Constant.gaEventCategory] : categoryName,
      [Constant.gaEventLabel] : actionName
    });
  }

  // refreshTokenチェック
  public checkRefreshToken(auth: AuthService) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (
          user.signInUserSession.refreshToken.token &&
          auth.refershToken &&
          user.signInUserSession.refreshToken.token !== auth.refershToken
        ) {
          this.showReloadDialog();
        }
      })
      .catch(error => {
        this.showReloadDialog();
      });
  }

  // 再ロードダイアログ表示
  public showReloadDialog() {
    const dialogId = 'reload';
    const reload = this.dialog.getDialogById(dialogId);
    if (reload) {
      return;
    }

    this.dialog.closeAll();
    const dialogRef = this.dialog.open(ReloginDialogComponent, {
      width: '400px',
      autoFocus: false,
      disableClose: true,
      id: dialogId
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        location.reload();
      }
    });
  }

  // API GET
  public apiGet(apiPath, options): Promise<any> {
    const date = Date.now();
    const path = apiPath + '?' + date.toString();

    return API.get(Constant.apiNameCompany, path, options).then(response => {
      this.debug().log(response);
      return this.returnApiResponse(response);
    });
  }

  public apiGetForCorp(apiPath, options): Promise<any> {
    return API.get(Constant.apiNameCorp, apiPath, options).then(response => {
      this.debug().log(response);
      return this.returnApiResponseForCorp(response);
    });
  }

  // API POST
  public apiPost(apiPath, options): Promise<any> {
    return API.post(Constant.apiNameCompany, apiPath, options).then(response => {
      this.debug().log(response);
      return this.returnApiResponse(response);
    });
  }

  // API PUT
  public apiPut(apiPath, options): Promise<any> {
    return API.put(Constant.apiNameCompany, apiPath, options).then(response => {
      return this.returnApiResponse(response);
    });
  }

  // API DEL
  public apiDel(apiPath, options): Promise<any> {
    return API.del(Constant.apiNameCompany, apiPath, options).then(response => {
      return this.returnApiResponse(response);
    });
  }

  // APIのステータスがメンテナンス中またはバージョンエラーかどうか
  public apiStatusOther(err: any): Boolean {
    if (err.status === Constant.MaintenanceNG || err.status === Constant.VersionNG) {
      return true;
    } else {
      return false;
    }
  }

  // APIのネットワークエラー判断
  public apiNetworkError(errMsg: String): Boolean {
    if (errMsg === Constant.NetworkError || errMsg.indexOf(Constant.timeoutError) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  // ログ出力
  public debug() {
    if (!environment.production) {
      return console;
    } else {
      return new MockConsole();
    }
  }

  private returnApiResponse(response) {
    return new Promise((resolve, reject) => {
      if (response.status === Constant.OK) {
        return resolve(response);
      } else if (response.status === Constant.VersionNG) {
        // バージョンエラー
        this.showReloadDialog();
        return reject(response);
      } else if (response.status === Constant.MaintenanceNG) {
        // メンテナンス中
        this.router.navigate(['maintenance']);
        return reject(response);
      } else {
        // そのほかのエラー
        return reject(response);
      }
    });
  }

  private returnApiResponseForCorp(response) {
    return new Promise((resolve, reject) => {
      if (response.result === Constant.OK) {
        return resolve(response);
      } else {
        // そのほかのエラー
        return reject(response);
      }
    });
  }

  /**
   * 現在の年月日を取得する
   * @param  {String} [dateString]   日付文字列
   * @param  {String} [format] フォーマット
   * @return {String}          フォーマット済み日付
   */
  public createCurrentDateString(dateString, format) {
    let date;
    if (!dateString) {
      date = new Date();
    } else {
      if (dateString.lastIndexOf('.') > -1) {
        dateString = dateString.slice(0, dateString.lastIndexOf('.'));
      }
      date = new Date(Date.parse(dateString));
    }
    if (format = 'YYYY/MM/DD hh:mm:ss') {
      format = format.replace(/YYYY/g, date.getFullYear());
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
      format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    }

    return format;
  }

  // 日付を文字列に変換する
  public convertMomentToString(date, separator) {
    const year = date._i.year.toString();
    const month = ('00' + (date._i.month + 1).toString()).slice(-2);
    const day = ('00' + date._i.date.toString()).slice(-2);
    const registered_date = year + separator + month + separator + day;
    return registered_date;
  }

  /**
   * 年月日を指定された区切り文字で分割する
   * @param {String} date      - 年月日の文字列(月日は0埋め)
   * @param {String} separator - 区切り文字
   * @return {Object}          - { year: 年, month: 月, date:日 }
   */
  public devideYMDAsSeparator(date, separator) {
    let result = { year: '', month: '', date: '' };
    const dividedData = date.split(separator);
    if (dividedData.length === 3) {
      // 年月日の場合
      result = {
        year: dividedData[0] === '0000' ? '' : dividedData[0],
        month: dividedData[1] === '00' ? '' : dividedData[1],
        date: dividedData[2] === '00' ? '' : dividedData[2]
      };
    }
    return result;
  }

  /**
   * 年月を指定された区切り文字で分割する
   * @param {String} date      - 年月の文字列(月日は0埋め)
   * @param {String} separator - 区切り文字
   * @return {Object}          - { year: 年, month: 月 }
   */
  public devideYMAsSeparator(date, separator) {
    let result = { year: '', month: '' };
    const dividedData = date.split(separator);
    if (dividedData.length === 2) {
      // 年月の場合
      result = {
        year: dividedData[0] === '0000' ? '' : dividedData[0],
        month: dividedData[1] === '00' ? '' : dividedData[1]
      };
    }
    return result;
  }

  /**
   * 月日を指定された区切り文字で分割する
   * @param {String} date      - 月日の文字列(月日は0埋め)
   * @param {String} separator - 区切り文字
   * @return {Object}          - { month: 月, date:日 }
   */
  public devideMDAsSeparator(date, separator) {
    let result = { month: '', date: '' };
    const dividedData = date.split(separator);
    if (dividedData.length === 2) {
      // 月日の場合
      result = {
        month: dividedData[0] === '00' ? '' : dividedData[0],
        date: dividedData[1] === '00' ? '' : dividedData[1]
      };
    }
    return result;
  }

  // 年月から日付作成
  public dateFromYearMonth(year: string, month: string) {
    if (!year || !month) {
      return '';
    }

    return this.dateFromYearMonthDay(year, month, '01');
  }

  // 年月日から日付作成
  public dateFromYearMonthDay(year: string, month: string, day: string) {
    if (!year || !month || !day) {
      return '';
    }
    // 日付有効性確認
    const numY = parseInt(year, 10);
    const numM = parseInt(month, 10);
    const numD = parseInt(day, 10);
    const dt = new Date(numY, numM - 1, numD);
    if (dt.getFullYear() !== numY || dt.getMonth() !== numM - 1 || dt.getDate() !== numD) {
      return '';
    }

    return year + '-' + ('00' + month).slice(-2) + '-' + ('00' + day).slice(-2);
  }

  /**
   * Masterのidとitem_valueを変換する
   * @param {Array} targetIds    - 変換したいmasterのid配列
   * @param {Object} masterData  - 変換元になるマスターデータ
   * @param {String} key         - masterのtype
   * @return {Array}
   */
  public convertMasterIdToItemValue(targetIds, masterData, key = '') {
    let targetMaster;
    if (key) {
      targetMaster = masterData[key];
    } else {
      targetMaster = masterData;
    }
    const itemValues = [];
    targetIds.forEach(id => {
      const selectedData = targetMaster.filter(function (data) {
        return data.id === Number(id);
      });
      if (selectedData.length > 0) {
        itemValues.push(selectedData[0].item_value);
      }
    });
    return itemValues;
  }

  /**
   * テキストを指定された区切り文字を使用して連結する
   * @param {Array} texts      - 連結したい文字列
   * @param {String} delimiter - 区切りたい文字
   * @return {String}
   */
  public concatenateTexts(texts, delimiter) {
    let concatenatedText = '';
    texts.forEach(text => {
      if (concatenatedText !== '') {
        concatenatedText = concatenatedText + delimiter;
      }
      concatenatedText = concatenatedText + text;
    });
    return concatenatedText;
  }

  /**
   * 全角文字を半角文字に変換する
   * @param {String} input
   * @return {String}
   */
  public convertZenkakuToHankaku(input) {
    const result = input.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    return result;
  }

  /**
   * LPに広告タグを埋め込む
   * @param {String} targetId scriptを埋め込む目印となるDivのID
   * @param {String} code     埋め込むコード
   */
  public insertLpAd(targetId, code) {
    const script = document.createElement('script');
    script.innerHTML = code;
    const div = document.getElementById(targetId);
    div.insertAdjacentElement('afterend', script);
  }

  /**
   * タレントの画像を取得する
   * @param {Array} talents      - タレントリスト
   * @param {Array} options      - API header
   */
   public getTalentImg(talents, options) {
    if (!this.checkOnline()) {
      return;
    }

    const imageApiPath = Constant.apiNameTarent + Constant.apiNameUrl + Constant.apiPathComponent;

    talents.forEach(talent => {
      const param = imageApiPath + talent.id;
      this.apiGet(param, options).then(res => {
        talent.image_path = res.data.url;
      })
      .catch(err => { });
    });

  }

  /**
   * 新卒採用の最小年度を取得する
   * @return {Number} 年号
   */
  public getNewGraduateYear() {
    const today = new Date();
    let newGraduateYear = today.getFullYear();
    if (today.getMonth() >= 3) {
      newGraduateYear++;
    }
    return newGraduateYear;
  }

  // windowsで表示されるL SEP、P SEPなどのスペース置換
  replaceSpace(text) {
    return text.replace(/\u2028|\u2029|\u0085/g, ' ');
  }
}

/**
 * consoleのモック
 * @class MockConsole
 */
class MockConsole {
  log(...v: any[]): void { }
  info(...v: any[]): void { }
  warn(...v: any[]): void { }
  error(...v: any[]): void { }
  debug(...v: any[]): void { }
  group(...v: any[]): void { }
  groupEnd(...v: any[]): void { }
  table(...v: any[]): void { }
  dir(...v: any[]): void { }
  time(...v: any[]): void { }
  timeEnd(...v: any[]): void { }
  assert(...v: any[]): void { }
  trace(...v: any[]): void { }
}
