import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Constant } from '../constant';
import { CommonUtil } from './../util/common-util';
import { ToastUtil } from './../util/toast-util';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent {
  public downloadUrl = null;
  public downloadFile = '';

  public fileNameNewgrad = 'CaLin_newgrad.pdf';
  public fileNameCareer = 'CaLin_career.pdf';
  private filePath = 'assets/pdf/';

  constructor(
    private router: Router,
    private http: HttpClient,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil) { }

  /* テンプレート起動 */
  // ダウンロード
  onDownload(fileName) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionDownload);

    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    this.http.get(this.filePath + fileName, {responseType: 'arraybuffer'}).subscribe(res => {
      const blob = new Blob([res], {type: Constant.fileFormatDownload});
      saveAs(blob, fileName);

    }, err => {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgDownloadFailed, Constant.toastShowMiliSec);
    });

  }

  // ダウンロードできない方はこちら
  onDownloadText(fileName) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionDownload);

    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    window.open(this.filePath + fileName);
  }

  // トップページ
  onTopPage() {
    this.router.navigate(['/']);
  }
}
