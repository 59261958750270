import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Constant } from '../constant';
import { environment } from '../../environments/environment';
import Quill from 'quill';
import { AuthService } from '../auth/auth.service';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { QuillUtil } from '../util/quill-util';
import { ImageUtil } from '../util/image-util';

@Component({
  selector: 'app-quill-common',
  templateUrl: './quill-common.component.html',
  styleUrl: './quill-common.component.css'
})
export class QuillCommonComponent implements OnInit {
  @Input() textData;
  @Output() closeDialog = new EventEmitter();
  public editor;
  public mainText = '';
  public loadFlg = false;
  public type;
  public maxLenText = 5000;
  private firstRender = true;
  private regexp_url = /(https?|ftp):\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;

  @ViewChild('quillElm') quillElm;

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private quillUtil: QuillUtil,
    private imageUtil: ImageUtil,
  ) { }

  ngOnInit(): void {
    this.type = this.textData.type
    if (!this.textData.text) {
      // 新規登録時
      this.loadFlg = true
      this.textData.text = ''
    }
  }

  /* エディタ関連 */
  onEditorCreated(quill) {
    this.editor = quill;
    const Delta = Quill.import('delta')

    const video = this.quillElm.elementRef.nativeElement.querySelectorAll('.ql-video');
    if (video.length > 0) {
      video[0].innerHTML = '<img src="assets/common/youtube_icon_x2.png">';
    }

    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);

    // リンク・動画埋め込みダイアログのプレースホルダー変更
    const tooltip = quill.theme.tooltip.textbox.dataset;
    tooltip.link = 'https://calin.co.jp';
    tooltip.video = 'https://www.youtube.com';

    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      if (node.outerHTML === '<br class="Apple-interchange-newline">') {
        return new Delta()
      }

       // macのPDFプレビュー貼り付け対応
      if (node.className === 'page' && node.children?.[0].className === 'section') {
        const text = node.innerText.trim().replace(/\n+\t+/g, '').normalize()
        return new Delta().insert(text)
      }

      // sbreakとbreakが二重になる件
      if (delta.ops.length === 2) {
        if (delta.ops[0].insert && delta.ops[0].insert.sbreak && delta.ops[1].insert === Constant.elemNewLine) {
          const newOps = [delta.ops[1]]
          delta.ops = newOps
        }
      }
      return delta;
    });

    if (quill.editor.delta.ops.length === 1 && quill.editor.delta.ops[0].insert === '\n\n') {
      this.editor.deleteText(0, 1, 'user');
    }

    // ドラッグを無効にする
    quill.container.addEventListener('dragstart', function(e) {
      e.preventDefault()
    });
    quill.container.addEventListener('drop', function(e) {
      e.preventDefault()
    });

    // 初期値設定
    this.mainText = this.textData.text
  }

  // エディタ変更検知
  onContentChanged(event) {
    if (this.firstRender && this.editor) {
      // 初期表示時、画像差し替え
      this.quillUtil.checkReloadQuill(this.editor, true, this.quillElm.elementRef.nativeElement);
      this.firstRender = false;

      // 読み込みチェック
      const loadElementsAll = document.querySelectorAll<HTMLElement>(
        '.ql-container img'
      );
      if (loadElementsAll.length > 0) {
        setTimeout(() => {
          this.checkLoadCnt(loadElementsAll.length)
        }, 500);
      } else {
        this.loadFlg = true
      }

      // 最後の改行が削除されてしまうため付け足す
      if (this.textData.text && this.textData.text.slice(-11) === '<p><br></p>') {
        const idx = this.editor.getLength()
        this.editor.insertText(idx, Constant.elemNewLine)
      }

      return;
    }

    if (!this.editor.getSelection()) {
      // カーソルがない場合は見出しの作り直し、ブロック内の改行クリアのみ
      this.updateToc();
      this.quillUtil.updatePtagBr(this.editor, this.quillElm.elementRef.nativeElement);
      return;
    }

    // OGP、オートリンク、引用解除時の改行コード置換
    event.delta.ops.forEach(element => {
      if (element.insert && element.insert === Constant.elemNewLine) {
        this.checkOgp();
      } else if (element.insert && (element.insert == ' ' || element.insert == '　' || element.insert == '\xa0')) {
        this.autoLink();
      } else if (element.attributes && element.attributes.blockquote === null) {
        this.quillUtil.updatePtagBr(this.editor, this.quillElm.elementRef.nativeElement);
      }
    });

    // 見出し作り直し
    this.updateToc();

    // 引用内に改行がある場合のカーソル移動
    const deleteFlg = event.delta.ops.filter(function(data) {
      return data.delete;
    });
    if (deleteFlg.length > 0) {
      const range = this.editor.getSelection()
      let prevLeaf = this.editor.getLeaf(range.index - 1)[0]
      let currentLeaf = this.editor.getLeaf(range.index)[0]
      if (currentLeaf.constructor.name === 'SmartBreak' && currentLeaf.parent.children.length > 1
      && prevLeaf.constructor.name !== 'SmartBreak' && currentLeaf.next?.constructor.name !== 'SmartBreak') {
        this.editor.setSelection(range.index - 1, 0, 'silent')
      }
    }

    // カーソル位置が隠れないよう、最下部に移動（最下部入力時）
    if (this.editor.getSelection().index >= this.editor.getLength() - 2) {
      const bound = this.editor.getBounds(this.editor.getSelection().index);
      if (bound.bottom > 397) {
        this.editor.scrollingContainer.scrollTop = this.editor.scrollingContainer.scrollHeight - this.editor.scrollingContainer.clientHeight ;
      }
    }
  }

  private checkLoadCnt(cnt) {
    if (this.quillUtil.loadErr) {
      // 読み込みエラーの場合ダイアログを閉じる
      this.closeDialog.emit()
      return;
    }

    if (this.quillUtil.loadCnt >= cnt) {
      this.loadFlg = true;
    } else {
      setTimeout(() => {
        this.checkLoadCnt(cnt)
      }, 500);
    }
  }

  // 目次の挿入
  onEditorToc() {
    const selection = this.editor.getSelection();
    let cursorPosition = 0;
    if (selection) {
      cursorPosition = selection.index;
    }
    const tocHtml = this.createTocHTML();
    this.editor.insertEmbed(cursorPosition, 'toc-div', tocHtml, 'user');
    this.editor.setSelection(cursorPosition + 1);
  }

  // 文字数カウント
  onEditorCount() {
    return this.editor.getLength() - 1;
  }

  // 画像やOGPの個数取得（親コンポーネントより起動）
  getImgCnt() {
    const parser = new DOMParser();
    const desc = parser.parseFromString(this.mainText, 'text/html');
    return desc.querySelectorAll('img[data-path], .ogp-div, iframe').length;
  }

  // HTML取得（親コンポーネントより起動）
  getContent() {
    if (!this.mainText) {
      return ''
    }
    // 本文整形
    const parser = new DOMParser();
    const desc = parser.parseFromString(this.mainText, 'text/html');

    // OGPローディング削除
    const loadings = desc.querySelectorAll('.ogp-loading');
    loadings.forEach(item => {
      item.remove();
    })

    // OGP書き換え
    const ogps = desc.querySelectorAll('.ogp-div');
    ogps.forEach(ogp => {
      if (ogp.children.length > 0) {
        const url = ogp.children[0].getAttribute('href');
        ogp.innerHTML = url;
        ogp.removeAttribute('contenteditable');
      }
    });

    // imgのbase64データ削除
    const imgs = desc.querySelectorAll('img');
    imgs.forEach(img => {
      img.removeAttribute('src');
    });

    return desc.body.innerHTML
  }

  // エラー有無取得（親コンポーネントより起動）
  getError() {
    if (!this.loadFlg || !this.editor || this.onEditorCount() > 5000) {
      return true
    } else {
      return false
    }
  }

  // 目次更新
  private updateToc() {
    const tocDiv = document.querySelectorAll(
      '.ql-container .toc-div'
    );
    if (tocDiv.length > 0) {
      const tocHtml = this.createTocHTML();
      tocDiv.forEach(div => {
        div.innerHTML = tocHtml;
      })
    }
  }

  // 目次のHTML作成
  private createTocHTML() {
    const headingTagElementsAll = document.querySelectorAll<HTMLElement>(
      '.ql-container h3, .ql-container h4'
    );

    // 改行のみの見出しを削除
    const headingTagElements = [];
    headingTagElementsAll.forEach((headingTagElement) => {
      if (headingTagElement.innerText !== Constant.elemNewLine) {
        headingTagElements.push(headingTagElement);
      }
    });
    // HTML作成
    let tocHtml = '';
    headingTagElements.forEach((headingTagElement, index) => {
      tocHtml  += '<div class="header-' + headingTagElement.tagName + '">' + headingTagElement.innerText + '</div>'
      if (index != headingTagElements.length -1) {
        tocHtml  += '<hr class="header-' + headingTagElement.tagName + '">';
      }
    });
    if (tocHtml === '') {
      tocHtml = '<div class="toc-title bottom0">目次<span>（見出しを作ると項目が表示されます）</span></div>' + tocHtml;
    } else {
      tocHtml = '<div class="toc-title">目次</div>' + tocHtml;
    }

    return tocHtml;
  }

  // オートリンク
  private autoLink() {
    const [leaf, offset] = this.editor.getLeaf(this.editor.getSelection().index);
    const index = this.editor.getIndex(leaf);

    if (!leaf.text) {
      return;
    }

    if (leaf.text.match(this.regexp_url) != null) {
      const urlAllMatches = leaf.text.match(this.regexp_url);
      if (urlAllMatches) {
        const urlMatches = new Set<string>(urlAllMatches);
          urlMatches.forEach(url => {
            const urlIndex = leaf.text.indexOf(url);
            this.editor.formatText(index + urlIndex, url.length, 'link', url, 'user');
          });
      }
    }
  }

  // OGP
  private checkOgp() {
    const selection = this.editor.getSelection(true);
    const [leaf, offset] = this.editor.getLeaf(selection.index - 1);
    const index = this.editor.getIndex(leaf);
    const [line, offsetLine] = this.editor.getLine(selection.index - 1);
    if (!leaf.text || leaf.parent.domNode.nodeName === 'A') {
      return;
    }

    if (leaf.text.match(this.regexp_url) != null) {
      // URLが含まれる場合OGP
      const urlAllMatches = leaf.text.match(this.regexp_url);
      const urlMatches = new Set<string>(urlAllMatches);
      urlMatches.forEach(url => {
        const urlIndex = leaf.text.indexOf(url);
        if (line.children.length === 1 && urlIndex === 0) {
          // OGP挿入
          setTimeout(() => {
            this.editor.setSelection(index + urlIndex)
            this.getOgpData(url, index + urlIndex);
            this.editor.deleteText(index + urlIndex, url.length + 1);
            this.editor.insertEmbed(index + urlIndex, 'ogp-loading', url, 'silent');
          });
        } else {
          // オートリンク
          this.editor.formatText(index + urlIndex, url.length, 'link', url);
        }
      });
    }
  }

  // OGP情報取得
  private getOgpData(url, cursorIndex) {
    this.quillUtil.getOgpHtml(url).then(html => {
      this.setOgpHtml(html, url, cursorIndex);
    })
    .catch(err => {
      this.commonUtil.debug().log(err)
      // 取得エラーの場合、オートリンクとする
      this.createErrorOgp(url, cursorIndex);
    });
  }

  // OGPのHTMLに差し替え
  private setOgpHtml(html, url, cursorIndex) {
    const [leaf, offset] = this.editor.getLeaf(cursorIndex);
    this.editor.removeFormat(cursorIndex, 1)
    this.editor.insertEmbed(cursorIndex, 'ogp-div', html, 'user');

    // 上部に入力した場合、OGPが隠れないように位置調整
    const bound = this.editor.getBounds(cursorIndex);
    if (bound.top < 0) {
      this.editor.scrollingContainer.scrollTop += bound.top - 6;
    }
    this.editor.setSelection(cursorIndex + 1);
  }

  // OGP取得エラー時はオートリンクとする
  private createErrorOgp(url, cursorIndex) {
    const [leaf, offset] = this.editor.getLeaf(cursorIndex);
    this.editor.removeFormat(cursorIndex, 1)
    this.editor.insertText(cursorIndex, Constant.elemNewLine, 'user');
    this.editor.insertText(cursorIndex, url, 'link', url, 'user');
    this.editor.setSelection(cursorIndex + url.length + 1);
  }

  // 画像アップロードのデフォルトのハンドラをリセット
  public imageHandler() {
    return;
  }

  // 画像アップロード
  public onImageUpload() {
    const fileInput = document.createElement('input');
    const self = this;
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg');
    fileInput.addEventListener('change', (event: Event) => {
      const target = event.target;
      this.uploadContentsImage(target);
    });
    fileInput.click();
  }

  private uploadContentsImage(target) {
    if (!target || !target.files[0]) {
      return;
    }
    const file = target.files[0];

    // 本文中画像をアップロード
    const maxHeight = Constant.uploadImageHeight;
    const maxWidth = Constant.uploadImageWidth;

    const config = environment.amplify.Storage.contentsEditorImage;
    this.imageUtil
      .uploadImageDataToS3(file, maxHeight, maxWidth, config)
      .then(image_path => {
        // 表示用
        const fileReader = new FileReader();
        fileReader.onload = function() {
          let cursor = this.editor.getSelection().index;
          this.editor.insertEmbed(cursor, 'image', { src: fileReader.result, 'data-path': image_path}, 'user');
          this.editor.setSelection(cursor + 1, 0, 'silent');

        }.bind(this);
        fileReader.readAsDataURL(file);

      })
      .catch(err => {
      });
  }

}
