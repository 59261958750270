import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { ToastUtil } from '../../util/toast-util';
import { CommonUtil } from '../../util/common-util';
import { GeneralYesNoDialogComponent } from './../general-yes-no-dialog/general-yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './../../auth/auth.service';
import { Auth } from '@aws-amplify/auth';
import { QuillUtil } from './../../util/quill-util';

@Component({
  selector: 'app-joboffer-detail-dialog',
  templateUrl: './joboffer-detail-dialog.component.html',
  styleUrls: ['./joboffer-detail-dialog.component.css']
})
export class JobofferDetailDialogComponent implements OnInit {
  public showSpinner = true
  public index;
  public jobOffer;
  public loadFlg = false;
  public pr;
  public desc;
  public closing;
  public subPlaceItems = []
  private loadCheckCnt = 0;
  public readonly Constant = Constant;
  private jobOfferId
  private jobOfferMaster

  constructor(
    public dialogRef: MatDialogRef<JobofferDetailDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private auth: AuthService,
    private quillUtil: QuillUtil,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.index = data.index;
    this.jobOfferId = data.id
    this.jobOffer = data.jobOffer
    this.jobOfferMaster = data.jobOfferMaster;
  }

  ngOnInit() {
    this.getDetail()
  }

  onClose() {
    this.dialogRef.close();
  }

  // 編集画面に切り替え
  onEdit() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    const param = {
      isEdit: true,
      isBase: false,
      index: this.index
    };
    this.dialogRef.close(param);
  }

  // 共通情報に切り替え
  onBase() {
    const param = {
      isEdit: false,
      isBase: true,
      index: this.index
    };
    this.dialogRef.close(param);
  }

  // ステータス変更
  onRelease(isRelease) {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    const param = {
      isEdit: false,
      isBase: false,
      index: this.index,
      isRelease: isRelease
    };

    let message = {};
    if (isRelease) {
      message = Constant.joMsgComfirmRelease;
    } else {
      message = Constant.joMsgComfirmStop;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: message }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close(param);
      }
    });
  }

  onCopy() {
    const target = document.getElementById('url');
    const temp = document.createElement('div');
    temp.appendChild(document.createElement('pre')).textContent = target['value'];
    document.body.appendChild(temp);
    document.getSelection().selectAllChildren(temp);
    const succeeded = document.execCommand('copy');

    if (succeeded) {
      // コピー成功
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
    } else {
      // コピー失敗
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
    }
    document.body.removeChild(temp);
  }

  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/joboffer/detail/' + this.jobOfferId;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        console.log(res)
        if (!res.data.id) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteJobAlreadyError, Constant.toastShowMiliSec);
          const param = {
            isEdit: false,
            isBase: false,
            isReload: true,
          };
          this.dialogRef.close(param);
          return;
        }
        this.jobOffer = res.data;
        this.convertTextJobofferMaster();
        setTimeout(() => {
          this.pr = res.data.pr
          this.desc = res.data.description
          this.closing = res.data.closing
        }, 500);
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  /* エディタ関連 */
  onEditorCreated(quill) {
    // 画像とOGPの差し替え
    this.quillUtil.checkReloadQuill(quill, false);

    // 読み込みチェック
    const loadElementsAll = document.querySelectorAll<HTMLElement>(
      '.ql-container img'
    );
    if (loadElementsAll.length > 0) {
      setTimeout(() => {
        this.checkLoadCnt(loadElementsAll.length)

      }, 500);
    } else {
      this.loadFlg = true
    }
  }

  // 画像読み込みチェック
  private checkLoadCnt(cnt) {
    if (this.loadCheckCnt > 30) {
      // 30回繰り返したら終了させる
      this.loadFlg = true;
      return;
    }

    if (this.quillUtil.loadCnt >= cnt) {
      this.loadFlg = true;
    } else {
      this.loadCheckCnt++
      setTimeout(() => {
        this.checkLoadCnt(cnt)
      }, 500);
    }
  }

  // 職種カテゴリー・勤務地・雇用形態をテキストに変換する
  private convertTextJobofferMaster() {
    // 職種カテゴリー
    for (const jobCategory of this.jobOfferMaster[Constant.joJobType]) {
      if (this.jobOffer.job_category_id === jobCategory.large_id) {
        this.jobOffer.job_category_text = jobCategory.large_item_value;
        const categoryMiddles = this.jobOfferMaster[Constant.joJobType].filter(function(data) {
          return data.large_id === jobCategory.large_id;
        });
        for (const categoryMiddle of categoryMiddles) {
          if (this.jobOffer.job_category_middle_id === categoryMiddle.id) {
            this.jobOffer.job_category_middle_text = categoryMiddle.item_value;
            break;
          }
        }
      }
    }
    // 勤務地
    for (const jobPlace of this.jobOfferMaster[Constant.joJobPlaceType]) {
      if (this.jobOffer.job_place_id === jobPlace.id) {
        this.jobOffer.job_place_text = jobPlace.item_value;
        break;
      }
    }
    // 勤務地（追加）
    this.jobOffer.sub_place.forEach(item => {
      this.subPlaceItems.push(item.item_value + item.city);
    });

    // 雇用形態
    let employmentText = '';
    const employmentTypeAry = this.jobOffer.employment_type.split(',');
    employmentTypeAry.forEach(id => {
      const selectedData = this.jobOfferMaster[Constant.joEmploymentType].filter(function(data) {
        return data.id === Number(id);
      });
      if (selectedData.length > 0) {
        if (employmentText !== '') {
          employmentText = employmentText + '、';
        }
        employmentText = employmentText + selectedData[0].item_value;
      }
    });
    this.jobOffer.employment_type = Number(employmentTypeAry[0]);
    this.jobOffer.employment_text = employmentText;

    // 契約期間
    const contractPeriodId = this.jobOffer.contract_period;
    const contractPeriodData = this.jobOfferMaster[Constant.joContractPeriodType].filter(function(data) {
      return data.id === contractPeriodId;
    });
    if(contractPeriodData.length > 0) {
      this.jobOffer.contract_period = contractPeriodData[0].item_value;
    }

    // 試用期間
    const trialPeriodId = this.jobOffer.trial_period;
    const trialPeriodData = this.jobOfferMaster[Constant.joTrialPeriodType].filter(function(data) {
      return data.id === trialPeriodId;
    });
    if(trialPeriodData.length > 0) {
      this.jobOffer.trial_period = trialPeriodData[0].item_value;
    }

    // 給与
    const salaryTypedId = this.jobOffer.salary_type;
    const salaryTypeData = this.jobOfferMaster[Constant.joSalaryType].filter(function(data) {
      return data.id === salaryTypedId;
    });
    if(salaryTypeData.length > 0) {
      this.jobOffer.salary_type = salaryTypeData[0].item_value;
    }

    // 固定残業
    const overtimeWorkTypedId = this.jobOffer.fixed_overtime;
    const overtimeWorkTypeData = this.jobOfferMaster[Constant.joFixedOvertimeType].filter(function(data) {
      return data.id === overtimeWorkTypedId;
    });
    if(overtimeWorkTypeData.length > 0) {
      this.jobOffer.fixed_overtime = overtimeWorkTypeData[0].item_value;
    }

    this.showSpinner = false;
  }
}
