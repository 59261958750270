import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from '../constant';
import { environment } from './../../environments/environment';
import { CommonUtil } from '../util/common-util';
import { saveAs } from 'file-saver';
import { ToastUtil } from '../util/toast-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { ImageUtil } from '../util/image-util';

@Component({
  selector: 'app-show-file',
  templateUrl: './show-file.component.html',
  styleUrls: ['./show-file.component.css']
})
export class ShowFileComponent implements OnInit {
  public readonly Constant = Constant;
  public loading = true;
  public url;
  public trustUrl;
  public fileExt = '';
  public closeFlg = false;
  public firstLoad = true;
  private type;
  private fileId = 0;
  private fileName;
  private blob;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    private title: Title,
    private imageUtil: ImageUtil,
  ) {
    this.title.setTitle(Constant.pageTitleFile + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.data['type'];
    const id = this.activatedRoute.snapshot.params['id'];
    const path = localStorage.getItem(Constant.lsShowFilePath);

    if (id) {
      // DB登録済みファイルの場合、URL取得
      this.fileId = parseInt(id, 10);
      if (isNaN(this.fileId)) {
        this.router.navigate(['error']);
        return;
      }
      this.getFileUrl();
    } else if (path) {
      // 未送信・アップロードしたファイルの場合
      this.getFileUrlS3(path);
      this.fileName = localStorage.getItem(Constant.lsShowFileName);
    } else {
      // その他はエラー画面に飛ばす
      this.router.navigate(['error']);
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // トークンチェック
    this.commonUtil.checkRefreshToken(this.auth);
  }

  public pdfLoadComplete(pdfData) {
    // PDFの高さを合わせる
    setTimeout(() => {
      const canvas = document.getElementsByClassName('pdfViewer')  as HTMLCollectionOf<HTMLElement>;
      const h = canvas[0].clientHeight;
      document.documentElement.style.setProperty('--viewHeight', h + 'px');
      this.firstLoad = false;
    }, 200);
  }

  // PDF表示エラー
  pdfError() {
    this.trustUrl = null;
  }

  // ダウンロード
  onDownload() {
    saveAs(this.blob, this.fileName);
  }

  // 閉じる
  onClose() {
    this.closeFlg = true;
    this.blob = null;
    window.close();
  }

  // ファイルURL取得
  private getFileUrl() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.loading = false;
      return;
    }
    Auth.currentSession().then(session => {
      const apiPath = '/' + this.type + '/file/' + this.fileId;
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(apiPath, options).then(res => {
          const url = res.data.url;
          this.fileName = res.data.name;
          if (!url) {
            this.loading = false;
            return;
          }
          this.checkUrl(url);
        })
        .catch(err => {
          this.loading = false;
        });
    });
  }

  // ファイルURL取得（S3より）
  private getFileUrlS3(path) {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.loading = false;
      return;
    }

    const config = environment.amplify.Storage.messageFile;

    this.imageUtil.getUploadFileUrlS3(path, config).then(url => {
      this.checkUrl(url);
    })
    .catch(err => {
      this.loading = false;
    });
  }

  // 実際にアクセスしてファイル有無をチェックする
  private checkUrl(url) {
    this.http.get(url, {responseType: 'arraybuffer'}).subscribe(data => {
      this.blob = new Blob([data], {type: Constant.fileFormatDownload});
      this.fileExt = this.fileName.split('.').pop().toLocaleLowerCase();

      // サニタイズ
      this.trustUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.url = url;
      this.loading = false;

    }, err => {
      this.loading = false;
    });
  }
}
