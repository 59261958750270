import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  BehaviorSubject ,  from as fromPromise ,  of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { environment } from './../../environments/environment';
import { Constant } from './../constant';
import { CommonUtil } from '../util/common-util';

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  public user;
  public error;
  public refershToken: string;
  public companyName: string;

  constructor(private router: Router, private commonUtil: CommonUtil) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** ログイン状態の取得 */
  public isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser()).pipe(
      map(result => {
        this.companyName = localStorage.getItem(Constant.lsCompanyName);
        if (!this.companyName) {
          this.loggedIn.next(false);
          return false;
        }
        if (!this.refershToken && result.signInUserSession.refreshToken.token) {
          this.refershToken = result.signInUserSession.refreshToken.token;
        }
        this.loggedIn.next(true);
        return true;
      }),
      catchError(error => {
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }

  /** ログイン */
  public signIn(email, password): Observable<any> {
    this.user = null;
    return fromPromise(Auth.signIn(email, password)).pipe(
      map(result => {
        this.commonUtil.debug().log(result);
        if (result['signInUserSession']) {
          return true;
        }
        if (result['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
          this.user = result;
          return false;
        }
        return false;
      }),
      catchError(error => {
        this.commonUtil.debug().log(error);
        this.error = error;
        return of(false);
      })
    );
  }

  public completeNewPassword(password): Observable<any> {
    return fromPromise(
      Auth.completeNewPassword(this.user, password, this.user['challengeParam']['requiredAttributes'])
    ).pipe(
      map(result => {
        if (result['signInUserSession']) {
          return true;
        }
      }),
      catchError(error => {
        this.commonUtil.debug().log(error);
        this.error = error;
        return of(false);
      })
    );
  }

  public companyLogin(): Promise<any> {
    return Auth.currentSession().then(session => {
      const apiPath = '/login';
      const options = this.createApiHeader(session);

      return this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.loggedIn.next(true);
          this.router.navigate(['/map']);
          localStorage.setItem(Constant.lsCompanyName, res.data.company_name);
          if (!res.data.hide_tutorial_flag) {
            localStorage.setItem(Constant.lsShowTutorial, Constant.lsTrue);
          }
          return Promise.resolve(res);
        })
        .catch(err => {
          this.loggedIn.next(false);
          if (this.commonUtil.apiStatusOther(err)) {
            // メンテナンス中・バージョンエラーの場合、awsからのサインアウトだけはしておく
            Auth.signOut();
          } else {
            this.signOut();
          }
          return Promise.reject(err);
        });
    });
  }

  /** ログアウト */
  public signOut() {
    fromPromise(Auth.signOut()).subscribe(
      result => {
        this.afterSignOut();
      },
      error => {
        this.commonUtil.debug().log(error);
        // エラーでもログアウトしておく
        this.afterSignOut();
      }
    );
  }

  public createApiHeader(session) {
    return {
      headers: {
        'x-api-key': environment.API_KEY,
        Authorization: session.idToken.jwtToken,
        'Content-Type': 'application/json',
        accesstoken: session.accessToken.jwtToken,
        companycode: localStorage.getItem(Constant.lsCompanyCode),
        version: environment.version
      },
      timeout: Constant.apiTimeoutSecond
    };
  }

  public createApiHeaderBeforeLogin() {
    return {
      headers: {
        'x-api-key': environment.API_KEY,
        'Content-Type': 'application/json',
        version: environment.version
      },
      timeout: Constant.apiTimeoutSecond
    };
  }

  public createApiHeaderBeforeLoginForCorp() {
    return {
      headers: {
        'x-api-key': environment.API_KEY_CORP,
        'Content-Type': 'application/json'
      },
      timeout: Constant.apiTimeoutSecond
    };
  }

  /* コラム用のAPIヘッダ作成 */
  public createApiHeaderForAdmin() {
    return {
      headers: {
        'x-api-key': environment.API_KEY_ADMIN,
        'Content-Type': 'application/json',
        version: environment.version
      },
      timeout: Constant.apiTimeoutSecond
    };
  }

  public toLoginPage(): void {
    const companyCode = localStorage.getItem(Constant.lsCompanyCode);
    if (companyCode) {
      this.router.navigate(['/login', companyCode]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private afterSignOut(): void {
    this.loggedIn.next(false);
    const companyCode = localStorage.getItem(Constant.lsCompanyCode);
    localStorage.clear();
    localStorage.setItem(Constant.lsCompanyCode, companyCode);
    localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
    this.companyName = '';
    this.refershToken = '';
    this.toLoginPage();
  }
}
