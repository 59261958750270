<mat-dialog-content>
  <div class="message" *ngIf="data.type == Constant.noticeTypeDel">
    URLを削除しますか？<br /><br />
    <span class="red-text">削除後、元には戻せません。</span>
    <br /><br />
    タレントに付与された接点・経路タグも削除されます。 <br /><br />
    削除したURLにタレントの流入が今後あった場合、デフォルトURLにリダイレクトされます。
  </div>
  <div class="message" *ngIf="data.type == Constant.noticeTypeTalentDel">
    この接点・経路をタレントから削除しますか?
  </div>
  <div class="message" *ngIf="data.type == Constant.noticeTypeTalenttagDel">
    この管理タグをタレントから削除しますか?
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button mat-dialog-close="true" color="primary" class="del-btn">OK</button>
</mat-dialog-actions>
