import { Component, OnInit, HostListener } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { Title } from '@angular/platform-browser';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { MatDialog } from '@angular/material/dialog';
import { NoticeEditDialogComponent } from './../dialog/notice-edit-dialog/notice-edit-dialog.component';
import { NoticeDelDialogComponent } from './../dialog/notice-del-dialog/notice-del-dialog.component';
import { NoticeConfDialogComponent } from './../dialog/notice-conf-dialog/notice-conf-dialog.component';
import { TalentListCondition } from '../model/talent-list-condition';
import { Router } from '@angular/router';
import { MomentUtil } from './../util/moment-util';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-notice-url',
  templateUrl: './notice-url.component.html',
  styleUrls: ['./notice-url.component.css'],
  providers: [Title]
})
export class NoticeUrlComponent implements OnInit {
  public Constant = Constant;
  public url;
  public notices;
  public showSpinner = true;
  public noticesMaxLen = 50;

  constructor(
    private auth: AuthService,
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private router: Router,
    private momentUtil: MomentUtil
  ) {
    this.title.setTitle(Constant.pageTitleNoticeUrl + Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      return;
    }

    this.getUrl();
  }

  // 告知用URL一覧取得
  getUrl() {
    this.notices = null;
    Auth.currentSession().then(session => {
      const apiPath = '/contacttag';
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          this.notices = res.data;
          this.convNoticeData();
          this.showSpinner = false;
        })
        .catch(err => {
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  open(index) {
    const url = this.notices[index].url;
    window.open(url);
  }

  // 埋め込みタグをコピー
  onCopy(type: string, index: string) {
    let element: HTMLElement;
    if (type === 'url') {
      element = document.getElementById('url-code-' + index);
    } else if (type === 'qr') {
      element = document.getElementById('qr-code-' + index);
    } else {
      element = document.getElementById('btn-code-' + index);
    }

    if (element) {
      if (type !== 'url') {
        element.focus();
        element.hidden = false;
      }

      const temp = document.createElement('div');
      temp.appendChild(document.createElement('span')).textContent = element['value'];
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      const succeeded = document.execCommand('copy');

      if (succeeded) {
        // コピー成功
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
      } else {
        // コピー失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
      }
      document.body.removeChild(temp);
    }
  }

  onQrDownload(index) {
    const qr = this.notices[index].qr_path;
    window.open(qr);
  }

  // コードをクリックした時に選択状態にする
  onCodeClick(event: any) {
    event.target.focus();
    event.target.select();
  }

  // 編集・新規作成
  onEdit(index?) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    let notice;
    let type;
    let tagId;
    if (index >= 0) {
      notice = this.notices[index];
      type = Constant.noticeTypeEdit;
      tagId = this.notices[index].id;
    } else {
      notice = { title: '', question: '' };
      type = Constant.noticeTypeAdd;
      // GAイベント
      this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUrlNew);
    }

    const dialogRef = this.dialog.open(NoticeEditDialogComponent, {
      width: Constant.offerDialogWidth,
      height: 'auto',
      maxHeight: '95vh',
      autoFocus: false,
      disableClose: true,
      data: { type: type, notice: notice, tagId: tagId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // ぐるぐる表示
        this.showSpinner = true;
        // 一覧再読み込み
        this.getUrl();
      }
    });
  }

  // 利用する or 停止する
  onUse(index, isUse: boolean) {
    // 確認ダイアログ表示
    const dialogRef = this.dialog.open(NoticeConfDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { isUse: isUse }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // GAイベント
        if (isUse) {
          this.commonUtil.sendGAEvent(
            Constant.gaCategoryButtons,
            Constant.gaActionUrlMenuStatusChangeCloseToOpenOK
          );
        } else {
          this.commonUtil.sendGAEvent(
            Constant.gaCategoryButtons,
            Constant.gaActionUrlMenuStatusChangeOpenToCloseOK
          );
        }
        if (!this.commonUtil.isOnline()) {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          return;
        }

        // ぐるぐる表示
        this.showSpinner = true;
        this.updateNoticeInUse(index, isUse);
      }
    });
  }

  // 削除
  onDel(index) {
    // 確認ダイアログ
    const dialogRef = this.dialog.open(NoticeDelDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { type: Constant.noticeTypeDel }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // GAイベント
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUrlDelete);
        if (!this.commonUtil.isOnline()) {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          return;
        }
        this.showSpinner = true;
        // 削除のAPI呼び出し
        this.delNoticeUrl(index);
      }
    });
  }

  // タレントリストへ
  onToList(id, period) {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.tlContactTagType,
      id: id
    };

    if (period === Constant.tlPeriodTypeThisMonth) {
      // 今月
      condition[1] = {
        type: Constant.tlContactTagStartType,
        id: this.momentUtil.createThisMonthStartDate()
      };
      condition[2] = {
        type: Constant.tlContactTagEndType,
        id: this.momentUtil.createToday()
      };
    } else if (period === Constant.tlPeriodTypeLastMonth) {
      // 先月
      condition[1] = {
        type: Constant.tlContactTagStartType,
        id: this.momentUtil.createLastMonthStartDate()
      };
      condition[2] = {
        type: Constant.tlContactTagEndType,
        id: this.momentUtil.createLastMonthEndDate()
      };
    } else if (period === Constant.tlPeriodTypeTwoMonthAgo) {
      // 先々月
      condition[1] = {
        type: Constant.tlContactTagStartType,
        id: this.momentUtil.createTwoMonthAgoStartDate()
      };
      condition[2] = {
        type: Constant.tlContactTagEndType,
        id: this.momentUtil.createTwoMonthAgoEndDate()
      };
    }

    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    window.open('list');
  }

  // 質問管理
  onQuestion() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate([Constant.rtQuestion]);
  }

  /* プライベート */
  // 接点利用する、停止するAPI呼び出し
  private updateNoticeInUse(index, isUse) {
    const inuse = isUse ? 1 : 0;
    Auth.currentSession().then(session => {
      const apiPath = '/contacttag/inuse';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        contact_tag_id: this.notices[index].id,
        inuse: inuse
      };

      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            // トースト表示
            if (isUse) {
              this.showToast(Constant.msgNoticeUse);
            } else {
              this.showToast(Constant.msgNoticeStop);
            }
          }
          // 一覧を再読み込み
          this.getUrl();
        })
        .catch(err => {
          // トースト表示
          this.toastUtil.clearAllShowingToast();
          if (err.data?.cnt === 0) {
            this.toastUtil.showErrorToast('', Constant.msgNoticeStatusFailed, Constant.toastShowMiliSec);
          } else {
            this.toastUtil.showErrorToast('', Constant.msgNoticeStatusFailedRetry, Constant.toastShowMiliSec);
          }

          // 一覧を再読み込み
          this.getUrl();
        });
    });
  }

  // 削除のAPI呼び出し
  private delNoticeUrl(index) {
    Auth.currentSession().then(session => {
      const apiPath = '/contacttag';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        contact_tag_id: this.notices[index].id
      };

      this.commonUtil
        .apiDel(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.showToast(Constant.msgNoticeDel);
          }
          // 一覧を再読み込み
          this.getUrl();
        })
        .catch(err => {
          // トースト表示
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', sprintf(Constant.msgNoticeUpdateError, '削除'), Constant.toastShowMiliSec);
          // 一覧を再読み込みする
          this.getUrl();
        });
    });
  }

  //
  private convNoticeData() {
    // 追加質問のテキストを配列に変更
    this.notices.forEach(element => {
      if (element.add_question) {
        element.add_question_ary = element.add_question.split('、');
      }
    });
  }

  // トースト表示
  private showToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showInformationToast('', msg, Constant.toastShowMiliSec);
  }
}
