import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUtil } from '../../util/common-util';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastUtil } from '../../util/toast-util';
import { Constant } from './../../constant';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';

@Component({
  selector: 'app-joboffer-adopt-dialog',
  templateUrl: './joboffer-adopt-dialog.component.html',
  styleUrls: ['./joboffer-adopt-dialog.component.css']
})
export class JobofferAdoptDialogComponent implements OnInit {
  public form: FormGroup;
  public adoptDateMin;
  public adoptDateMax;
  public adoptDate;
  public isSaving = false;

  constructor(
    public dialogRef: MatDialogRef<JobofferAdoptDialogComponent>,
    private toastUtil: ToastUtil,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonUtil: CommonUtil,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = new FormGroup({
      date: new FormControl({ value: '', disabled: true }, [Validators.required])
    });
    // カレンダー最小値、最大値
    this.adoptDateMin = new Date(1980, 0, 1);
    this.adoptDateMax = new Date(2034, 11, 31);
  }

  onConfirm() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionKarteApplyEngageOK);
    this.isSaving = true;
    const data = {
      adopt_date: this.adoptDate
    };
    this.postAdoptDate(data);
  }

  setAdoptDate(date) {
    this.adoptDate = this.commonUtil.convertMomentToString(date, '/');
  }

  // 入社予定日の登録
  private postAdoptDate(postData) {
    Auth.currentSession().then(session => {
      const apiPath = '/talent/jobofferaction/adopt/' + this.data.talentId;
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        adopt_date: postData.adopt_date,
        job_offer_action_ids: this.data.adoptIds
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            // ダイアログを閉じてその他を登録
            this.dialogRef.close(postData);
          }
        })
        .catch(err => {
          this.isSaving = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.commonUtil.debug().log(err);
        });
    });
  }
}
