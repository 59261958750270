import { Component, OnInit, HostListener } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { Title } from '@angular/platform-browser';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { MatDialog } from '@angular/material/dialog';
import { PrcontentDetailDialogComponent } from './../dialog/prcontent-detail-dialog/prcontent-detail-dialog.component';
import { PrcontentEditDialogComponent } from './../dialog/prcontent-edit-dialog/prcontent-edit-dialog.component';
import { GeneralYesNoDialogComponent } from './../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { TalentListCondition } from './../model/talent-list-condition';
import { PrcontenttagEditDialogComponent } from '../dialog/prcontenttag-edit-dialog/prcontenttag-edit-dialog.component';
import { Router } from '@angular/router';
import { PrcontentPriorityDialogComponent } from '../dialog/prcontent-priority-dialog/prcontent-priority-dialog.component';

@Component({
  selector: 'app-pr-contents',
  templateUrl: './pr-contents.component.html',
  styleUrls: ['./pr-contents.component.css']
})
export class PrContentsComponent implements OnInit {
  public tellNo = Constant.calinTellNo + Constant.calinWorkTime;
  public supportMail = Constant.calinSupportMail;
  public prContents;
  public tagMaster;
  public apiPath = '/prcontent';
  public readonly Constant = Constant;
  public showSpinner = true;

  constructor(
    private title: Title,
    private router: Router,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {
    this.title.setTitle(Constant.pageTitlePrContent + Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    // 採用PRコンテンツデータを取得する
    this.getPrContentsData();
  }

  // 詳細画面表示
  onDetail(index) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const prContent = this.prContents[index];

    const dialogRef = this.dialog.open(PrcontentDetailDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      data: {
        index: index,
        id: this.prContents[index].id,
        prContent: prContent
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result.reload) {
          // 一覧再読み込み
          this.getPrContentsData();
        } else if (result.isEdit) {
          // 編集画面を表示する
          this.onEdit(result.index);
        } else {
          // ステータス更新
          this.releaseExec(result.index, result.isRelease);
        }
      }
    });
  }

  // 編集画面表示
  onEdit(index?) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    let type;
    let content_id = 0;
    if (index != null) {
      content_id = this.prContents[index].id;
      type = Constant.joTypeEdit;
    } else {
      type = Constant.joTypeAdd;
    }

    const dialogRef = this.dialog.open(PrcontentEditDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        id: content_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getPrContentsData();
      }
    });
  }

  // 公開ステータス変更
  onRelease(index, isRelease) {
    let message = {};
    if (isRelease) {
      message = Constant.pcMsgComfirmRelease;
    } else {
      message = Constant.pcMsgComfirmStop;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: message }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.releaseExec(index, isRelease);
      }
    });
  }

  // 削除
  onDel(index) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.pcMsgComfirmDel }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        // 削除のAPI呼び出し
        this.delPrContents(this.prContents[index].id);
      }
    });
  }

  // 閲覧数、いいねのリンクタップ
  onToList(action, index) {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.pcTitleType,
      id: this.prContents[index].id
    };
    condition[1] = {
      type: Constant.pcActionType,
      id: action
    };
    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    window.open('list');
  }

  // 詳細URLをコピー
  onUrlCopy(index: string) {
    let element: HTMLElement;
    element = document.getElementById('url-code-' + index);

    if (element) {
      const temp = document.createElement('div');
      temp.appendChild(document.createElement('span')).textContent = element['value'];
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      const succeeded = document.execCommand('copy');

      if (succeeded) {
        // コピー成功
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
      } else {
        // コピー失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
      }
      document.body.removeChild(temp);
    }
  }

  // 優先表示
  onPriority() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(PrcontentPriorityDialogComponent, {
      width: '800px',
      height: Constant.tagEditDialogHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // 再読み込み
        this.getPrContentsData();
      }
    });
  }

  // タグマスタ編集
  onTagRegist() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(PrcontenttagEditDialogComponent, {
      width: '530px',
      height: Constant.tagEditDialogHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // 再読み込み
        this.getPrContentsData();
      }
    });
  }

  // カテゴリー管理
  onCategory() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate([Constant.rtPrContentsCategory]);
  }

  /*
   * プライベート関数
   */
  // 公開ステータス変更
  private releaseExec(index, isRelease) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/status';
      const options = this.auth.createApiHeader(session);
      const email = localStorage.getItem(Constant.lsOperator);
      let toastMessage = Constant.empty;
      options['body'] = {
        id: this.prContents[index].id,
        email: email
      };
      if (isRelease) {
        options['body']['release_status'] = Constant.pcReleaseStatusRelease;
        toastMessage = Constant.msgStatusRelease;
      } else {
        options['body']['release_status'] = Constant.pcReleaseStatusStop;
        toastMessage = Constant.msgStatusNotRelease;
      }
      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          this.showToast(toastMessage);
          this.getPrContentsData();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.status === Constant.NG) {
            this.showErrorToast(Constant.msgNoticeStatusFailed);
          } else {
            this.showErrorToast(Constant.msgNoticeStatusFailedRetry);
          }
          this.getPrContentsData();
        });
    });
  }

  // 採用PRコンテンツ情報を取得する
  private getPrContentsData() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      return;
    }

    // ぐるぐる表示
    this.showSpinner = true;
    this.prContents = null;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          this.prContents = res.data;
          this.getImageUrl();
          // コンテンツのタグマスターデータを取得する
          this.getContentsTagMaster();
          this.commonUtil.debug().log(this.prContents);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // 採用PRコンテンツ情報を取得する
  private getContentsTagMaster() {
    // ぐるぐる表示
    const masterApiPath = this.apiPath + '/master';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(masterApiPath, options)
        .then(res => {
          this.tagMaster = res.data;
          this.convertIdToText();
          this.commonUtil.debug().log(this.tagMaster);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
        });
    });
  }

  // 採用PRコンテンツ画像のURLを取得する
  private getImageUrl() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const urlApiPath = this.apiPath + '/url/';
      const ids = [];
      for (const content of this.prContents) {
        ids.push(content.id);
      }
      options['body'] = {
        ids: ids
      };
      this.commonUtil
        .apiPost(urlApiPath, options)
        .then(res => {
          for (const content of this.prContents) {
            const urldata = res.data.filter(function(data) {
              return content.id === data.id;
            });
            content.image_url = urldata[0].url;
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    });
  }

  // 採用PRコンテンツの削除API呼び出し
  private delPrContents(index) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        contents_id: index
      };

      this.commonUtil
        .apiDel(this.apiPath, options)
        .then(res => {
          this.showToast(Constant.msgNoticeDel);
          this.getPrContentsData();
        })
        .catch(err => {
          this.showErrorToast(Constant.msgNoticeDelFailed2);
          this.getPrContentsData();
        });
    });
  }

  // トースト表示
  private showToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showInformationToast(Constant.empty, msg, Constant.toastShowMiliSec);
  }

  // エラートースト表示
  private showErrorToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showErrorToast(Constant.empty, msg, Constant.toastShowMiliSec);
  }

  // タグIDをテキストに変換する
  private convertIdToText() {
    for (const prContent of this.prContents) {
      const textAry = [];
      for (let i = 1; i <= 5; i++) {
        if (prContent['tag' + i] !== 0) {
          const tag = this.tagMaster.filter(function(data) {
            return data.id === prContent['tag' + i];
          });
          if (tag.length > 0) {
            textAry.push(tag[0].title);
            prContent['tag' + i + '_text'] = tag[0].title;
          }
        }
      }
      if (textAry.length === 0) {
        textAry.push(Constant.textNotRegist);
      }
      prContent.tagTextAry = textAry;
    }
    this.showSpinner = false;
  }
}
