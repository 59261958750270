import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { PaginationInstance } from 'ngx-pagination';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';

@Component({
  selector: 'app-talent-list-common',
  templateUrl: './talent-list-common.component.html',
  styleUrls: ['./talent-list-common.component.css']
})
export class TalentListCommonComponent implements OnInit {
  @Input() talentData;
  @Output() checkTalent = new EventEmitter();
  @Output() checkTalentAll = new EventEmitter();
  public readonly Constant = Constant;
  public favFlgSending;
  public newGraduateYear = 0;

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) { }

  ngOnInit(): void {
    // ページング設定
    if (!this.talentData.pagingConfig) {
      const config: PaginationInstance = {
        id: 'talents',
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: 10
      };
      this.talentData.pagingConfig = config;
    }

    // 現在の新卒年度を計算
    this.newGraduateYear = this.commonUtil.getNewGraduateYear();
  }

  // タレントカルテ表示
  onShowTalentKarte(id: number, csv_flag: number) {
    let url = 'detail/';
    if (csv_flag === 1) {
      url += 'c/';
    }
    window.open(url + id);
  }

  // チェック
  onCheck(idx) {
    // 親コンポーネントでチェック状態管理
    this.checkTalent.emit(idx);
  }

  // 一括チェック
  onCheckAll() {
    this.checkTalentAll.emit(this.talentData.allCheckFlg);
  }

  // お気に入りフラグ★ボタン押下時の処理
  onFavoriteButton(t_id: number, f_flag: number, idx) {
    if (!this.talentData.listFlg || this.favFlgSending) {
       return;
    }

    // ネットワークチェック
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    // 更新処理中はボタン押下無効化
    this.favFlgSending = true;

    // APIへ渡す、登録するフラグの値をセット
    let RegistrationFlgNo: number;
    if (f_flag === 0) {
      // 現在のフラグが0=未登録の場合は1を渡す
      RegistrationFlgNo = 1;
    } else {
      // 現在のフラグが1=登録の場合は0を渡す
      RegistrationFlgNo = 0;
    }

    // API接続
    Auth.currentSession().then(session => {
      const apiPath = '/talentfavorite';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        talent_id: t_id,
        favorite_flag: RegistrationFlgNo
      };
      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          // 更新したタレント1件のフラグを変更
          this.talentData.talents[idx].favorite_flag = RegistrationFlgNo;
          // ★ボタン押下無効を解除
          this.favFlgSending = false;
        })
        .catch(err => {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgFavoriteError, Constant.toastShowMiliSec);
          // ★ボタン押下無効を解除
          this.favFlgSending = false;
        });
    });
  }

}
