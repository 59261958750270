import { environment } from './../../environments/environment';
import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from '@aws-amplify/api';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-lp-case',
  templateUrl: './lp-case.component.html',
  styleUrls: ['./lp-case.component.css']
})
export class LpCaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly Constant = Constant;
  public columnImgUrl = environment.COLUMN_IMG_URL;
  public caseList = [];
  public showSpinner = true;
  public caseId = 0;
  public detailData;

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonUtil: CommonUtil,
    private domSanitizer: DomSanitizer,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private dom
  ) {
    const id = this.activatedRoute.snapshot.params['id'];
    if (id) {
      this.caseId = parseInt(id, 10);
    }

    this.getCase();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  ngAfterViewInit(): void {
    // URLが変更されたら再読み込みする
    this.activatedRoute.url.subscribe(params => {
      if (params[1] && params[1].path !== this.caseId.toString()) {
        this.caseId = parseInt(params[1].path, 10);
        this.detailData = null;
        this.deleateCanonicalURL();
        this.getCase();
        window.scroll(0, 0);
      }
    });
  }

  ngOnDestroy(): void {
    this.deleateCanonicalURL();
  }

  public ellipsisText(text: string, maxLen: number) {
    // HTMLタグを消去
    text = text.replace(/(<([^>]+)>)/gi, '');

    const len = text.length;
    if (len <= maxLen) {
      return text;
    } else {
      return text.substr(0, maxLen) + '… ';
    }
  }

  private getCase() {
    let url = '';
    if (this.caseId) {
      this.getCaseDetail();
      url = this.dom.location.origin + '/' + Constant.rtCase + '/' + this.caseId;
    } else {
      this.getCaseList();
      this.setSeoMeta(Constant.pageTitleRoot, Constant.pageDescriptionRoot);
      url = this.dom.location.origin + '/' + Constant.rtCase;
    }

    this.createCanonicalURL(url);
  }

  // 一覧取得
  private getCaseList() {
    const apiPath = '/column/usagelist/1';
    const options = this.auth.createApiHeaderForAdmin();

    API.get('admin', apiPath, options).then(res => {
      this.commonUtil.debug().log(res);
      if (res.data) {
        this.caseList = res.data;
        this.showSpinner = false;
      }
    })
    .catch(err => {
      this.commonUtil.debug().log(err);
    });
  }

  // 詳細取得
  private getCaseDetail() {
    const apiPath = '/column/usage/' + this.caseId;
    const options = this.auth.createApiHeaderForAdmin();

    API.get('admin', apiPath, options).then(res => {
      if (!res.data || res.data.release_status !== 1) {
        // データが取得できない場合、非公開の場合は一覧に飛ばす
        this.router.navigate([Constant.rtCase]);
        return;
      }
      this.detailData = res.data;
      this.createHtml(this.detailData);
      this.showSpinner = false;
      this.setSeoMeta(this.detailData.title + Constant.metaCalin, this.ellipsisText(this.detailData.content, 200));
    })
    .catch(err => {
      this.commonUtil.debug().log(err);
    });
  }

  // コンテンツのHTML作成
  private createHtml(data) {
    data.contentHtml = this.domSanitizer.bypassSecurityTrustHtml(data.content);
  }

  // SEOのメタタグ設定
  private setSeoMeta(title, description) {
    this.title.setTitle(title);
    this.meta.updateTag({name: 'description', content: description});
  }

  // canonicalタグ設定
  createCanonicalURL(url) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

  // canonicalタグ削除
  deleateCanonicalURL() {
    const doms = Array.from(this.dom.head.querySelectorAll('[rel=\'canonical\']'));
    doms.forEach((elm: any) => {
      this.dom.head.removeChild(elm);
    });
  }
}
