<div class="title-area">
  <h2 mat-subheader class="title">一斉メッセージ</h2>
  <div class="btn-new">
    <button mat-raised-button color="warn" [disabled]="showSpinner" (click)="onPost()">
      新規作成
    </button>
    <button mat-raised-button class="except-btn" (click)="onExceptInfo()">
      除外リスト
    </button>
  </div>
</div>
<div class="desc">
  一斉メッセージを送信するには、(1)右上の「新規作成」を押下、(2)一覧に表示される「新規登録」からメッセージと送信対象を登録、(3)Menuから「送信」を選択してください。<br>
  一斉メッセージを送信しないタレントの一覧は、右上の「除外リスト」をご覧ください。<br>
  （　）内は、一斉メッセージを送信したタレントのうち、現在もタレントプールに登録している人数です。
</div>
<div *ngIf="showSpinner && !isSearching" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="container">
<table *ngIf="!showSpinner || messages" class="table">
  <tr>
    <th class="status">ステータス</th>
    <th class="count">送信対象</th>
    <th class="message">メッセージ</th>
    <th class="date">送信日</th>
    <th class="open">既読率</th>
    <th class="memo">管理用タイトル・メモ</th>
    <th>Menu</th>
  </tr>
  <ng-container *ngFor="let msg of messages; index as idx">
    <tr>
      <!-- ステータス -->
      <td *ngIf="msg.send_date" class="status sended">
        <img
          src="assets/notice/use-check.png"
          srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x
          "><br>送信済
      </td>
      <td *ngIf="!msg.send_date" class="status unsend">
        <img
          src="assets/notice/default-check.png"
          srcset="
            assets/notice/default-check.png    1x,
            assets/notice/default-check@2x.png 2x,
            assets/notice/default-check@3x.svg 3x
          "><br>未送信
      </td>
      <!-- 送信対象 -->
      <td class="count link-bold">
        <div class="type" *ngIf="msg.talent_csv_flag==0">自己登録</div>
        <div class="type" *ngIf="msg.talent_csv_flag==1">手動/CSV取込</div>
        <ng-container *ngIf="msg.send_date && msg.talent_cnt_all_send>0">{{msg.talent_cnt_all_send}}　<br>（<a (click)="onToList(msg.id, '')">{{msg.talent_cnt_send}}</a>）</ng-container>
        <ng-container *ngIf="!msg.send_date && msg.talent_cnt_unsent>0"><a (click)="onEditTalent(idx)">{{msg.talent_cnt_unsent}}　</a></ng-container>
        <div *ngIf="!msg.send_date && msg.talent_cnt_unsent==0">
          <a class="data-edit-link" (click)="onEditTalent(idx)">新規登録</a>
        </div>
      </td>
      <!-- メッセージ -->
      <td *ngIf="msg.message && msg.talent_csv_flag == 1" class="message"><a (click)="onEditMessage(idx)">{{ msg.mail_title }}</a></td>
      <td *ngIf="msg.message && msg.talent_csv_flag == 0" class="message"><a (click)="onEditMessage(idx)">{{ellipsisText(msg.message, 100)}}</a></td>
      <td *ngIf="!msg.message">
        <a class="data-edit-link" (click)="onEditMessage(idx)">新規登録</a>
      </td>
      <!-- 送信日 -->
      <td *ngIf="msg.send_date" class="date">{{msg.send_date}}</td>
      <td *ngIf="!msg.send_date" class="date">-</td>
      <!-- 既読率 -->
      <td *ngIf="msg.send_date" class="open link-bold">
        <span>{{ calcReadedRatio(msg.cnt_readed, msg.cnt_unread) }}%</span>
        <table class="open-ratio">
          <tr><td>既読 ：</td><td class="short">{{msg.cnt_readed}}</td><td class="long">（<a (click)="onToList(msg.id, Constant.multiMsgActionRead)">{{msg.cnt_readed_talent}}</a>）</td></tr>
          <tr><td>未読 ：</td><td class="short">{{msg.cnt_unread}}</td><td class="long">（<a (click)="onToList(msg.id, Constant.multiMsgActionUnRead)">{{msg.cnt_unread_talent}}</a>）</td></tr>
        </table>
      </td>
      <td *ngIf="!msg.send_date" class="open">-</td>
      <!-- 管理メモ -->
      <td *ngIf="msg.memo || msg.memo_title" class="memo">
        <a (click)="onEditMemo(idx)" *ngIf="msg.memo_title" class="title">{{msg.memo_title}}</a>
        <a (click)="onEditMemo(idx)" class="title gray" *ngIf="!msg.memo_title">タイトル未設定</a>
        <a (click)="onEditMemo(idx)" *ngIf="msg.memo">{{ellipsisText(msg.memo, 30)}}</a>
        <a (click)="onEditMemo(idx)" class="gray" *ngIf="!msg.memo">メモ未設定</a>
      </td>
      <td *ngIf="!msg.memo && !msg.memo_title">
        <a class="data-edit-link gray" (click)="onEditMemo(idx)">新規登録</a>
      </td>
      <!-- メニュー -->
      <td class="btn-edit">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" overlapTrigger="false">
          <button mat-menu-item (click)="onSend(idx)" *ngIf="!msg.send_date && msg.talent_cnt_unsent>0 && msg.message">送信</button>
          <button mat-menu-item (click)="onCopy(idx)">コピー</button>
          <button mat-menu-item (click)="onDel(idx)" *ngIf="!msg.send_date">削除</button>
        </mat-menu>
      </td>
    </tr>

  </ng-container>
</table>
<div class="spinner" *ngIf="messages && showSpinner">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<div class="data-none" *ngIf="!showSpinner && (!messages || messages.length==0)">一斉メッセージがありません。</div>
</div>



