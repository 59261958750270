import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-general-message-dialog',
  templateUrl: './general-message-dialog.component.html',
  styleUrls: ['./general-message-dialog.component.css'],
})
export class GeneralMessageDialogComponent implements OnInit {
  public message;
  public safeHtml: SafeHtml;

  constructor(
    public dialogRef: MatDialogRef<GeneralMessageDialogComponent>,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data;
    // メッセージテキストの一部色変更のためにHTMLコードを変換
    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  ngOnInit() { }

  onOK() {
    this.dialogRef.close(true);
  }
}
