import { Component, OnInit, HostListener } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Title } from '@angular/platform-browser';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from './../util/toast-util';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { CompanyProfileDialogComponent } from '../dialog/company-profile-dialog/company-profile-dialog.component';
import { CompanyMessageDialogComponent } from '../dialog/company-message-dialog/company-message-dialog.component';
import { CompanyAboutDialogComponent } from '../dialog/company-about-dialog/company-about-dialog.component';
import { CompanyColorDialogComponent } from '../dialog/company-color-dialog/company-color-dialog.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  public companyInfo = null;
  public isLoading = true;
  public registAll = false;
  public registProfile = false;
  public registMessage = false;
  public registAbout = false;
  public readonly Constant = Constant;
  private apiPath = '/company';

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  constructor(
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    private dialog: MatDialog,
  ) {
    this.title.setTitle(Constant.pageTitleCompanyInfo + Constant.pageTitleCommon);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.getCompanyData();
  }

  onProfile() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    const dialogRef = this.dialog.open(CompanyProfileDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getCompanyData();
      }
    });

  }

  onMessage() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    const dialogRef = this.dialog.open(CompanyMessageDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getCompanyData();
      }
    });
  }

  onAbout() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    const dialogRef = this.dialog.open(CompanyAboutDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getCompanyData();
      }
    });
  }

  // カラー変更
  onChangeColor() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const mainColor = this.companyInfo.main.main_color ? this.companyInfo.main.main_color: Constant.companyMainColor

    const dialogRef = this.dialog.open(CompanyColorDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        mainColor: mainColor,
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCompanyData();
      }
    });
  }

  // privateメソッド
  // 企業情報を取得する
  private getCompanyData() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          if (res.data) {
            this.companyInfo = res.data;
            this.isLoading = false;
            this.checkRegistStatus()
          }
          this.commonUtil.debug().log(this.companyInfo);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.isLoading = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // 企業情報の登録状況チェック
  private checkRegistStatus() {
    //　業種・勤務地が登録されていればProfileOK
    if (this.companyInfo.main.industry && this.companyInfo.main.employment_area) {
      this.registProfile = true;
    } else {
      this.registProfile = false;
    }

    //　ヘッドラインが登録されていればMessageOK
    if (this.companyInfo.main.message_headline) {
      this.registMessage = true;
    } else {
      this.registMessage = false;
    }

    // aboutが1つ以上登録されていればAboutOK
    if (this.companyInfo.about.length>0) {
      this.registAbout = true;
    } else {
      this.registAbout = false;
    }

    if (this.registProfile && this.registMessage && this.registAbout) {
      this.registAll = true;
    } else {
      this.registAll = false;
    }
  }
}
