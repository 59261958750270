// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'dev',
  version: '1.0.0',
  amplify: {
    Auth: {
      identityPoolId: 'ap-northeast-1:ec20a79b-15ae-4e0b-90d3-46f8b48d88b1',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_hndOO7Zbr',
      userPoolWebClientId: '7fj4lpetn72qt8c21ll3u6qlci'
    },
    API: {
      endpoints: [
        {
          name: 'company',
          endpoint: 'https://tkqvbxtd16.execute-api.ap-northeast-1.amazonaws.com/dev'
        },
        {
          name: 'corp',
          endpoint: 'https://jmoore2ge7.execute-api.ap-northeast-1.amazonaws.com/dev'
        },
        {
          name: 'admin',
          endpoint: 'https://kjxitb5e1i.execute-api.ap-northeast-1.amazonaws.com/dev'
        }
      ]
    },
    Storage: {
      offerCoverimage: {
        bucket: 'dev.talentpool.company.coverimage',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyLogo: {
        bucket: 'dev.talentpool.company.logo',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyPrImage: {
        bucket: 'dev.talentpool.company.primage',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyAboutMainImage: {
        bucket: 'dev.talentpool.company.about.main',
        region: 'ap-northeast-1',
        level: 'private'
      },
      companyDocument: {
        bucket: 'dev.talentpool.company.document',
        region: 'ap-northeast-1',
        level: 'private'
      },
      contentsImage: {
        bucket: 'dev.talentpool.company.contents',
        region: 'ap-northeast-1',
        level: 'private'
      },
      contentsCategoryImage: {
        bucket: 'dev.talentpool.company.contentscategory',
        region: 'ap-northeast-1',
        level: 'private'
      },
      contentsEditorImage: {
        bucket: 'dev.talentpool.company.contents.editor',
        region: 'ap-northeast-1',
        level: 'private'
      },
      jobofferImage: {
        bucket: 'dev.talentpool.company.jobofferimage',
        region: 'ap-northeast-1',
        level: 'private'
      },
      messageFile: {
        bucket: 'dev.talentpool.common.messagefile',
        region: 'ap-northeast-1',
        level: 'private'
      },
      talentcsv: {
        bucket: 'dev.talentpool.company.talentcsv',
        region: 'ap-northeast-1',
        level: 'private'
      },
    }
  },
  API_KEY: 'vKVl2xB7E44NksZvcNUUR4MpEHEBgd2U9tRw2yoJ',
  AnalyticsTrackingId: 'G-CZ8CDKEYVZ',
  API_KEY_CORP: 'o5N89hWvX19w2HEdBJHYS4oWAVKtUZp21LMJnXIF',
  CORP_URL: 'https://d3mod1tgsfs16c.cloudfront.net',
  API_KEY_ADMIN: 'eeB5Ms5EXy13fySNcchE03ApNLuIJTUE2HIArxEG',
  COLUMN_IMG_URL: 'https://dev.company.calin.co.jp/assets/column/',
};
