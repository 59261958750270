<mat-dialog-content>
  <div class="message">
    採用おめでとうございます！<br />
    入社(予定)日を記入の上、「採用合意」ステータスを確定してください。
  </div>
  <form [formGroup]="form">
    <div class="joined-date">
      <mat-form-field class="input-date" (click)="picker.open()" hideRequiredMarker="true">
        <mat-label>入社(予定)日</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [min]="adoptDateMin"
          [max]="adoptDateMax"
          (dateChange)="setAdoptDate($event.value)"
          formControlName="date"
          class="input-date-text"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button
    mat-raised-button
    (click)="onConfirm()"
    [disabled]="form.invalid || !adoptDate || isSaving"
    class="button-confirm"
  >
    確定する
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
