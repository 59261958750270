<mat-dialog-content>
  <div class="content">
    <form [formGroup]="form">
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 管理用タイトル</span>（タレントには公開されません）<span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="title">
          <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
          <input
            class="input-text title"
            formControlName="title"
            #title
            placeholder="入力してください。"
            type="text"
          />
        </div>
      </div>
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 質問</span>
          <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div>
          <div class="label-count" [class.length-red]="questionText.value.length>maxLenQuestion">{{ questionText.value.length }}/{{ maxLenQuestion }}</div>
          <textarea
            class="textarea"
            formControlName="question"
            #questionText
            placeholder="質問本文を入力してください。"></textarea>
        </div>
      </div>
      <div class="input-container" [class.gray]="question.id">
        <div class="label">
          <img *ngIf="!question.id"
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <img *ngIf="question.id"
            src="assets/notice/mark_gray.png"
            srcset="
              assets/notice/mark_gray.png   1x,
              assets/notice/mark_gray@2x.png 2x,
              assets/notice/mark_gray@3x.svg 3x
            "
          />
          <span class="font-bold"> 回答形式</span>（作成後に変更できません）<span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="indent-radio">
          <mat-radio-group formControlName="type" (change)="onTypeChange($event.value)">
            <mat-radio-button color="primary" [value]="Constant.aqAnswerTypeTextId" #radiotext [disabled]="question.id">フリーテキスト（1000字）</mat-radio-button>
            <mat-radio-button color="primary" [value]="Constant.aqAnswerTypeOneSelectId" [disabled]="question.id">単一選択</mat-radio-button>
            <mat-radio-button color="primary" [value]="Constant.aqAnswerTypeMultiSelectId" [disabled]="question.id">複数選択</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- 回答形式選択肢 -->
      <div class="indent" *ngIf="!radiotext.checked">
        <div class="input-container">
          <div class="label">
            <img
              src="assets/notice/mark_blue.png"
              srcset="
                assets/notice/mark_blue.png    1x,
                assets/notice/mark_blue@2x.png 2x,
                assets/notice/mark_blue@3x.svg 3x
              "
            />
            <span class="font-bold"> 選択肢</span>
            <span class="required">{{ Constant.prefixRequire }}（2つ以上）</span>
          </div>
          <div class="label-desc" *ngIf="question.id">
            タレントが回答済の選択肢テキストを変更する場合、<span class="red">回答結果のテキストも変更</span>されます。
          </div>
          <table class="select-table">
            <tr>
              <th>表示順</th>
              <th>選択肢</th>
              <th class="item-no">管理ID</th>
            </tr>
            <tr *ngFor="let item of selectItemAry;">
              <td>
                <mat-form-field class="input-select order_no no-line">
                  <mat-select [formControlName]="'itemorderno_'+item.item_no" panelWidth="">
                    <mat-option [value]="0">非表示</mat-option>
                    <ng-container  *ngFor="let nom of selectItemAry; index as idx">
                      <mat-option [value]="idx+1">
                        {{ idx+1 }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td class="item-title">
                <div class="label-count" [class.length-red]="itemtitle.value.length>maxLenTypeItem">{{ itemtitle.value.length }}/{{ maxLenTypeItem }}</div>
                <input
                  class="input-text"
                  [formControlName]="'itemtitle_'+item.item_no"
                  #itemtitle
                  placeholder="選択肢を入力してください。"
                  type="text">
              </td>
              <td class="item-no">
                {{ item.item_no }}
              </td>

            </tr>
          </table>
        </div>
      </div>

      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x"/>
          <span class="font-bold"> この質問の表示順</span>
          <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="indent margintop">
          <mat-form-field class="input-select order_no no-line">
            <mat-select formControlName="order_no" panelWidth="">
              <ng-container  *ngFor="let num of orderNum; let n = index">
                <mat-option  [value]="n+1">
                  {{ n+1 }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          他の質問と順番が重複した場合、作成が新しい方を上位表示します。
        </div>
      </div>

    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="disabledSaveBtn()" class="yes-btn">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
