<section id="worries">
  <div class="title-area midashi-font">
    新卒採用のご担当者さま<br>
    このようなお悩みはありませんか？
  </div>
  <div class="picture-area">
    <div class="picture">
      <div class="image">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo01.png" width="150" />
        </picture>
      </div>
      <div class="back-yellow">
        <span>就活前</span>から
      </div>
      <div class="title color-navy">
        学生との接点を<br>構築したい
      </div>
      <div class="description">
        新卒採用の母集団を最大化するために、就活前の学生と接点をたくさん作っておきたい。<br>
        他社に先駆けて優秀な人材とつながり、採用を優位に進めたい。
      </div>
    </div>
    <div class="picture">
      <div class="image bottom">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo02.png" width="200" />
        </picture>
      </div>
      <div class="title color-navy">
        志望意欲を高めて<br>選考辞退を減らしたい
      </div>
      <div class="description">
        就活解禁前から企業理解を深めておき、志望意欲が高まった状態で選考をスタートしたい。<br>
        そのために、自社の情報を手軽に掲載・発信できるツールが欲しい。
      </div>
    </div>
    <div class="picture">
      <div class="image">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo03.png" width="190" />
        </picture>
      </div>
      <div class="back-yellow">
        <span>就活後</span>も
      </div>
      <div class="title color-navy">
        つながりを<br>無駄にしたくない
      </div>
      <div class="description">
        数多の努力の末に築き上げた学生との関係性を、就活終了と共に失うのはもったいない。<br>
        第二新卒や中途採用の潜在候補者として、つながりを維持したい。
      </div>
    </div>
  </div>
</section>
<section id="merit1">
  <div class="title-area midashi-font center">
    そのお悩み、CaLinなら　<br>
    解決できるかもしれません。
  </div>
  <div class="left">
    <div class="title-area midashi-font show-pc">
      就活前の学生をタレントプールできる
    </div>
    <div class="title-area midashi-font show-sp">
      就活前の学生を<br>タレントプールできる
    </div>
    <div class="text">
      <p>CaLinに企業情報を登録すると、<a href="{{Constant.footerUrlTalent}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopTalentLPText)">CaLinのタレント集客サイト</a>に掲載されます。</p>
      <p>CaLinのタレント集客サイトには、就活前から情報収集に励む意欲的かつ情報アンテナの高い学生が日々登録しています。</p>
      <p>なお、集客をさらに強化したい場合、CaLinによって生成されるエントリーページへの<span class="red">リンクを自社の採用サイトに設置する</span>のが効果的です。</p>
    </div>
  </div>
  <div class="image">
    <picture>
      <a href="{{Constant.footerUrlTalent}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopTalentLPPic)">
        <img lazyLoad="../../assets/lp/img_talent.jpg" width="600" />
      </a>
    </picture>
  </div>
  <div class="attribute">
    <div class="bold">新規登録ユーザの属性</div>
    <div class="small">※集計期間：2021年7-10月</div>
    <div class="flex">
      <img lazyLoad="/assets/lp/graph1.png" width="300" />
      <img lazyLoad="/assets/lp/graph2.png" width="300" />
      <img lazyLoad="/assets/lp/graph3.png" width="300" />
    </div>
  </div>
</section>
<section id="merit2" class="center">
  <div class="title-area midashi-font show-pc">
    志望意欲を高めることができる
  </div>
  <div class="title-area midashi-font show-sp">
    志望意欲を<br>高めることができる
  </div>
  <div class="desc">
    CaLinには、企業理解や志望意欲を高めるための機能が豊富に揃っています。<br>
    採用PRコンテンツや求人情報に掲載数の上限や掲載期間の制限はありません。<br>
    メッセージの送信も無制限です。
  </div>
  <div class="flex">
    <div class="col">
      <div class="image">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo04.png" width="180" />
        </picture>
      </div>
      <div class="text">
        <b>会社説明会の<br>スライドを掲載</b>
      </div>
    </div>
    <div class="col">
      <div class="image">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo05.png" width="170" />
        </picture>
      </div>
      <div class="text">
        <b>採用PRコンテンツ<br class="show-sp">を掲載</b><br><span class="small">（テキスト・画像）</span>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="col">
      <div class="image height">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo06.png" height="110" />
        </picture>
      </div>
      <div class="text">
        <b>求人情報を掲載</b><br><span class="small">（インターン募集可）</span>
      </div>
    </div>
    <div class="col">
      <div class="image">
        <picture>
          <img lazyLoad="../../assets/lp/kigyo07.png" width="180" />
        </picture>
      </div>
      <div class="text">
        <b>個別または一斉に<br>メッセージを送信</b>
      </div>
    </div>
  </div>
</section>
<section id="merit3">
  <div class="left">
    <div class="title-area midashi-font show-pc">
      就活後もつながり続けることができる
    </div>
    <div class="title-area midashi-font show-sp">
      就活後もつながり<br>続けることができる
    </div>
    <div class="text">
      <p>CaLinでは、採用年度ごとに候補者データを削除しません。</p>
      <p>新卒採用を通じて企業に良い印象を持った方は、そのまま企業のタレントプールに残り続けます。</p>
      <p>3年で30%以上の新卒が転職する今、候補者とつながり続けることは、第二新卒や中途の母集団形成に有効です。</p>
      <p>新卒採用で多くの学生に費やした努力は毎年積み重なり、将来の中途採用チャネルへと花咲きます。</p>
    </div>
  </div>
  <div class="image">
    <picture>
      <img lazyLoad="../../assets/lp/kigyo08.png" width="450" />
    </picture>
  </div>
</section>
<section id="merit4" class="center">
  <div class="title-area midashi-font show-pc">
    さらに、高い反応率が期待できる
  </div>
  <div class="title-area midashi-font show-sp">
    さらに、<br>高い反応率が期待できる
  </div>
  <div class="flex">
    <div class="image">
      <picture>
        <img lazyLoad="../../assets/lp/kigyo09.png" width="300" />
      </picture>
    </div>
    <div class="text">
      <p>候補者は、企業とつながるとメッセージのやりとりが可能となり、つながった企業の最新情報がマイページに届くようになります。<br>
        逆に、つながっていない企業からメッセージやスカウトが届くことはありません。<br>
        さらに、候補者は、企業とのつながりを自由に解消することができます。</p>
      <p>つまり、候補者が待ち望む企業だけの情報やメッセージが届くので、既読率や返信率は高くなっています。</p>
      <p>企業にとっては、受信を拒否する候補者の情報削除やオプトアウトに手間をかけず、自社に気持ちが向いている候補者だけに効率よくアプローチできるのではないでしょうか？</p>
      <p>また、CaLinの管理画面では、候補者による最終アクセスなどのアクティブ状況がわかったり、どのような採用PRコンテンツに興味を持っているかなどの趣向がわかるので、アプローチの反応率を上げる工夫に役立ちます。</p>
    </div>
  </div>
</section>
