import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Constant } from './../../constant';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { MatDialogRef } from '@angular/material/dialog';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-joboffer-priority-dialog',
  templateUrl: './joboffer-priority-dialog.component.html',
  styleUrl: './joboffer-priority-dialog.component.css'
})
export class JobofferPriorityDialogComponent {
  public readonly Constant = Constant;
  public form: FormGroup;
  public showSpinner = true;
  public isSaving = false;
  public btnEnable = false;
  public joboffers;
  public orderNum = Array(3);  // 表示順選択リスト用

  constructor(
    private dialogRef: MatDialogRef<JobofferPriorityDialogComponent>,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) {

  }

  ngOnInit() {
    this.getJoboffer();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.checkOnline()) {
      this.isSaving = false;
      return;
    }

    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.postPriority();
  }

  onSortChange(val, name) {
    // 同順位があればリセット
    if (val > 0) {
      for (const key in this.form.value) {
        if (this.form.value[key] === val && key !== name) {
          this.form.controls[key].setValue(0);
        }
      }
    }
  }

  /* API */
  private getJoboffer() {
    Auth.currentSession().then(session => {
      const apiPath = '/joboffer/priority';
      const options = this.auth.createApiHeader(session);

      this.commonUtil.apiGet(apiPath, options).then(res => {
        this.joboffers = res.data;
        this.createFormData();
        this.showSpinner = false;
        this.btnEnable = true;
      })
      .catch(err => {
        this.showSpinner = false;
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  private postPriority() {
    const sortAry = [];
    for (const key in this.form.value) {
      const id = Number(key.split('-')[1]);
      sortAry.push({id: id, sort_no: this.form.value[key]});
    }

    Auth.currentSession().then(session => {
      const apiPath = '/joboffer/priority';
      const options = this.auth.createApiHeader(session);
      options['body'] = {sort_ary: sortAry};

      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
        // ダイアログ閉じる
        this.dialogRef.close(true);
      })
      .catch(err => {
        // 保存失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
        this.isSaving = false;
      });
    });
  }

  private createFormData() {
    const controles = {};
    this.joboffers.forEach(element => {
      controles['job-' + element.id] = new FormControl(element.sort_no);
    });

    this.form = new FormGroup(controles);
  }
}
