import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { environment } from './../../../environments/environment';
import { ImageUtil } from './../../util/image-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { MultipleSelectDialogComponent } from '../multiple-select-dialog/multiple-select-dialog.component';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-company-profile-dialog',
  templateUrl: './company-profile-dialog.component.html',
  styleUrl: './company-profile-dialog.component.css'
})
export class CompanyProfileDialogComponent implements OnInit {
  public form: FormGroup;
  public companyInfo;
  public companyMasters = {};
  public readonly Constant = Constant;
  public isSaving = false;
  public showSpinner = true;
  public employmentAreaText = '';
  public industryText = '';
  public employmentAreaAry = [];
  public industryAry = [];
  public otherLinkError = false;
  private apiPath = '/company';
  public companyLogoPath = null;
  public companyPrImagePath = null;
  private companyLogoData = null;
  private uploadedCompanyLogoPath = null;
  private companyPrImageData = null;
  private uploadedCompanyPrImagePath = null;
  private uploadFileCnt = 0;

  public title = 'Profile（企業概要）'
  public maxLenAddress = 100;
  public maxLenLink = 255;
  public maxLenSummary = 120;
  public maxLen20 = 20;

  @ViewChild('prImgFileInput')
  private prImgFileInput: ElementRef;
  @ViewChild('logoFileInput')
  private logoFileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<CompanyProfileDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getCompanyData();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();

    let maxWidth, maxHeight;
    let fileCnt = 0;
    this.uploadFileCnt = 0
    if (this.companyLogoData) {
      fileCnt++;
    }
    if (this.companyPrImageData) {
      fileCnt++;
    }

    if (this.companyLogoData) {
      // ロゴ画像が変更されていた場合はS3に保存する
      const config = environment.amplify.Storage.companyLogo;
      maxWidth = 300;
      maxHeight = 300;
      this.uploadImageData(this.companyLogoData, Constant.companyImageCompanyLogo, config, fileCnt, maxWidth, maxHeight);
    }
    if (this.companyPrImageData) {
      // PR画像が変更されていた場合はS3に保存する
      const config = environment.amplify.Storage.companyPrImage;
      maxWidth = Constant.uploadImageFullWidth;
      maxHeight = Constant.uploadImageFullHeight;
      this.uploadImageData(this.companyPrImageData, Constant.companyImagePrImage, config, fileCnt, maxWidth, maxHeight);
    }

    // 画像に変更がない場合はデータを更新
    if (!this.companyLogoData && !this.companyPrImageData) {
      this.dataUpdate();
    }
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.companyMsgConfirmEditCancel, this.title), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // 複数選択用のモーダルを表示する
  onMultipleSelect(type) {
    let currentData = '';
    const isMultiRow = true;
    let title = '';
    let fontSize = Constant.elementValGeneralFontSize;
    let rowWidth = Constant.elementVal4RowWidth;
    if (type === Constant.companyInfoIndustryType) {
      currentData = this.companyInfo.industry;
      title = Constant.companyTextSelectIndustry;
      fontSize = Constant.elementValSmallFontSize;
      rowWidth = Constant.elementValTwinRowWidth;
    } else if (type === Constant.companyInfoEmploymentAreaType) {
      currentData = this.companyInfo.employment_area;
      title = Constant.companyTextSelectEmploymentArea;
    }

    const dialogRef = this.dialog.open(MultipleSelectDialogComponent, {
      width: Constant.multiSelectDialogWidth,
      autoFocus: false,
      data: {
        masterData: this.companyMasters[type],
        currentData: currentData,
        isMultiRow: isMultiRow,
        fontSize: fontSize,
        rowWidth: rowWidth,
        title: title
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // チェックされたidをテキストに変換し、連結する
        this.convertMasterIdToText(result, type);

        // APIの形式に合う様にカンマ区切りの文字列に変換する
        const strResult = this.commonUtil.concatenateTexts(result, Constant.hsCommma);
        if (type === Constant.companyInfoIndustryType) {
          this.companyInfo.industry = strResult;
        } else if (type === Constant.companyInfoEmploymentAreaType) {
          this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompanyAreaOK);
          this.companyInfo.employment_area = strResult;
        }
      }
    });
  }

  // 画像選択
  onChangeImage(evt, type) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function() {
      if (type === Constant.companyImageCompanyLogo) {
        this.companyLogoPath = fileReader.result;
        this.companyLogoData = file;
      } else if (type === Constant.companyImagePrImage) {
        this.companyPrImagePath = fileReader.result;
        this.companyPrImageData = file;
      }
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 画像削除
  onImageDelete(type) {
    if (type === Constant.companyImageCompanyLogo) {
      this.companyLogoPath = '';
      this.companyLogoData = null;
      this.logoFileInput.nativeElement.value = '';
    } else if (type === Constant.companyImagePrImage) {
      this.companyPrImagePath = '';
      this.companyPrImageData = null;
      this.prImgFileInput.nativeElement.value = '';
    }
  }

  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  // 改行ペースト対応
  onInput() {
    // 入力内容を取得
    const beforeText = this.form.value.summary;
    // 入力内容の改行を空文字に置き換える
    const changeText = beforeText.replace(/\r?\n/g, '');
    // 元の内容と置き換えた内容が等しくなければ、新しい内容に置き換える
    if (changeText !== beforeText) {
      this.form.patchValue({summary: changeText});
    }
  }

  // 保存ボタンdisabled
  checkSaveBtn() {
    if (this.showSpinner || !this.form || this.form.invalid || this.isSaving || this.industryAry.length == 0 ||
      this.employmentAreaAry.length == 0 || !this.companyLogoPath || !this.companyPrImagePath) {
      return true
    }

    // リンクその他の入力チェック
    for (let i = 1; i<=3; i++) {
      if (this.form.value['link_name' + i] || this.form.value['link_other' + i]) {
        if (!this.form.value['link_name' + i] || !this.form.value['link_other' + i]) {
          this.otherLinkError = true
          return true
        }
      }
    }

    this.otherLinkError = false
    return false
  }

  // 画像をS3にアップロードする
  private uploadImageData(imageData, imageType, config, fileCnt, maxWidth, maxHeight) {
    // 画像をアップロード
    this.imageUtil
      .uploadImageDataToS3(imageData, maxHeight, maxWidth, config)
      .then(image_path => {
        if (imageType === Constant.companyImageCompanyLogo) {
          // 企業ロゴの場合
          this.uploadedCompanyLogoPath = image_path;
          this.uploadFileCnt++;
        } else if (imageType === Constant.companyImagePrImage) {
          // PR画像の場合
          this.uploadedCompanyPrImagePath = image_path;
          this.uploadFileCnt++;
        }
        if (fileCnt === this.uploadFileCnt) {
          // 全てのファイルがアップロードできたらデータをpostする
          this.dataUpdate();
        }
      })
      .catch(err => {
        this.toastUtil.showErrorToast(
          Constant.empty,
          Constant.msgSaveFailedRetry,
          Constant.toastShowMiliSec
        );
        this.isSaving = false;
      });
  }


  // 企業情報を取得する
  private getCompanyData() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          console.log(res.data)
          if (res.data) {
            this.companyInfo = res.data.main;
            this.getCompanyMaster();
            this.getImageData();
          }
          this.commonUtil.debug().log(this.companyInfo);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }
  // 企業情報関連のマスター情報を取得する
  private getCompanyMaster() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const companyMasterApiPath = this.apiPath + '/master';

      this.commonUtil
        .apiGet(companyMasterApiPath, options)
        .then(res => {
          if (res.data) {
            res.data.forEach(rowdata => {
              this.companyMasters[rowdata.type] = rowdata.data;
            });
            // 業種のテキストを取得する
            const industries = this.companyInfo.industry.split(',');
            this.convertMasterIdToText(industries, Constant.companyInfoIndustryType);
            // 勤務地のテキストを取得する
            const employmentAreas = this.companyInfo.employment_area.split(',');
            this.convertMasterIdToText(employmentAreas, Constant.companyInfoEmploymentAreaType);
            this.initForm();
            this.showSpinner = false;
          }
          this.commonUtil.debug().log(this.companyMasters);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    });
  }

  // 画像データを取得する
  private getImageData() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const companyimageApiPath = this.apiPath + '/url';

      this.commonUtil
        .apiGet(companyimageApiPath, options)
        .then(res => {
          if (res.data.logo_path !== '') {
            this.companyLogoPath = res.data.logo_path;
          } else {
            this.companyLogoPath = ''
          }
          if (res.data.pr_image_path !== '') {
            this.companyPrImagePath = res.data.pr_image_path;
          } else {
            this.companyPrImagePath = ''
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    });
  }

  private initForm() {
    this.form = new FormGroup({
      logo_path: new FormControl(this.companyInfo.logo_path),
      pr_image_path: new FormControl(this.companyInfo.pr_image_path),
      establishment: new FormControl(this.companyInfo.establishment, [Validators.maxLength(this.maxLen20)]),
      representative_name: new FormControl(this.companyInfo.representative_name, [Validators.maxLength(this.maxLen20)]),
      capital: new FormControl(this.companyInfo.capital, [Validators.maxLength(this.maxLen20)]),
      employees: new FormControl(this.companyInfo.employees, [Validators.maxLength(this.maxLen20)]),
      address: new FormControl(this.companyInfo.address, [Validators.maxLength(this.maxLenAddress)]),
      homepage: new FormControl(this.companyInfo.homepage, [Validators.maxLength(this.maxLenLink)]),
      link_x: new FormControl(this.companyInfo.link_x, [Validators.maxLength(this.maxLenLink)]),
      link_fb: new FormControl(this.companyInfo.link_fb, [Validators.maxLength(this.maxLenLink)]),
      link_ig: new FormControl(this.companyInfo.link_ig, [Validators.maxLength(this.maxLenLink)]),
      link_tt: new FormControl(this.companyInfo.link_tt, [Validators.maxLength(this.maxLenLink)]),
      link_linkedin: new FormControl(this.companyInfo.link_linkedin, [Validators.maxLength(this.maxLenLink)]),
      link_note: new FormControl(this.companyInfo.link_note, [Validators.maxLength(this.maxLenLink)]),
      link_name1: new FormControl(this.companyInfo.link_name1, [Validators.maxLength(this.maxLen20)]),
      link_name2: new FormControl(this.companyInfo.link_name2, [Validators.maxLength(this.maxLen20)]),
      link_name3: new FormControl(this.companyInfo.link_name3, [Validators.maxLength(this.maxLen20)]),
      link_other1: new FormControl(this.companyInfo.link_other1, [Validators.maxLength(this.maxLenLink)]),
      link_other2: new FormControl(this.companyInfo.link_other2, [Validators.maxLength(this.maxLenLink)]),
      link_other3: new FormControl(this.companyInfo.link_other3, [Validators.maxLength(this.maxLenLink)]),
      summary: new FormControl(this.companyInfo.summary, [Validators.maxLength(this.maxLenSummary)]),
    });
  }

  // 更新実行
  private dataUpdate() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    Auth.currentSession().then(session => {
      const logoPath = this.getImagePathParam(Constant.companyImageCompanyLogo);
      const prImagePath = this.getImagePathParam(Constant.companyImagePrImage);

      const options = this.auth.createApiHeader(session);
      options['body'] = {
        type: Constant.companyUpdateTypeProfile,
        logo_path: logoPath,
        pr_image_path: prImagePath,
        // main_copy: this.commonUtil.replaceSpace(this.form.value.maincopy),
        employment_area: this.companyInfo.employment_area,
        industry: this.companyInfo.industry,
        establishment: this.commonUtil.replaceSpace(this.form.value.establishment),
        representative_name: this.commonUtil.replaceSpace(this.form.value.representative_name),
        capital: this.commonUtil.replaceSpace(this.form.value.capital),
        employees: this.commonUtil.replaceSpace(this.form.value.employees),
        address: this.commonUtil.replaceSpace(this.form.value.address),
        homepage: this.commonUtil.replaceSpace(this.form.value.homepage),
        link_x: this.commonUtil.replaceSpace(this.form.value.link_x),
        link_fb: this.commonUtil.replaceSpace(this.form.value.link_fb),
        link_ig: this.commonUtil.replaceSpace(this.form.value.link_ig),
        link_tt: this.commonUtil.replaceSpace(this.form.value.link_tt),
        link_linkedin: this.commonUtil.replaceSpace(this.form.value.link_linkedin),
        link_note: this.commonUtil.replaceSpace(this.form.value.link_note),
        link_other1: this.commonUtil.replaceSpace(this.form.value.link_other1),
        link_other2: this.commonUtil.replaceSpace(this.form.value.link_other2),
        link_other3: this.commonUtil.replaceSpace(this.form.value.link_other3),
        link_name1: this.commonUtil.replaceSpace(this.form.value.link_name1),
        link_name2: this.commonUtil.replaceSpace(this.form.value.link_name2),
        link_name3: this.commonUtil.replaceSpace(this.form.value.link_name3),
        summary: this.commonUtil.replaceSpace(this.form.value.summary),
      };
      this.commonUtil
        .apiPut(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
            this.dialogRef.close(res.status);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
          this.isSaving = false;
        });
    });
  }

  // マスタのidからテキストに変換する
  private convertMasterIdToText(targetIds, type) {
    // idからテキストに変換する
    const targetTexts = this.commonUtil.convertMasterIdToItemValue(targetIds, this.companyMasters, type);
    // 取得したテキストを連結する
    const concatedText = this.commonUtil.concatenateTexts(targetTexts, Constant.commma);
    if (type === Constant.companyInfoIndustryType) {
      this.industryAry = targetTexts
      this.industryText = concatedText;
    } else if (type === Constant.companyInfoEmploymentAreaType) {
      this.employmentAreaText = concatedText;
      this.employmentAreaAry = targetTexts;
    }
  }

  private getImagePathParam(type) {
    if (type === Constant.companyImageCompanyLogo) {
      if (this.uploadedCompanyLogoPath) {
        // 画像が更新されていた場合
        return this.uploadedCompanyLogoPath;
      }
      if (this.companyLogoPath === '') {
        // 画像が削除されていた場合
        return this.companyLogoPath;
      } else {
        // 変更なし
        return this.companyInfo.logo_path;
      }
    } else if (type === Constant.companyImagePrImage) {
      if (this.uploadedCompanyPrImagePath) {
        // 画像が更新されていた場合
        return this.uploadedCompanyPrImagePath;
      }
      if (this.companyPrImagePath === '') {
        // 画像が削除されていた場合
        return this.companyPrImagePath;
      } else {
        // 変更なし
        return this.companyInfo.pr_image_path;
      }
    }
    return null;
  }

}
