import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastUtil } from '../../util/toast-util';
import { CommonUtil } from '../../util/common-util';
import { Constant } from './../../constant';

@Component({
  selector: 'app-message-select-talent-dialog',
  templateUrl: './message-select-talent-dialog.component.html',
  styleUrls: ['./message-select-talent-dialog.component.css']
})
export class MessageSelectTalentDialogComponent implements OnInit {
  public readonly Constant = Constant;

  constructor(
    private dialogRef: MatDialogRef<MessageSelectTalentDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  onSelect(type) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.dialogRef.close(type);
  }
}
