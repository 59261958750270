<h2 mat-subheader>タレントアクション サマリー</h2>
<div *ngIf="showSpinnerPv" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="update" *ngIf="updateFlg && (updateFlg.mail_unread==1 || updateFlg.noaction==1)">
  <a (click)="onUnreadMail()" *ngIf="updateFlg.mail_unread==1">未読の受信メッセージがあります。</a><br *ngIf="updateFlg.mail_unread==1">
  <a (click)="onNoAction()" *ngIf="updateFlg.noaction==1">未対応の応募があります。</a>
</div>
<table class="pageview" *ngIf="pvData">
  <tr>
    <td class="label"></td>
    <td class="label">今月</td>
    <td class="label">先月</td>
    <td class="label">先々月</td>
    <td class="label">累計</td>
  </tr>
  <tr>
    <td class="title">タレントプール登録中の人数</td>
    <td class="data">{{ pvData[7].cnt1 }}</td>
    <td class="data">{{ pvData[7].cnt2 + pvData[7].cnt2_del }}</td>
    <td class="data">{{ pvData[7].cnt3 + pvData[7].cnt3_del }}</td>
    <td class="data">-</td>
  </tr>
  <tr>
    <td class="title">タレント 増加数</td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeThisMonth)">{{ pvData[1].cnt1 }}</a></td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeLastMonth)">{{ pvData[1].cnt2 }}</a></td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeTwoMonthAgo)">{{ pvData[1].cnt3 }}</a></td>
    <td class="data"><a (click)="toListRegistered(0)">{{ pvData[1].cnt_all }}</a></td>
  </tr>
  <tr>
    <td class="title">− 自己登録のタレント</td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeThisMonth, 0)">{{ pvData[8].cnt1 }}</a></td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeLastMonth, 0)">{{ pvData[8].cnt2 }}</a></td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeTwoMonthAgo, 0)">{{ pvData[8].cnt3 }}</a></td>
    <td class="data"><a (click)="toListRegistered(0, 0)">{{ pvData[8].cnt_all }}</a></td>
  </tr>
  <tr>
    <td class="title">− 手動/CSV取込のタレント</td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeThisMonth, 1)">{{ pvData[9].cnt1 }}</a></td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeLastMonth, 1)">{{ pvData[9].cnt2 }}</a></td>
    <td class="data"><a (click)="toListRegistered(Constant.tlPeriodTypeTwoMonthAgo, 1)">{{ pvData[9].cnt3 }}</a></td>
    <td class="data"><a (click)="toListRegistered(0, 1)">{{ pvData[9].cnt_all }}</a></td>
  </tr>
  <tr>
    <td class="title">エントリーページ 閲覧数</td>
    <td class="data">{{ pvData[0].cnt1 + pvData[0].cnt1_default }}</td>
    <td class="data">{{ pvData[0].cnt2 + pvData[0].cnt2_default }}</td>
    <td class="data">{{ pvData[0].cnt3 + pvData[0].cnt3_default }}</td>
    <td class="data">{{ pvData[0].cnt_all + pvData[0].cnt_all_default }}</td>
  </tr>
  <tr>
    <td class="title">採用PRコンテンツ 閲覧数</td>
    <td class="data">{{ pvData[2].cnt1 }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionMore, Constant.tlPeriodTypeThisMonth)">{{ pvData[2].cnt1_talent }}</a>）
    </td>
    <td class="data">{{ pvData[2].cnt2 }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionMore, Constant.tlPeriodTypeLastMonth)">{{ pvData[2].cnt2_talent }}</a>）
    </td>
    <td class="data">{{ pvData[2].cnt3 }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionMore, Constant.tlPeriodTypeTwoMonthAgo)">{{ pvData[2].cnt3_talent }}</a>）
    </td>
    <td class="data">{{ pvData[2].cnt_all }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionMore, 0)">{{ pvData[2].cnt_all_talent }}</a>）
    </td>
  </tr>
  <tr>
    <td class="title">採用PRコンテンツ いいね数</td>
    <td class="data">{{ pvData[3].cnt1 }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionGood, Constant.tlPeriodTypeThisMonth)">{{ pvData[3].cnt1_talent }}</a>）
    </td>
    <td class="data">{{ pvData[3].cnt2 }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionGood, Constant.tlPeriodTypeLastMonth)">{{ pvData[3].cnt2_talent }}</a>）
    </td>
    <td class="data">{{ pvData[3].cnt3 }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionGood, Constant.tlPeriodTypeTwoMonthAgo)">{{ pvData[3].cnt3_talent }}</a>）
    </td>
    <td class="data">{{ pvData[3].cnt_all }}
      （<a (click)="toListView(Constant.pcActionType, Constant.pcActionGood, 0)">{{ pvData[3].cnt_all_talent }}</a>）
    </td>
  </tr>
  <tr>
    <td class="title">求人情報 閲覧数</td>
    <td class="data">{{ pvData[4].cnt1 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionView, Constant.tlPeriodTypeThisMonth)">{{ pvData[4].cnt1_talent }}</a>）
    </td>
    <td class="data">{{ pvData[4].cnt2 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionView, Constant.tlPeriodTypeLastMonth)">{{ pvData[4].cnt2_talent }}</a>）
    </td>
    <td class="data">{{ pvData[4].cnt3 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionView, Constant.tlPeriodTypeTwoMonthAgo)">{{ pvData[4].cnt3_talent }}</a>）
    </td>
    <td class="data">{{ pvData[4].cnt_all }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionView, 0)">{{ pvData[4].cnt_all_talent }}</a>）
    </td>
  </tr>
  <tr>
    <td class="title">求人情報 気になる数</td>
    <td class="data">{{ pvData[5].cnt1 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionMind, Constant.tlPeriodTypeThisMonth)">{{ pvData[5].cnt1_talent }}</a>）
    </td>
    <td class="data">{{ pvData[5].cnt2 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionMind, Constant.tlPeriodTypeLastMonth)">{{ pvData[5].cnt2_talent }}</a>）
    </td>
    <td class="data">{{ pvData[5].cnt3 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionMind, Constant.tlPeriodTypeTwoMonthAgo)">{{ pvData[5].cnt3_talent }}</a>）
    </td>
    <td class="data">{{ pvData[5].cnt_all }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionMind, 0)">{{ pvData[5].cnt_all_talent }}</a>）
    </td>
  </tr>
  <tr>
    <td class="title">求人情報 応募数</td>
    <td class="data">{{ pvData[6].cnt1 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionApply, Constant.tlPeriodTypeThisMonth)">{{ pvData[6].cnt1_talent }}</a>）
    </td>
    <td class="data">{{ pvData[6].cnt2 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionApply, Constant.tlPeriodTypeLastMonth)">{{ pvData[6].cnt2_talent }}</a>）
    </td>
    <td class="data">{{ pvData[6].cnt3 }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionApply, Constant.tlPeriodTypeTwoMonthAgo)">{{ pvData[6].cnt3_talent }}</a>）
    </td>
    <td class="data">{{ pvData[6].cnt_all }}
      （<a (click)="toListView(Constant.offerActionType, Constant.offerActionApply, 0)">{{ pvData[6].cnt_all_talent }}</a>）
    </td>
  </tr>

</table>
<div class="desc" *ngIf="pvData">
  （　）内は、タレントプール登録中のタレントによるアクション数です。
</div>

<mat-divider></mat-divider>
<h2 mat-subheader>タレントマップ</h2>
<div *ngIf="showSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="map-container" *ngIf="mapData">
  <div class="header">
    <div>
      <span class="talent-cnt-label">新卒採用が対象のタレント数</span>
    </div>
    <div class="header-cnt-container">
      <span class="font-normal">全</span>
      <span class="header-cnt-value">{{ sinsotsuCnt }}</span>
      <span class="font-normal">人</span>
    </div>
  </div>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile *ngFor="let graduate of graduateSinsotsu">
      <div class="map-wrapper graduate future" (click)="toListGraduate(graduate)">
        <div class="map-title-year font-bold">
          <div>{{ graduate.item_value }}</div>
        </div>
        <div class="map-count-tyuuto">
          <span class="font-normal">
            {{ graduate.cnt }}
          </span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="header">
    <div>
      <span class="talent-cnt-label">中途採用などが対象のタレント数</span>
    </div>
    <div class="header-cnt-container">
      <span class="font-normal">全</span>
      <span class="header-cnt-value">{{ chutoCnt }}</span>
      <span class="font-normal">人</span>
    </div>
  </div>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile *ngFor="let graduate of graduateChuto">
      <div class="map-wrapper graduate past" (click)="toListGraduate(graduate)">
        <div class="map-title-year font-bold">
          <div>{{ graduate.item_value }}</div>
        </div>
        <div class="map-count-tyuuto">
          <span class="font-normal">
            {{ graduate.cnt }}
          </span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="header">
    <div>
      <span class="talent-cnt-label">採用枠が不明のタレント数</span>
    </div>
    <div class="header-cnt-container">
      <span class="font-normal">全</span>
      <span class="header-cnt-value">{{ otherCnt }}</span>
      <span class="font-normal">人</span>
    </div>
  </div>
  <mat-grid-list cols="4" rowHeight="100px" class="bottom">
    <mat-grid-tile *ngFor="let graduate of graduateOther">
      <div class="map-wrapper graduate gray" (click)="toListGraduate(graduate)">
        <div class="map-title-year font-bold">
          <div>{{ graduate.item_value }}</div>
        </div>
        <div class="map-count-tyuuto">
          <span class="font-normal">
            {{ graduate.cnt }}
          </span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-divider></mat-divider>

  <div class="header">
    <div>
      <span class="talent-cnt-label">希望職種別 タレント数</span>
    </div>
    <div class="header-cnt-container">
      <span class="font-normal">全</span>
      <span class="header-cnt-value">{{ mapData.all_cnt }}</span>
      <span class="font-normal">人</span>
    </div>
  </div>
  <mat-grid-list cols="3" rowHeight="120px">
    <mat-grid-tile *ngFor="let detail of mapData.data">
      <!-- 該当するタレントがいる場合 -->
      <div *ngIf="detail.cnt > 0" class="map-wrapper map-wrapper-tyuuto" (click)="toList(detail)">
        <div class="map-title-tyuuto font-bold">
          <div>{{ detail.item_value.split('　')[0] }}</div>
          <div>{{ detail.item_value.split('　')[1] }}</div>
        </div>
        <div class="map-count-tyuuto">
          <span class="font-normal">
            {{ detail.cnt }}
          </span>
          <span class="font-normal">人</span>
        </div>
      </div>
      <!-- 該当するタレントがいない場合 -->
      <div *ngIf="detail.cnt == 0" class="map-wrapper-inactive map-wrapper-tyuuto">
        <div class="map-title-tyuuto font-bold">
          <div>{{ detail.item_value.split('　')[0] }}</div>
          <div>{{ detail.item_value.split('　')[1] }}</div>
        </div>
        <div class="map-count-tyuuto">
          <span class="font-normal"> {{ detail.cnt }}</span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-divider></mat-divider>

  <div class="header">
    <div>
      <span class="talent-cnt-label">性別 タレント数</span>
    </div>
    <div class="header-cnt-container">
      <span class="font-normal">全</span>
      <span class="header-cnt-value">{{ mapData.all_cnt }}</span>
      <span class="font-normal">人</span>
    </div>
  </div>
  <mat-grid-list cols="3" rowHeight="120px">
    <mat-grid-tile>
      <div class="map-wrapper-tyuuto map-wrapper-inactive" (click)="toListSex(Constant.tlSexIDMen, mapData.sex_data.men_cnt)"
      [class.map-wrapper]="mapData.sex_data.men_cnt>0"
      [class.map-wrapper-inactive]="mapData.sex_data.men_cnt==0">
        <div class="map-title-tyuuto font-bold">男性</div>
        <div class="map-count-tyuuto">
          <span class="font-normal">{{ mapData.sex_data.men_cnt }}</span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="map-wrapper-tyuuto map-wrapper-inactive" (click)="toListSex(Constant.tlSexIDWomen, mapData.sex_data.women_cnt)"
      [class.map-wrapper]="mapData.sex_data.women_cnt>0"
      [class.map-wrapper-inactive]="mapData.sex_data.women_cnt==0">
        <div class="map-title-tyuuto font-bold">女性</div>
        <div class="map-count-tyuuto">
          <span class="font-normal">{{ mapData.sex_data.women_cnt }}</span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="map-wrapper-tyuuto map-wrapper-inactive" (click)="toListSex(0, mapData.sex_data.other_cnt)"
      [class.map-wrapper]="mapData.sex_data.other_cnt>0"
      [class.map-wrapper-inactive]="mapData.sex_data.other_cnt==0">
        <div class="map-title-tyuuto font-bold">未登録</div>
        <div class="map-count-tyuuto">
          <span class="font-normal">{{ mapData.sex_data.other_cnt }}</span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-divider></mat-divider>

  <div class="header">
    <div>
      <span class="talent-cnt-label">年齢別 タレント数</span>
    </div>
    <div class="header-cnt-container">
      <span class="font-normal">全</span>
      <span class="header-cnt-value">{{ mapData.all_cnt }}</span>
      <span class="font-normal">人</span>
    </div>
  </div>

  <mat-grid-list cols="4" rowHeight="120px" *ngIf="mapData.age_data_ary">
    <mat-grid-tile *ngFor="let detail of mapData.age_data_ary">
      <div class="map-wrapper-tyuuto map-wrapper-inactive" (click)="toListAge(detail)"
      [class.map-wrapper]="detail.cnt>0"
      [class.map-wrapper-inactive]="detail.cnt==0">
        <div class="map-title-tyuuto font-bold">
          <div>{{ detail.name }}</div>
        </div>
        <div class="map-count-tyuuto">
          <span class="font-normal">
            {{ detail.cnt }}
          </span>
          <span class="font-normal">人</span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>


</div>
