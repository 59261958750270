<h2 color="primary">ログイン</h2>
<mat-divider></mat-divider>
<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div class="login-container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin(loginForm.value)" autocomplete="on">
    <div class="category-container">
      <div>
        <label>メールアドレス</label>
      </div>
      <div>
        <input id="email" class="text-input" type="email" formControlName="email" />
      </div>
      <mat-error class="error-text" *ngIf="email.invalid && email.touched">
        メールアドレスは正しい形式で入力してください。
      </mat-error>
    </div>

    <div class="category-container">
      <div>
        <label>パスワード</label>
      </div>
      <div>
        <input class="text-input" type="password" formControlName="password" />
      </div>
      <mat-error class="error-text" *ngIf="password.invalid && password.touched">
        パスワードを入力してください。
      </mat-error>
    </div>
    <div class="footer-container">
      <button class="login-button" type="submit">ログイン</button>
    </div>

    <div class="attention">
      アカウントにログインすることにより、 CaLinの
      <a (click)="onAgreement()">利用規約</a>
      <br />および <a (click)="onPrivacy()">プライバシーポリシー</a>に同意するものとします。
    </div>
    <div class="attention">
      <b>推奨環境</b>
      Chrome, EdgeのPC全画面表示でご利用ください。
    </div>
  </form>
</div>
