import { Component, OnInit } from '@angular/core';
import { Constant } from './../constant';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  providers: [Title]
})
export class PageNotFoundComponent implements OnInit {
  public readonly Constant = Constant;
  constructor(private title: Title) {
    this.title.setTitle(Constant.pageTitleError + Constant.pageTitleCommon);
  }

  ngOnInit() {}
}
