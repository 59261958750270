<mat-dialog-content>
  <div class="message" *ngIf="!data.isUse">
    URLの利用を停止しますか?<br /><br />停止中のURLへタレントが流入した場合、デフォルトURLにリダイレクトされます。(停止中のURLタイトルが接点・経路タグとしてタレントに付与されることはありません)
  </div>
  <div class="message" *ngIf="data.isUse">
    URLを利用しますか?<br /><br />アンケートに「はい」とタレントが回答した場合、当該タレントにURLタイトルが接点・経路タグとして付与されるようになります。
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button mat-dialog-close="true" color="primary" class="yes-btn" *ngIf="!data.isUse">
    停止
  </button>
  <button mat-raised-button mat-dialog-close="true" color="primary" class="yes-btn" *ngIf="data.isUse">
    利用
  </button>
</mat-dialog-actions>
