import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { ToastUtil } from '../../util/toast-util';
import { CommonUtil } from '../../util/common-util';
import { GeneralYesNoDialogComponent } from './../general-yes-no-dialog/general-yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { QuillUtil } from './../../util/quill-util';

@Component({
  selector: 'app-prcontent-detail-dialog',
  templateUrl: './prcontent-detail-dialog.component.html',
  styleUrls: ['./prcontent-detail-dialog.component.css']
})
export class PrcontentDetailDialogComponent implements OnInit {
  public index;
  public prContent;
  public description;
  public readonly Constant = Constant;
  public viewMode = false;
  public showSpinner = true;
  public loadFlg = false;
  private apiPath = '/prcontent';
  private loadCheckCnt = 0;

  constructor(
    public dialogRef: MatDialogRef<PrcontentDetailDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private auth: AuthService,
    private quillUtil: QuillUtil,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.index = data.index;
    this.prContent = data.prContent;
    if (data.viewMode) {
      this.viewMode = data.viewMode;
    }
  }

  ngOnInit() {
    this.getDetail();
  }

  onClose() {
    this.dialogRef.close();
  }

  // 編集画面に切り替え
  onEdit() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const param = {
      isEdit: true,
      index: this.index
    };
    this.dialogRef.close(param);
  }

  // ステータス変更
  onRelease(isRelease) {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    const param = {
      isEdit: false,
      index: this.index,
      isRelease: isRelease
    };
    let message = {};
    if (isRelease) {
      message = Constant.pcMsgComfirmRelease;
    } else {
      message = Constant.pcMsgComfirmStop;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: message }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close(param);
      }
    });
  }

  /* private */
  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/detail/' + this.data.id;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        if (!res.data) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteContentAlreadyError, Constant.toastShowMiliSec);
          const param = {
            reload: true
          };
          this.dialogRef.close(param);
          return;
        }
        this.prContent = res.data;
        this.showSpinner = false;
        setTimeout(() => {
          this.description = res.data.description.replaceAll('\n', '<br>')
        }, 200);

      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  /* エディタ関連 */
  onEditorCreated(quill) {
    // 画像とOGPの差し替え
    this.quillUtil.checkReloadQuill(quill, false);

    // 読み込みチェック
    const loadElementsAll = document.querySelectorAll<HTMLElement>(
      '.ql-container img'
    );
    if (loadElementsAll.length > 0) {
      setTimeout(() => {
        this.checkLoadCnt(loadElementsAll.length)

      }, 500);
    } else {
      this.loadFlg = true
    }
  }

  // 画像読み込みチェック
  private checkLoadCnt(cnt) {
    if (this.loadCheckCnt > 30) {
      // 30回繰り返したら終了させる
      this.loadFlg = true;
      return;
    }

    if (this.quillUtil.loadCnt >= cnt) {
      this.loadFlg = true;
    } else {
      this.loadCheckCnt++
      setTimeout(() => {
        this.checkLoadCnt(cnt)
      }, 500);
    }
  }
}
