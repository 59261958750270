<div class="flex">
  <div class="side">
    <div class="button-container">
      <button mat-raised-button (click)="onClose()">閉じる</button>
    </div>
  </div>
  <div class="right">
    <div class="title-area">
      <h2 mat-subheader class="title">プロフィール追加収集　質問管理</h2>
      <div class="btn-new">
        <button mat-raised-button color="warn" (click)="onEdit()" [disabled]="showSpinner || !questions || questions.length>=questionsMaxLen">
          新規作成
        </button>
      </div>
    </div>
    <div class="container">
      <div class="desc">
        つながったタレントに企業独自の質問を設定し、プロフィール情報を追加で収集できます。<br>
        質問の回答を収集するには、告知用URLページから接点・経路ごとに質問を紐付けてください。
      </div>
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <div class="count" *ngIf="!showSpinner && questions">{{ questions.length }} / {{ questionsMaxLen }}</div>
      <table *ngIf="!showSpinner && questions?.length>=0" class="table">
        <tr>
          <th class="title">管理用タイトル</th>
          <th class="question">質問</th>
          <th class="type">回答形式</th>
          <th class="menu">Menu</th>
        </tr>
        <ng-container *ngFor="let question of questions; index as idx">
          <tr>
            <td class="title"><a (click)="onDetail(idx)">{{ question.title }}</a></td>
            <td class="question">{{ question.question }}</td>
            <td class="type">{{ question.type_name }}</td>
            <td class="menu">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <button mat-menu-item (click)="onDetail(idx)">詳細</button>
                <button mat-menu-item (click)="onEdit(idx)">編集</button>
                <button mat-menu-item (click)="onCopy(idx)">コピー</button>
                <button mat-menu-item (click)="onDel(question.id)">削除</button>
              </mat-menu>
            </td>
          </tr>
        </ng-container>
      </table>
      <div class="data-none" *ngIf="!showSpinner && questions?.length==0">質問がありません。</div>
    </div>
  </div>
</div>
