<section id="worries">
  <div class="title-area midashi-font">
    中途採用のご担当者さま<br>
    その採用ページ もったいない！？
  </div>
  <div class="item-area">
    <div class="item">
      <div class="case">Case 1</div>
      <div class="title">
        応募しかできない<br>採用ページ
      </div>
      <div class="description">
        採用ページには、今は応募できないが会社に興味ある<span class="color-orange">【潜在層】</span>も多く訪れます。彼らを逃すのは機会損失です。
      </div>
    </div>
    <div class="item">
      <div class="case">Case 2</div>
      <div class="title">
        応募が面倒な<br>採用ページ
      </div>
      <div class="description">
        直接応募の受付が、メール・電話・郵送といった方法では、応募率が下がってしまう傾向にあります。
      </div>
    </div>
    <div class="item">
      <div class="case">Case 3</div>
      <div class="title">
        そもそも<br>採用ページが無い
      </div>
      <div class="description">
        採用ページが無いと求職者を不安にさせ、外部の求人サイトからの応募やスカウト反応率にも影響します。
      </div>
    </div>
  </div>
</section>
<div class="arrow-down">
  <picture>
    <img lazyLoad="/assets/lp/arrow_blue.png" />
  </picture>
</div>
<div class="title-area midashi-font center">
  それらの "もったいない" <br>CaLinで解決できるかもしれません。
</div>
<section id="merit1" class="center">
  <div class="title-area midashi-font">
    候補者との接点を<br class="show-sp"><span class="color-orange">「つながる」</span>でつくる
  </div>
  <div class="desc1">
    CaLinは、今すぐの「応募者」だけではなく、会社に興味を持った候補者と気軽に「つながる」ことができるサービスです。<br>
    つながっている候補者の情報は、自社の<span class="color-orange">タレントプール</span>（人材データベース）に蓄積されていきます。<br>
    また、応募の受付や管理もCaLinのシステム内で簡単に行えます。<br>
  </div>
  <ul class="check-list bold">
    <li>いつか応募したい【潜在層】をタレントプールできます。</li>
    <li>つながっている候補者はボタン一つで「応募」できます。</li>
  </ul>
  <div class="row">
    <div class="contents">
      <div class="prefix">これまで</div>
      <div class="title">
        <span class="color-navy">顕在層</span> だけ
      </div>
      <div class="description-before">応募する方しか
        <br> 受け付けません
      </div>
    </div>
    <div class="arrow">
      <img class="show-pc" src="../../assets/lp/arrow_blue_right.png" width="27" height="54">
      <img class="show-sp" src="../../assets/lp/arrow_blue.png" width="54" height="27">
    </div>
    <div class="contents">
      <div class="talentpool">
        <span class="color-orange">＼タレントプール／</span>
      </div>
      <div class="prefix">これから</div>
      <div class="title">
        <span class="color-navy">潜在層</span> も
      </div>
      <div class="description-after">今すぐ応募しなくても<br>
        会社に興味をもって<br>
        くれた方を歓迎します
      </div>
    </div>
  </div>
  <div class="bold desc2">
    自社に興味ある人材とつながり続けて、<br><span class="color-orange">独自の人材スカウトチャネル</span>にする<br>というのが、タレントプールのイメージです。
  </div>
</section>
<section id="merit2">
  <div class="title-area midashi-font">
    エントリーページの作成は簡単で、魅力の伝え方も豊富
  </div>
  <div class="desc">
    CaLinに企業情報などを登録するだけで、候補者とつながるためのエントリーページを簡単に作成できます。<br>
    会社の魅力を伝えるための方法も豊富で、<span class="color-orange">採用ブランディング</span>にも効果的です。
  </div>
  <ul class="check-list bold">
    <li>エントリーページは採用ページとして利用できます。<br><span class="small">※ 採用ページが無い場合にオススメです。</span></li>
  </ul>
  <div class="flex">
    <div class="left">
      <div class="col">
        <div class="image">
          <picture>
            <img lazyLoad="../../assets/lp/kigyo04.png" width="180" />
          </picture>
        </div>
        <div class="text">
          <b>会社説明会の<br>スライドを掲載</b>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <picture>
            <img lazyLoad="../../assets/lp/kigyo05.png" width="170" />
          </picture>
        </div>
        <div class="text">
          <b>採用PRコンテンツ<br class="show-sp">を掲載</b><br><span class="small">（テキスト・画像）</span>
        </div>
      </div>
      <div class="col">
        <div class="image height">
          <picture>
            <img lazyLoad="../../assets/lp/kigyo06.png" height="110" />
          </picture>
        </div>
        <div class="text">
          <b>求人情報を掲載</b><br><span class="small">（業務委託/パート募集可）</span>
        </div>
      </div>

    </div>
    <div class="right">
      <picture>
        <source srcset="../../assets/lp/entrypage.webp" type="image/webp">
        <img lazyLoad="/assets/lp/entrypage.png" width="300">
      </picture>
    </div>
  </div>
</section>
<section id="merit3" class="center">
  <div class="title-area midashi-font">
    タレントプールの運用も手間いらず
  </div>
  <div class="desc">
    一般的にタレントプールの運用で大変なのが、<span class="color-orange">候補者情報のメンテナンス</span>です。<br>
    最新情報を取得したり、連絡拒否の方を削除したり・・・。<br><br>
    CaLinは、そんなタレントプールの運用をラクにします。
  </div>
  <ul class="check-list bold">
    <li>候補者データの削除や連絡停止などの手間がありません<br>
      <span class="small">※ 候補者のプロフィールは候補者自身で更新します</span>
      <span class="small">※ 企業とのつながりは候補者が自由に解消できます</span>
    </li>
    <li>転職予定時期や最終アクセスなどアクティブ状況がわかります</li>
    <li>詳細検索やフラグ管理など便利な機能も豊富です</li>
  </ul>
  <div class="image">
    <picture>
      <source srcset="../../assets/lp/karte.webp" type="image/webp">
      <img lazyLoad="/assets/lp/karte.png" width="600" />
    </picture>
  </div>
</section>
<section id="merit4" class="center">
  <div class="title-area midashi-font">
    自由に候補者へアプローチ、<br class="show-sp">反応も良好
  </div>
  <div class="flex">
    <div class="text">
      <p class="top0">つながっている候補者には、好きなタイミングで自由にメッセージ送信ができます。</p>
      <p>一方、つながっていない候補者へは、スカウトメッセージなど一切の送信ができません。</p>
      <p>候補者自らの意思で<span class="color-orange">つながっている企業からのメッセージだけが届く</span>ので、その反応率は高い傾向にあります。</p>
      <p>その他、どのような採用PRコンテンツに興味を持っているかといった趣向がわかったり、対象を細かく絞り込んでの一斉メッセージ送信など、アプローチの効果・効率を高める機能も豊富です。</p>
    </div>
    <div class="image">
      <picture>
        <img lazyLoad="../../assets/lp/kigyo09.png" width="250" />
      </picture>
    </div>
  </div>
</section>

<section id="merit5" class="center">
  <div class="title-area midashi-font">
    エントリーページのリンクを設置して<br class="show-pc">タレントプール採用をスタート！
  </div>
  <div class="desc">
    <p><span class="color-orange">タレントプールへの導線をつくる</span>ために、採用ページや企業ホームページにエントリーページへのリンク（つながるボタン）を設置してください。</p>
    <p>その後、応募者を獲得するための従来の採用活動を推進していけば、その活動の副産物としてタレントプールも大きくなっていきます。</p>
    <p>もし、リンクを設置する場所や方法などにお悩みでしたら、CaLinサポート担当よりいくつか案をご提示しますので、お気軽にご相談ください。</p>
  </div>
  <div class="image">
    <picture>
      <source srcset="../../assets/lp/link_img2.webp" type="image/webp">
      <img lazyLoad="/assets/lp/link_img2.png" width="800" />
    </picture>
  </div>


</section>
