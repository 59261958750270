<app-header></app-header>
<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="30"></mat-spinner>
</div>
<div *ngIf="trustUrl" class="back">
  <div class="button-container">
    <button mat-raised-button (click)="onDownload()" color="warn">ダウンロード</button>
    <button mat-raised-button (click)="onClose()" [disabled]="closeFlg">閉じる</button>
  </div>
  <div class="file-area">
    <pdf-viewer *ngIf="fileExt == Constant.fileExtensionPdf && !firstLoad" [src]="url" [fit-to-page]="true" [zoom-scale]="'page-fit'" [render-text]="false"
    [show-all]="true" [autoresize]="true" class="pdf-viewer"></pdf-viewer>
    <pdf-viewer *ngIf="fileExt == Constant.fileExtensionPdf && firstLoad" [src]="url" [fit-to-page]="true" [render-text]="false"
    [show-all]="true" [autoresize]="true" (after-load-complete)="pdfLoadComplete($event)" class="pdf-viewer-hidden"></pdf-viewer>
    <img *ngIf="fileExt != Constant.fileExtensionPdf" [src]="trustUrl">
  </div>
</div>
<div *ngIf="!loading && !trustUrl" class="error">
  <p>ファイルの取得に失敗しました。</p>
  <p>ファイル表示が正しく行われなかった、またはファイルが既に削除された可能性があります。</p>
  <p>ファイル取得元のページを更新して、再度実行してください。</p>
  <button mat-raised-button (click)="onClose()" [disabled]="closeFlg">閉じる</button>
</div>
