import { Injectable } from '@angular/core';
import * as loadImage from 'blueimp-load-image';
import { Constant } from './../constant';
import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';
import { ToastUtil } from './toast-util';
import { CommonUtil } from '../util/common-util';

@Injectable()
export class ImageUtil {
  private timeoutId;
  private timeoutIdImage;

  constructor(private toastUtil: ToastUtil, private commonUtil: CommonUtil) {}

  /**
   * S3に画像をアップロードする
   * @param {Object} imageFile   - アップロードする画像のファイルオブジェクト
   * @param {integer} maxHeight  - 画像の最大縦幅
   * @param {integer} maxWidth   - 画像の最大横幅
   * @return {Promise}
   */
  public uploadImageDataToS3(imageFile, maxHeight, maxWidth, config): Promise<any> {
    // ファイル形式チェック
    return new Promise((resolve, reject) => {
      if (imageFile.type !== Constant.imageFormatJpg && imageFile.type !== Constant.imageFormatPng) {
        this.toastUtil.showErrorToast('', Constant.msgErrorInvalidFile, Constant.toastShowMiliSecErr);
        return reject();
      }

      if (imageFile.size === 0) {
        this.toastUtil.showErrorToast('', Constant.msgErrorUploadFile, Constant.toastShowMiliSecErr);
        return reject();
      }

      loadImage.parseMetaData(imageFile, data => {
        const options = {
          orientation: null,
          maxHeight: maxHeight,
          maxWidth: maxWidth,
          canvas: true
        };
        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        this.getDataUrl(imageFile, options).then(dataUrl => {
          const uploadBlob = this.base64ToBlob(dataUrl, imageFile.type);
          const fileAry = imageFile.name.split('.');
          const ext = '.' + fileAry[fileAry.length - 1];
          const s3Config = {
            bucket: config.bucket,
            region: config.region,
            level: config.level,
            contentType: imageFile.type
          }

          // identityId取得
          Auth.currentUserCredentials().then(session => {
            Storage.put(Date.now().toString() + ext, uploadBlob, s3Config)
            .then(result => {
              clearTimeout(this.timeoutIdImage);
              const image_path = session.identityId + '/' + result['key'];
              return resolve(image_path);
            })
            .catch(err => {
              clearTimeout(this.timeoutIdImage);
              this.commonUtil.debug().log(err);
              return reject();
            });
          });

          // タイムアウトを設定
          this.timeoutIdImage = setTimeout(() => {
            return reject();
          }, Constant.apiTimeoutSecond);
        })
        .catch(err2 => {
          this.toastUtil.showErrorToast('', Constant.msgErrorUploadFile, Constant.toastShowMiliSecErr);
          return reject();
        });
      });
    });
  }

  // BlobをDataURLに変換
  private getDataUrl(blobImage: Blob, options: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      loadImage(
        blobImage,
        canvas => {
          if (canvas.type === 'error') {
            reject();
          } else {
            resolve(canvas.toDataURL(blobImage.type));
          }
        },
        options
      );
    });
  }

  // 引数のBase64の文字列をBlob形式にする
  private base64ToBlob(base64, filetype) {
    const base64Data = base64.split(',')[1], // Data URLからBase64のデータ部分のみを取得
      data = window.atob(base64Data), // base64形式の文字列をデコード
      buff = new ArrayBuffer(data.length),
      arr = new Uint8Array(buff);
    // blobの生成
    for (let i = 0, dataLen = data.length; i < dataLen; i++) {
      arr[i] = data.charCodeAt(i);
    }
    const blob = new Blob([arr], { type: filetype });
    return blob;
  }

  /**
   * S3にPDFをアップロードする
   * @param {Object} pdfFile   - アップロードするPDFのファイルオブジェクト
   * @return {Promise}
   */
   public uploadPdfDataToS3(pdfFile, config): Promise<any> {
    return new Promise((resolve, reject) => {
      // ファイル形式チェック
      if (pdfFile.type !== Constant.fileFormatPdf) {
        this.toastUtil.showErrorToast('', Constant.msgErrorInvalidFile, Constant.toastShowMiliSecErr);
        return reject();
      }

      // ファイルサイズチェック 5MB以下
      if (pdfFile.size > Constant.fileMaxSize) {
        this.toastUtil.showErrorToast('', Constant.msgErrorLargeFile, Constant.toastShowMiliSecErr);
        return reject();
      }

      if (pdfFile.size === 0) {
        this.toastUtil.showErrorToast('', Constant.msgErrorUploadFile, Constant.toastShowMiliSecErr);
        return reject();
      }
      const s3Confing = {
        bucket: config.bucket,
        region: config.region,
        level: config.level,
        contentType: pdfFile.type
      }

      Auth.currentUserCredentials().then(session => {
        Storage.put(Date.now().toString() + '.pdf', pdfFile, s3Confing)
        .then(result => {
          clearTimeout(this.timeoutId);
          const pdf_path = session.identityId + '/' + result['key'];
          return resolve(pdf_path);
        })
        .catch(err => {
          clearTimeout(this.timeoutId);
          this.commonUtil.debug().log(err);
          return reject();
        });
      });

      // タイムアウトを設定
      this.timeoutId = setTimeout(() => {
        return reject();
      }, Constant.apiTimeoutSecond);
    });
  }

  /**
   * S3にメッセージ添付ファイルをアップロードする
   * @param {Object} file   - アップロードするファイルオブジェクト
   * @return {Promise}
   */
   public uploadMsgFileDataToS3(file, config): Promise<any> {
    return new Promise((resolve, reject) => {
      // ファイル形式チェック
      if (file.type === Constant.imageFormatJpg || file.type === Constant.imageFormatPng || file.type === Constant.fileFormatPdf
        || file.type === Constant.fileFormatPpt || file.type === Constant.fileFormatPptx || file.type === Constant.fileFormatXls
        || file.type === Constant.fileFormatXlsx || file.type === Constant.fileFormatDoc || file.type === Constant.fileFormatDocx ) {
          // OK
      } else {
        this.toastUtil.showErrorToast('', Constant.msgErrorInvalidFile, Constant.toastShowMiliSecErr);
        return reject();
      }

      // ファイルサイズチェック 5MB以下
      if (file.size > Constant.fileMaxSize) {
        this.toastUtil.showErrorToast('', Constant.msgErrorLargeFile, Constant.toastShowMiliSecErr);
        return reject();
      }

      const fileAry = file.name.split('.');
      const ext = '.' + fileAry[fileAry.length - 1];
      const s3Confing = {
        bucket: config.bucket,
        region: config.region,
        level: config.level,
        contentType: file.type
      }

      Auth.currentUserCredentials().then(session => {
        // ファイルアップロード
        Storage.put(Date.now().toString() + ext, file, s3Confing)
        .then(result => {
          const path = session.identityId + '/' + result['key'];
          return resolve(path);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          return reject();
        });
      });
    });
  }

  /**
   * S3にアップロードしたファイルURLを取得する
   * @param {String} path   - アップロードしたファイルのパス
   * @return {Promise}
   */
   public getUploadFileUrlS3(path, config): Promise<any> {
    return new Promise((resolve, reject) => {
      const key = path.split('/')[1];
      Auth.currentUserCredentials().then(session => {
        Storage.get(key, config).then(url => {
          return resolve(url);
        })
        .catch(err2 => reject());
      })
      .catch(err => reject());
    });
  }

  /**
   * S3にアップロードしたファイルの削除
   * @param {Object} key   - アップロードしたファイルの名前
   * @return {Promise}
   */
   public delMsgFileFromS3(key, config): Promise<void> {
    return new Promise((resolve, reject) => {
      Auth.currentUserCredentials().then(session => {
        // ファイル削除
        Storage.remove(key, config)
        .then(result => {
          return resolve();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          return reject();
        });
      });
    });
  }

  /**
   * S3にCSVをアップロードする
   * @param {Object} csvFile   - アップロードするCSVのファイルオブジェクト
   * @return {Promise}
   */
   public uploadTalentCsvToS3(csvFile, config): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(csvFile);
      // ファイルサイズチェック 10MB以下
      if (csvFile.size > Constant.fileTalentMaxSize) {
        this.toastUtil.showErrorToast('', Constant.msgErrorLargeFile, Constant.toastShowMiliSecErr);
        return reject('file');
      }

      if (csvFile.size === 0) {
        this.toastUtil.showErrorToast('', Constant.msgErrorUploadFile, Constant.toastShowMiliSecErr);
        return reject('file');
      }

      const s3Confing = {
        bucket: config.bucket,
        region: config.region,
        level: config.level,
        contentType: csvFile.type
      }

      Auth.currentUserCredentials().then(session => {
        Storage.put(Date.now().toString() + '.csv', csvFile, s3Confing)
        .then(result => {
          clearTimeout(this.timeoutId);
          const file_path = session.identityId + '/' + result['key'];
          return resolve(file_path);
        })
        .catch(err => {
          clearTimeout(this.timeoutId);
          this.commonUtil.debug().log(err);
          return reject('file');
        });
      });

      // タイムアウトを設定
      this.timeoutId = setTimeout(() => {
        console.log('timeout');
        return reject();
      }, Constant.apiTimeoutSecond);
    });
  }
}
