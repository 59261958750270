import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { environment } from './../../../environments/environment';
import { ImageUtil } from './../../util/image-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { QuillUtil } from './../../util/quill-util';
import { sprintf } from 'sprintf-js';
import Quill from 'quill';

@Component({
  selector: 'app-prcontent-edit-dialog',
  templateUrl: './prcontent-edit-dialog.component.html',
  styleUrls: ['./prcontent-edit-dialog.component.css']
})
export class PrcontentEditDialogComponent implements OnInit {
  public form: FormGroup;
  public prContent;
  public tagMaster = [];
  public tag1Choices = [];
  public tag2Choices = [];
  public tag3Choices = [];
  public tag4Choices = [];
  public tag5Choices = [];
  public categoryMaster = [];
  public readonly Constant = Constant;
  public mode;
  public isSaving = false;
  public showSpinner = true;
  public contentsImagePath = null;
  private contentsImageData = null;
  private uploadedContentsImagePath = null;
  private apiPath = '/prcontent';

  public maxLenTitle = 50;
  public maxLenDescription = 5000;

  @ViewChild('imgFileInput')
  private imgFileInput: ElementRef;

  public editor;
  public loadFlg = false;
  private firstRender = true;
  private regexp_url = /(https?|ftp):\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;

  constructor(
    public dialogRef: MatDialogRef<PrcontentEditDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private auth: AuthService,
    private quillUtil: QuillUtil,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mode = data.type;
    if (this.mode === Constant.joTypeAdd) {
      this.loadFlg = true;
    }
  }

  ngOnInit() {
    if (this.data.id !== 0) {
      this.getDetail();
    } else {
      this.prContent = {
        image_url: Constant.empty,
        image_path: Constant.empty,
        title: Constant.empty,
        description: Constant.empty,
        tag1: Constant.empty,
        tag2: Constant.empty,
        tag3: Constant.empty,
        tag4: Constant.empty,
        tag5: Constant.empty,
        category_id: Constant.empty,
      };
      this.getContentsTagMaster();
    }
    this.getCategoryMaster();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();

    if (this.contentsImageData) {
      // ロゴ画像が変更されていた場合はS3に保存し、データを更新
      const config = environment.amplify.Storage.contentsImage;
      this.uploadImageData(this.contentsImageData, Constant.companyImageCompanyLogo, config);
    } else {
      this.dataUpdate();
    }
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.pcMsgConfirmEditCancel, this.mode), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // メイン画像選択
  onChangeImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function () {
      this.contentsImagePath = fileReader.result;
      this.contentsImageData = file;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 画像削除
  onImageDelete() {
    this.contentsImagePath = Constant.empty;
    this.contentsImageData = null;
    this.imgFileInput.nativeElement.value = '';
  }

  onTagChange() {
    this.createTagChoices(this.form.value.tag1, this.form.value.tag2, this.form.value.tag3, this.form.value.tag4, this.form.value.tag5);
  }

  /* プライベート */
  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/detail/' + this.data.id;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        if (!res.data) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteContentAlreadyError, Constant.toastShowMiliSec);
          this.dialogRef.close(true);
          return;
        }
        this.prContent = res.data;
        this.getContentsTagMaster();
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.dialogRef.close();
      });
    });
  }

  // タグマスタ取得
  private getContentsTagMaster() {
    // ぐるぐる表示
    const masterApiPath = this.apiPath + '/master';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(masterApiPath, options)
        .then(res => {
          this.tagMaster = res.data;
          this.convertIdToText(this.prContent);
          this.createTagMasterData(this.tagMaster);
          this.initForm();
          this.contentsImagePath = this.prContent.image_url;
          this.showSpinner = false;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }

  // カテゴリマスタ取得
  private getCategoryMaster() {
    const masterApiPath = this.apiPath + '/category';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(masterApiPath, options)
        .then(res => {
          this.categoryMaster = res.data.data;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }

  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      title: new FormControl(Constant.empty, [Validators.required]),
      description: new FormControl(Constant.empty, [Validators.required]),
      tag1: new FormControl(Constant.empty),
      tag2: new FormControl(Constant.empty),
      tag3: new FormControl(Constant.empty),
      tag4: new FormControl(Constant.empty),
      tag5: new FormControl(Constant.empty),
      category_id: new FormControl(Constant.empty),
    });

    // 初期値設定
    this.prContent.description = this.prContent.description.replaceAll('\n', '<br>');
    setTimeout(() => {
      this.form.patchValue({
        title: this.prContent.title,
        tag1: this.prContent.tag1,
        tag2: this.prContent.tag2,
        tag3: this.prContent.tag3,
        tag4: this.prContent.tag4,
        tag5: this.prContent.tag5,
        category_id: this.prContent.category_id,
      });

      // 本文反映を遅らせる
      setTimeout(() => {
        this.form.patchValue({
          description: this.prContent.description,
        });
      });
    });
  }

  private createTagMasterData(data) {
    const noRegist = {
      id: 0,
      title: Constant.textNotRegist
    };
    this.tagMaster.unshift(noRegist);
    this.createTagChoices(this.prContent.tag1, this.prContent.tag2, this.prContent.tag3, this.prContent.tag4, this.prContent.tag5);
  }

  // タグIDをテキストに変換する
  private convertIdToText(prContent) {
    const textAry = [];
    for (let i = 1; i <= 5; i++) {
      if (prContent['tag' + i] !== 0) {
        const tag = this.tagMaster.filter(function(data) {
          return data.id === prContent['tag' + i];
        });
        if (tag.length > 0) {
          textAry.push(tag[0].title);
        }
      } else {
        prContent['tag' + i] = Constant.empty;
      }
    }
    if (textAry.length === 0) {
      textAry.push(Constant.textNotRegist);
    }
    this.prContent.tagTextAry = textAry;
    this.showSpinner = false;
  }

  // 他のタグで選択されている値を除いた選択肢を作成する
  private createTagChoices(tag1, tag2, tag3, tag4, tag5) {
    this.tag1Choices = this.tagMaster.filter(
      function (tag) {
        return (tag.id !== tag2 && tag.id !== tag3 && tag.id !== tag4 && tag.id !== tag5) || tag.id === 0;
      }.bind(this)
    );
    this.tag2Choices = this.tagMaster.filter(
      function (tag) {
        return (tag.id !== tag1 && tag.id !== tag3 && tag.id !== tag4 && tag.id !== tag5) || tag.id === 0;
      }.bind(this)
    );
    this.tag3Choices = this.tagMaster.filter(
      function (tag) {
        return (tag.id !== tag1 && tag.id !== tag2 && tag.id !== tag4 && tag.id !== tag5) || tag.id === 0;
      }.bind(this)
    );
    this.tag4Choices = this.tagMaster.filter(
      function (tag) {
        return (tag.id !== tag1 && tag.id !== tag2 && tag.id !== tag3 && tag.id !== tag5) || tag.id === 0;
      }.bind(this)
    );
    this.tag5Choices = this.tagMaster.filter(
      function (tag) {
        return (tag.id !== tag1 && tag.id !== tag2 && tag.id !== tag3 && tag.id !== tag4) || tag.id === 0;
      }.bind(this)
    );
  }

  // 画像をS3にアップロードする
  private uploadImageData(imageData, imageType, config) {
    // 画像をアップロード
    const maxHeight = Constant.uploadImageHeight;
    const maxWidth = Constant.uploadImageWidth;

    this.imageUtil
      .uploadImageDataToS3(imageData, maxHeight, maxWidth, config)
      .then(image_path => {
        this.uploadedContentsImagePath = image_path;
        this.dataUpdate();
      })
      .catch(err => {
        let msg;
        if (this.mode === Constant.joTypeEdit) {
          msg = Constant.msgSaveFailedRetry;
        } else {
          msg = Constant.msgNoticeNewFailedRetry;
        }
        this.toastUtil.showErrorToast(
          Constant.empty,
          msg,
          Constant.toastShowMiliSec
        );
        this.isSaving = false;
      });
  }

  // 採用PRコンテンツ情報を更新する
  private dataUpdate() {
    let tag1Data = 0;
    let tag2Data = 0;
    let tag3Data = 0;
    let tag4Data = 0;
    let tag5Data = 0;
    if (this.form.value.tag1 !== Constant.empty) {
      tag1Data = this.form.value.tag1;
    }
    if (this.form.value.tag2 !== Constant.empty) {
      tag2Data = this.form.value.tag2;
    }
    if (this.form.value.tag3 !== Constant.empty) {
      tag3Data = this.form.value.tag3;
    }
    if (this.form.value.tag4 !== Constant.empty) {
      tag4Data = this.form.value.tag4;
    }
    if (this.form.value.tag5 !== Constant.empty) {
      tag5Data = this.form.value.tag5;
    }

    const imagePath = this.getImagePathParam();

    // 本文整形
    const parser = new DOMParser();
    const desc = parser.parseFromString(this.form.value.description, 'text/html');

    // 画像やOGPの件数チェック
    const imgCnt = desc.querySelectorAll('img[data-path], .ogp-div, iframe');
    if (imgCnt.length > 50) {
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgErrorSaveEditorImg, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    // OGPローディング削除
    const loadings = desc.querySelectorAll('.ogp-loading');
    loadings.forEach(item => {
      item.remove();
    })

    // OGP書き換え
    const ogps = desc.querySelectorAll('.ogp-div');
    ogps.forEach(ogp => {
      if (ogp.children.length > 0) {
        const url = ogp.children[0].getAttribute('href');
        ogp.innerHTML = url;
        ogp.removeAttribute('contenteditable');
      }
    });

    // imgのbase64データ削除
    const imgs = desc.querySelectorAll('img');
    imgs.forEach(img => {
      img.removeAttribute('src');
    });

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        image_path: imagePath,
        title: this.commonUtil.replaceSpace(this.form.value.title),
        description: desc.body.innerHTML,
        tag1: tag1Data,
        tag2: tag2Data,
        tag3: tag3Data,
        tag4: tag4Data,
        tag5: tag5Data,
        category_id: this.form.value.category_id ? this.form.value.category_id : 0,
        email: localStorage.getItem(Constant.lsOperator),
      };

      if (this.mode === Constant.joTypeEdit) {
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPrContentEditSave);
        // 更新
        options['body']['id'] = this.prContent.id;
        this.commonUtil
          .apiPut(this.apiPath, options)
          .then(res => {
            this.toastUtil.showInformationToast(
              Constant.empty,
              Constant.msgResultSaveSuccess,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(res.status);
            this.isSaving = false;
          })
          .catch(err => {
            this.commonUtil.debug().log(err);
            this.isSaving = false;
            if (err.status === Constant.NG && err.data.cnt !== 0) {
              // 削除済み
              this.toastUtil.showErrorToast(
                Constant.empty,
                Constant.msgResultSaveFailed,
                Constant.toastShowMiliSec
              );
              this.dialogRef.close(err.status);
            } else {
              this.toastUtil.showErrorToast(
                Constant.empty,
                Constant.msgSaveFailedRetry,
                Constant.toastShowMiliSec
              );
            }
          });
      } else if (this.mode === Constant.joTypeAdd) {
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPrContentNewSave);
        // 新規登録
        this.commonUtil
          .apiPost(this.apiPath, options)
          .then(res => {
            this.isSaving = false;
            this.toastUtil.showInformationToast(
              Constant.empty,
              Constant.msgNoticeNew,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(res.status);
          })
          .catch(err => {
            this.commonUtil.debug().log(err);
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgNoticeNewFailedRetry,
              Constant.toastShowMiliSec
            );
            this.isSaving = false;
          });
      }
    });
  }

  private getImagePathParam() {
    if (this.uploadedContentsImagePath) {
      // 画像が更新されていた場合
      return this.uploadedContentsImagePath;
    }
    if (this.contentsImagePath === Constant.empty) {
      // 画像が削除されていた場合
      return this.contentsImagePath;
    }
    // 削除の変更もされていない場合はそのまま
    return this.prContent.image_path;
  }

  /* エディタ関連 */
  onEditorCreated(quill) {
    this.editor = quill;
    const Delta = Quill.import('delta')

    const video = document.getElementsByClassName('ql-video');
    if (video.length > 0) {
      video[0].innerHTML = '<img src="assets/common/youtube_icon_x2.png">';
    }

    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);

    // リンク・動画埋め込みダイアログのプレースホルダー変更
    const tooltip = quill.theme.tooltip.textbox.dataset;
    tooltip.link = 'https://calin.co.jp';
    tooltip.video = 'https://www.youtube.com';

    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      if (node.outerHTML === '<br class="Apple-interchange-newline">') {
        return new Delta()
      }

       // macのPDFプレビュー貼り付け対応
      if (node.className === 'page' && node.children?.[0].className === 'section') {
        const text = node.innerText.trim().replace(/\n+\t+/g, '').normalize()
        return new Delta().insert(text)
      }

      // sbreakとbreakが二重になる件
      if (delta.ops.length === 2) {
        if (delta.ops[0].insert && delta.ops[0].insert.sbreak && delta.ops[1].insert === Constant.elemNewLine) {
          const newOps = [delta.ops[1]]
          delta.ops = newOps
        }
      }
      return delta;
    });

    // ドラッグを無効にする
    quill.container.addEventListener('dragstart', function(e) {
      e.preventDefault()
    });
    quill.container.addEventListener('drop', function(e) {
      e.preventDefault()
    });

  }

  // エディタ変更検知
  onContentChanged(event) {
    // console.log(event)
    if (this.mode === Constant.joTypeEdit && this.firstRender && this.editor) {
      // 初期表示時、画像差し替え
      this.quillUtil.checkReloadQuill(this.editor, true);
      this.firstRender = false;

      // 読み込みチェック
      const loadElementsAll = document.querySelectorAll<HTMLElement>(
        '.ql-container img'
      );
      if (loadElementsAll.length > 0) {
        setTimeout(() => {
          this.checkLoadCnt(loadElementsAll.length)
        }, 500);
      } else {
        this.loadFlg = true
      }

      // 最後の改行が削除されてしまうため付け足す
      if (this.prContent.description.slice(-11) === '<p><br></p>') {
        const idx = this.editor.getLength()
        this.editor.insertText(idx, Constant.elemNewLine)
      }

      return;
    }

    if (this.isSaving) {
      return;
    }

    if (!this.editor.getSelection()) {
      // カーソルがない場合は見出しの作り直し、ブロック内の改行クリアのみ
      this.updateToc();
      this.quillUtil.updatePtagBr(this.editor);
      return;
    }

    // OGP、オートリンク、引用解除時の改行コード置換
    let newLineFlg = false;
    event.delta.ops.forEach(element => {
      if (element.insert && element.insert === Constant.elemNewLine) {
        newLineFlg = true;
        this.checkOgp();
      } else if (element.insert && (element.insert == ' ' || element.insert == '　' || element.insert == '\xa0')) {
        this.autoLink();
      } else if (element.attributes && element.attributes.blockquote === null) {
        this.quillUtil.updatePtagBr(this.editor);
      }
    });

    // 見出し作り直し
    this.updateToc();

    // 引用内に改行がある場合のカーソル移動
    const deleteFlg = event.delta.ops.filter(function(data) {
      return data.delete;
    });
    if (deleteFlg.length > 0) {
      const range = this.editor.getSelection()
      let prevLeaf = this.editor.getLeaf(range.index - 1)[0]
      let currentLeaf = this.editor.getLeaf(range.index)[0]
      if (currentLeaf.constructor.name === 'SmartBreak' && currentLeaf.parent.children.length > 1
      && prevLeaf.constructor.name !== 'SmartBreak' && currentLeaf.next?.constructor.name !== 'SmartBreak') {
        this.editor.setSelection(range.index - 1, 0, 'silent')
      }
    }

    // カーソル位置が隠れないよう、最下部に移動（最下部入力時）
    if (this.editor.getSelection().index >= this.editor.getLength() - 2) {
      const bound = this.editor.getBounds(this.editor.getSelection().index);
      if (bound.bottom > 397) {
        this.editor.scrollingContainer.scrollTop = this.editor.scrollingContainer.scrollHeight - this.editor.scrollingContainer.clientHeight ;
      }
    }
  }

  private checkLoadCnt(cnt) {
    if (this.quillUtil.loadErr) {
      // 読み込みエラーの場合ダイアログを閉じる
      this.dialogRef.close();
      return;
    }

    if (this.quillUtil.loadCnt >= cnt) {
      this.loadFlg = true;
    } else {
      setTimeout(() => {
        this.checkLoadCnt(cnt)
      }, 500);
    }
  }

  // 目次の挿入
  onEditorToc() {
    const selection = this.editor.getSelection();
    let cursorPosition = 0;
    if (selection) {
      cursorPosition = selection.index;
    }
    const tocHtml = this.createTocHTML();
    this.editor.insertEmbed(cursorPosition, 'toc-div', tocHtml, 'user');
    this.editor.setSelection(cursorPosition + 1);
  }

  // 文字数カウント
  onEditorCount() {
    return this.editor.getLength() - 1;
  }

  // 目次更新
  private updateToc() {
    const tocDiv = document.querySelectorAll(
      '.ql-container .toc-div'
    );
    if (tocDiv.length > 0) {
      const tocHtml = this.createTocHTML();
      tocDiv.forEach(div => {
        div.innerHTML = tocHtml;
      })
    }
  }

  // 目次のHTML作成
  private createTocHTML() {
    const headingTagElementsAll = document.querySelectorAll<HTMLElement>(
      '.ql-container h3, .ql-container h4'
    );

    // 改行のみの見出しを削除
    const headingTagElements = [];
    headingTagElementsAll.forEach((headingTagElement) => {
      if (headingTagElement.innerText !== Constant.elemNewLine) {
        headingTagElements.push(headingTagElement);
      }
    });
    // HTML作成
    let tocHtml = '';
    headingTagElements.forEach((headingTagElement, index) => {
      tocHtml  += '<div class="header-' + headingTagElement.tagName + '">' + headingTagElement.innerText + '</div>'
      if (index != headingTagElements.length -1) {
        tocHtml  += '<hr class="header-' + headingTagElement.tagName + '">';
      }
    });
    if (tocHtml === '') {
      tocHtml = '<div class="toc-title bottom0">目次<span>（見出しを作ると項目が表示されます）</span></div>' + tocHtml;
    } else {
      tocHtml = '<div class="toc-title">目次</div>' + tocHtml;
    }

    return tocHtml;
  }

  // オートリンク
  private autoLink() {
    const [leaf, offset] = this.editor.getLeaf(this.editor.getSelection().index);
    const index = this.editor.getIndex(leaf);

    if (!leaf.text) {
      return;
    }

    if (leaf.text.match(this.regexp_url) != null) {
      const urlAllMatches = leaf.text.match(this.regexp_url);
      if (urlAllMatches) {
        const urlMatches = new Set<string>(urlAllMatches);
          urlMatches.forEach(url => {
            const urlIndex = leaf.text.indexOf(url);
            this.editor.formatText(index + urlIndex, url.length, 'link', url, 'user');
          });
      }
    }
  }

  // OGP
  private checkOgp() {
    const selection = this.editor.getSelection(true);
    const [leaf, offset] = this.editor.getLeaf(selection.index - 1);
    const index = this.editor.getIndex(leaf);
    const [line, offsetLine] = this.editor.getLine(selection.index - 1);
    if (!leaf.text || leaf.parent.domNode.nodeName === 'A') {
      return;
    }

    if (leaf.text.match(this.regexp_url) != null) {
      // URLが含まれる場合OGP
      const urlAllMatches = leaf.text.match(this.regexp_url);
      const urlMatches = new Set<string>(urlAllMatches);
      urlMatches.forEach(url => {
        const urlIndex = leaf.text.indexOf(url);
        if (line.children.length === 1 && urlIndex === 0) {
          // OGP挿入
          setTimeout(() => {
            this.editor.setSelection(index + urlIndex)
            this.getOgpData(url, index + urlIndex);
            this.editor.deleteText(index + urlIndex, url.length + 1);
            this.editor.insertEmbed(index + urlIndex, 'ogp-loading', url, 'silent');
          });
        } else {
          // オートリンク
          this.editor.formatText(index + urlIndex, url.length, 'link', url);
        }
      });
    }
  }

  // OGP情報取得
  private getOgpData(url, cursorIndex) {
    this.quillUtil.getOgpHtml(url).then(html => {
      this.setOgpHtml(html, url, cursorIndex);
    })
    .catch(err => {
      this.commonUtil.debug().log(err)
      // 取得エラーの場合、オートリンクとする
      this.createErrorOgp(url, cursorIndex);
    });
  }

  // OGPのHTMLに差し替え
  private setOgpHtml(html, url, cursorIndex) {
    const [leaf, offset] = this.editor.getLeaf(cursorIndex);
    this.editor.removeFormat(cursorIndex, 1)
    this.editor.insertEmbed(cursorIndex, 'ogp-div', html, 'user');

    // 上部に入力した場合、OGPが隠れないように位置調整
    const bound = this.editor.getBounds(cursorIndex);
    if (bound.top < 0) {
      this.editor.scrollingContainer.scrollTop += bound.top - 6;
    }
    this.editor.setSelection(cursorIndex + 1);
  }

  // OGP取得エラー時はオートリンクとする
  private createErrorOgp(url, cursorIndex) {
    const [leaf, offset] = this.editor.getLeaf(cursorIndex);
    this.editor.removeFormat(cursorIndex, 1)
    this.editor.insertText(cursorIndex, Constant.elemNewLine, 'user');
    this.editor.insertText(cursorIndex, url, 'link', url, 'user');
    this.editor.setSelection(cursorIndex + url.length + 1);
  }

  // 画像アップロードのデフォルトのハンドラをリセット
  public imageHandler() {
    return;
  }

  // 画像アップロード
  public onImageUpload() {
    const fileInput = document.createElement('input');
    const self = this;
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg');
    fileInput.addEventListener('change', (event: Event) => {
      const target = event.target;
      this.uploadContentsImage(target);
    });
    fileInput.click();
  }

  private uploadContentsImage(target) {
    if (!target || !target.files[0]) {
      return;
    }
    const file = target.files[0];

    // 本文中画像をアップロード
    const maxHeight = Constant.uploadImageHeight;
    const maxWidth = Constant.uploadImageWidth;

    const config = environment.amplify.Storage.contentsEditorImage;

    this.imageUtil
      .uploadImageDataToS3(file, maxHeight, maxWidth, config)
      .then(image_path => {
        // 表示用
        const fileReader = new FileReader();
        fileReader.onload = function() {
          let cursor = this.editor.getSelection().index;
          this.editor.insertEmbed(cursor, 'image', { src: fileReader.result, 'data-path': image_path}, 'user');
          this.editor.setSelection(cursor + 1, 0, 'silent');

        }.bind(this);
        fileReader.readAsDataURL(file);

      })
      .catch(err => {
      });
  }
}
