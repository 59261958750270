import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { TalentListPagingCondition } from '../model/TalentListPagingCondition';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';

@Component({
  selector: 'app-multi-message-except',
  templateUrl: './multi-message-except.component.html',
  styleUrls: ['./multi-message-except.component.css']
})
export class MultiMessageExceptComponent implements OnInit {
  public talentData;
  public talents = null;
  public totalCnt;
  public showSpinner = true;
  private condition;
  private isSearching = false;
  private currentOffset = 0;
  private isNextData = true;
  private scrollElement;
  private timer;  // スクロールイベント管理用タイマー

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private router: Router,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    // 除外タレント取得
    const condition = {
      type: Constant.tlMultiMsgExceptType,
      id: 1
    };
    this.condition = [condition];
    this.getExceptList();

    // タレントリスト表示用
    this.talentData = {
      talents: this.talents,
      checkFlg: false,
      listFlg: false,
    };

    // スクロールコンテナの設定
    this.scrollElement = this.elementRef.nativeElement.querySelector('.container');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.isNextData) {
      return;
    }

    // スクロール最下部で次データの取得
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (window.pageYOffset + window.innerHeight - 150 >= this.scrollElement.clientHeight) {
        this.getNextData();
      }
    }, 100);
  }

  // タレントリスト
  onTalentList() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    // 検索条件クリア
    localStorage.removeItem(Constant.lsTalentListCondition);

    this.router.navigate(['/list']);
  }

  // 閉じる
  onClose() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['/message']);
  }

  // 除外タレントリスト取得
  getExceptList() {
    if (!this.commonUtil.checkOnline()) {
      this.showSpinner = false;
      return;
    }

    if (this.isSearching) {
      return;
    }

    // ページング
    const pagingReqParam: TalentListPagingCondition = {
      offset: this.currentOffset,
      limit: Constant.tlItemPerPage
    };

    this.isSearching = true;
    Auth.currentSession().then(session => {
      const apiPath = '/talentlist';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        Conditions: this.condition,
        paging: pagingReqParam
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.commonUtil.debug().log(res.data);
          this.getListExec(res, options);
          this.isSearching = false;
        })
        .catch(err => {
          this.isSearching = false;
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  /* その他プラベート関数 */
  // リストAPI取得後の処理
  private getListExec(data, options) {
    if (!this.talents) {
      this.talents = data.data.list;
    } else {
      this.talents.push(...data.data.list);
    }
    this.talentData.talents = this.talents;
    this.showSpinner = false;
    this.totalCnt = data.data.paging.total;
    // 次データ有無の設定
    if (data.data.list.length < Constant.tlItemPerPage) {
      this.isNextData = false;
    }
    if (data.data.list) {
      // 各タレントの画像URLを取得
      this.commonUtil.getTalentImg(data.data.list, options);
    }
  }

  // スクロールで次のデータ取得
  private getNextData() {
    if (this.showSpinner || !this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    this.currentOffset += Constant.tlItemPerPage;
    this.getExceptList();
  }

}
