<div class="wrap">
  <header>
    <div class="header-area">
      <div class="system-name">タレントプール採用サービス</div>
      <div class="logo"><img class="logo-image" src="../../assets/lp/logo_blue.png" width="100" (click)="onTopPage()" /></div>
    </div>
  </header>
  <div class="contents-area">
    <div class="title-area">
      <h2 class="title">資料ダウンロード</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="description">
      このたびは資料をご請求いただきありがとうございます。<br>
      下記のボタンを押下して資料をダウンロードしてください。<br><br>
      ご検討よろしくお願いいたします。
    </div>
    <mat-divider class="short"></mat-divider>
    <div class="font-bold">主に 新卒採用での利用をご検討の方</div>
    <div class="button-area">
        <button mat-button (click)="onDownload(fileNameNewgrad)" class="send-button">
          資料ダウンロード<br>（新卒）
        </button><br>
        <a (click)="onDownloadText(fileNameNewgrad)" target="_blank" class="text-download">ダウンロードに失敗する<br>またはモバイルの方はこちら</a>
    </div>
    <mat-divider class="short"></mat-divider>
    <div class="font-bold">主に 中途採用での利用をご検討の方</div>
    <div class="button-area">
        <button mat-button (click)="onDownload(fileNameCareer)" class="send-button">
          資料ダウンロード<br>（中途）
        </button><br>
        <a (click)="onDownloadText(fileNameCareer)" target="_blank" class="text-download">ダウンロードに失敗する<br>またはモバイルの方はこちら</a>
    </div>
  </div>
</div>
