<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">カラー変更</div>
      <div class="desc">エントリーページのボタンカラーなどを変更できます。<br>半角英数字の16進数カラーコード（6文字：0〜9、a〜f）で登録してください。</div>
    </div>
    <div class="list-area">
      <form [formGroup]="form">
        <table>
          <tr>
            <td>
              <div [id]="colorPreviewId"></div>
            </td>
            <td>
              #<mat-form-field class="input-text">
                <input matInput
                  formControlName="main_color"
                  type="text"
                  [placeholder]="Constant.companyMainColor"
                  [maxlength]="maxLenColor"
                  (input)="onInput()"
                  (blur)="onBlur()"
                  (compositionend)="onCompositionEnd()">
              </mat-form-field>
            </td>
            <td>
              <span class="bold">メインカラー</span><br>
              つながる、応募ボタンなどに使用
            </td>
          </tr>
        </table>

      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="form.invalid || isSaving" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
