import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../auth/auth.service';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { ImageUtil } from './../../util/image-util';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'app-prcontent-category-edit-dialog',
  templateUrl: './prcontent-category-edit-dialog.component.html',
  styleUrls: ['./prcontent-category-edit-dialog.component.css']
})

export class PrcontentCategoryEditDialogComponent implements OnInit {
  public readonly Constant = Constant;
  public form: FormGroup;
  public category;
  public urlBase;
  public orderNum = Array(10);
  public isSaving = false;
  public categoryImagePath = null;
  private categoryImageData = null;
  private uploadedCategoryImagePath = null;

  // validation
  public maxLenTitle = 20;
  public maxLenDesc = 100;
  public maxLenUrl = 20;

  @ViewChild('imgFileInput')
  private imgFileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<PrcontentCategoryEditDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.category = data.category;
    this.urlBase = data.urlBase;
    this.categoryImagePath = this.category.image_url;
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    if (this.categoryImageData) {
      // 画像が変更されていた場合はS3に保存し、データを更新
      const config = environment.amplify.Storage.contentsCategoryImage;
      this.uploadImageData(this.categoryImageData, config);
    } else {
      this.dataUpdate();
    }

  }

  // 画像選択
  onChangeImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function () {
      this.categoryImagePath = fileReader.result;
      this.categoryImageData = file;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 画像削除
  onImageDelete() {
    this.categoryImagePath = Constant.empty;
    this.categoryImageData = null;
    this.imgFileInput.nativeElement.value = '';
  }

  // textareaの改行(エンターキー押下)無効化
  onKeyDown(evt) {
    if (evt.key === 'Enter') {
      return false;
    }
  }

  // textareaに貼り付けられたテキストの改行を無効化
  onInput() {
    // 入力内容を取得
    const beforeText = this.form.value.description;
    // 入力内容の改行を空文字に置き換える
    const changeText = beforeText.replace(/\r?\n/g, '');
    // 元の内容と置き換えた内容が等しくなければ、新しい内容に置き換える
    if (changeText !== beforeText) {
      this.form.patchValue({'description': changeText});
    }
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    const formControl = {};
    formControl['title'] = new FormControl(this.category.title, [Validators.required, Validators.maxLength(this.maxLenTitle)]);
    formControl['description'] = new FormControl(this.category.description, [Validators.maxLength(this.maxLenDesc)]);
    formControl['url'] = new FormControl(this.category.url,
      [Validators.required, Validators.maxLength(this.maxLenUrl), Validators.pattern(Constant.fvAlpahNumericReg)]);
    formControl['order_no'] = new FormControl(this.category.order_no);

    this.form = new FormGroup(formControl);
  }

  // 保存実行
  private dataUpdate() {
    const apiPath = '/prcontent/category';

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        title: this.commonUtil.replaceSpace(this.form.value.title),
        description: this.commonUtil.replaceSpace(this.form.value.description),
        url: this.form.value.url,
        order_no: this.form.value.order_no,
        image_path: this.getImagePathParam()
      };

      if (this.category.id) {
        // 更新
        options['body']['id'] = this.category.id;
      }

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.toastUtil.showInformationToast(
            Constant.empty,
            Constant.msgResultSaveSuccess,
            Constant.toastShowMiliSec
          );
          this.dialogRef.close(res.status);
          this.isSaving = false;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.data?.cnt === 0 || err.data?.max === true) {
            // 削除済み
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgResultSaveFailed,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(true);
          } else if (err.data?.url === true) {
            // URL重複
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgDuplicateUrl,
              Constant.toastShowMiliSec
            );
          } else {
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgSaveFailedRetry,
              Constant.toastShowMiliSec
            );
          }

          this.isSaving = false;
        });
    });
  }

  // 画像をS3にアップロードする
  private uploadImageData(imageData, config) {
    // 画像をアップロード
    const maxHeight = Constant.uploadImageHeight;
    const maxWidth = Constant.uploadImageWidth;

    this.imageUtil
      .uploadImageDataToS3(imageData, maxHeight, maxWidth, config)
      .then(image_path => {
        this.uploadedCategoryImagePath = image_path;
        this.dataUpdate();
      })
      .catch(err => {
        this.toastUtil.showErrorToast(
          Constant.empty,
          Constant.msgSaveFailedRetry,
          Constant.toastShowMiliSec
        );
        this.isSaving = false;
      });
  }

  private getImagePathParam() {
    if (this.uploadedCategoryImagePath) {
      // 画像が更新されていた場合
      return this.uploadedCategoryImagePath;
    }
    if (this.categoryImagePath === Constant.empty) {
      // 画像が削除されていた場合
      return this.categoryImagePath;
    }
    // 削除の変更もされていない場合はそのまま
    return this.category.image_path;
  }

}

