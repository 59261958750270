import { Component, OnInit } from '@angular/core';
import { Constant } from './../constant';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  public readonly Constant = Constant;
  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle(Constant.pageTitleMaintenance + Constant.pageTitleCommon);
  }
}
