import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from '../auth/auth.service';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { Constant } from './../constant';
import { ImportTalentDialogComponent } from './../dialog/import-talent-dialog/import-talent-dialog.component';

@Component({
  selector: 'app-csv-import-talent',
  templateUrl: './csv-import-talent.component.html',
  styleUrls: ['./csv-import-talent.component.css']
})
export class CsvImportTalentComponent implements OnInit {
  public showSpinner = false;
  public csvData;
  private apiPath = '/import/talent';

  constructor(
    private title: Title,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(Constant.pageTitleImportTalent + Constant.pageTitleCommon);
    // 一覧取得
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.getCsvData();
  }

  onNew(): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(ImportTalentDialogComponent, {
      width: '800px',
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // 更新
        this.getCsvData();
      }
    });

  }

  onClose(): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['/list']);
  }

  onLpTop(): void {
    window.open('/');

  }

  onRequestForCaLin() {
    window.open(Constant.requestForCalinUrl);
  }

  /* API */
  // 一覧取得
  private getCsvData() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(this.apiPath, options).then(res => {
        this.csvData = res.data;
        this.showSpinner = false;
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

}
