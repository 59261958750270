import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Constant } from './../../constant';
import { ToastUtil } from '../../util/toast-util';
import { CommonUtil } from '../../util/common-util';

@Component({
  selector: 'app-general-yes-no-dialog',
  templateUrl: './general-yes-no-dialog.component.html',
  styleUrls: ['./general-yes-no-dialog.component.css']
})
export class GeneralYesNoDialogComponent implements OnInit {
  public message;
  public safeHtml: SafeHtml;
  private onlineCheck = true;

  constructor(
    public dialogRef: MatDialogRef<GeneralYesNoDialogComponent>,
    private domSanitizer: DomSanitizer,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.msg;
    if (data.check === false) {
      this.onlineCheck = data.check;
    }
    // メッセージテキストの一部色変更のためにHTMLコードを変換
    this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close(false);
  }

  onOK() {
    if (this.onlineCheck && !this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    this.dialogRef.close(true);
  }
}
