import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constant } from './../constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUtil } from './../util/common-util';
import { AuthService } from './../auth/auth.service';
import { ToastUtil } from './../util/toast-util';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  public readonly Constant = Constant;
  public title = '';
  public isSendFinish = false;
  public form: FormGroup;
  public mode;
  public errorMessage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService
  ) {
    this.mode = this.activatedRoute.snapshot.params['mode'];

    // URLが変更されたら再読み込みする
    this.activatedRoute.url.subscribe(params => {
      if (params[1].path !== this.mode) {
        this.mode = params[1].path;
        this.isSendFinish = false;
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    // 画面表示時に一番上に持っていく
    window.scroll(0, 0);

    // ページタイトル設定
    if (this.mode === Constant.modeNewAccount) {
      // アカウント登録申請
      this.title = Constant.requestTitleAccount;
    } else {
      // 資料請求申請
      this.title = Constant.requestTitleWhitePaper;
    }

    this.initForm();
  }

  // 送信ボタン
  onSend() {
    // GA送信
    const action = this.mode === Constant.modeNewAccount ? Constant.gaActionAccountSend : Constant.gaActionWhitepaperSend;
    this.onClickGAEvent(action);

    this.checkInputValue();
  }

  // リンククリック時のAnalyticsEvent
  onClickGAEvent(action) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, action);
  }

  // フォームの初期化
  private initForm() {
    // 設立年月が登録されていない場合は空白
    this.form = new FormGroup({
      company_name: new FormControl('', [Validators.required]),
      department: new FormControl('', [Validators.required]),
      applicant_name: new FormControl('', [Validators.required]),
      tell_no: new FormControl('', [Validators.required, Validators.pattern(/^(0{1}\d{9,10})$/)]),
      mail_address: new FormControl('', [Validators.required, Validators.email]),
      homepage: new FormControl('', [Validators.required]),
      scene: new FormControl(Constant.useSceneNewgrad, [Validators.required]),
    });
  }

  // 入力値チェック
  private checkInputValue() {

    this.errorMessage = '';
    // 企業名
    if (this.form.controls.company_name.errors) {
      this.errorMessage = Constant.msgRequestCompanyNameEmpty;
      return;
    }
    // 部署名
    if (this.form.controls.department.errors) {
      this.errorMessage = Constant.msgRequestDepartmentEmpty;
      return;
    }
    // 名前
    if (this.form.controls.applicant_name.errors) {
      this.errorMessage = Constant.msgRequestNameEmpty;
      return;
    }
    // 電話番号
    if (this.form.controls.tell_no.errors) {
      if (this.form.controls.tell_no.errors.required) {
        this.errorMessage = Constant.msgRequestTellNoEmpty;
        return;
      }
      if (this.form.controls.tell_no.errors.pattern) {
        this.errorMessage = Constant.msgRequestTellNoInvalid;
        return;
      }
    }
    // メールアドレス
    if (this.form.controls.mail_address.errors) {
      if (this.form.controls.mail_address.errors.required) {
        this.errorMessage = Constant.msgRequestMailAddressEmpty;
        return;
      }
      if (this.form.controls.mail_address.errors.email) {
        this.errorMessage = Constant.msgRequestMailAddressInvalid;
        return;
      }
    }
    // ホームページ
    if (this.form.controls.homepage.errors) {
      this.errorMessage = Constant.msgRequestHomepageEmpty;
      return;
    }
    this.sendData();
  }

  private sendData() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const options = this.auth.createApiHeaderBeforeLogin();
    options['body'] = {
      company_name: this.form.value.company_name,
      department: this.form.value.department,
      applicant_name: this.form.value.applicant_name,
      tell_no: this.form.value.tell_no,
      mail_address: this.form.value.mail_address,
      homepage: this.form.value.homepage,
      mode: this.mode,
      use_scene: this.form.value.scene,
    };
    const apiPath = '/request';

    this.commonUtil
      .apiPost(apiPath, options)
      .then(res => {
        // 画面表示時に一番上に持っていく
        window.scroll(0, 0);

        this.isSendFinish = true;
        // 申請後のページタイトル設定
        if (this.mode === Constant.modeNewAccount) {
          // アカウント登録申請
          this.title = Constant.requestTitleAccountCmp;

          // HR　NOTE広告用タグ
          this.commonUtil.insertLpAd(Constant.lpHrnoteScriptId, Constant.lpHrnoteAdEnd);
        } else {
          // 資料請求申請
          this.title = Constant.requestTitleWhitePaperCmp;
        }
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
      });
  }
}
