<app-lp-header page="case"></app-lp-header>
<div class="wrap">
  <ng-container *ngIf="caseId==0; else detail">
    <h2>CaLinの活用例　研究所</h2>
    <div *ngIf="showSpinner" class="spinner">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <div class="flex">
      <div class="col" *ngFor="let case of caseList">
        <a routerLink="/case/{{ case.id }}"><img *ngIf="case.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ case.image_path }}"></a>
        <a class="title" routerLink="/case/{{ case.id }}">{{ case.title }}</a>
        <div class="content">{{ ellipsisText(case.content, 140) }}</div>
      </div>
    </div>
  </ng-container>
  <ng-template #detail>
    <div class="back">
      <a routerLink="/case">＜ CaLinの活用例　研究所</a>
    </div>
    <div class="detail">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner diameter="60"></mat-spinner>
      </div>
      <ng-container *ngIf="detailData">
        <h2 class="title">{{ detailData.title }}</h2>
        <img *ngIf="detailData.image_path" class="image" src="{{ columnImgUrl }}{{ detailData.image_path }}">
        <div [innerHTML]='detailData.contentHtml' class="content"></div>
        <mat-divider></mat-divider>
        <div class="pagelink">
          <div class="col left" [class.no-data]="!detailData.pre">
            <ng-container *ngIf="detailData.pre">
              <a routerLink="/case/{{ detailData.pre.id }}" *ngIf="detailData.pre" class="textlink">＜＜　前の活用例</a>
              <a routerLink="/case/{{ detailData.pre.id }}"><img *ngIf="detailData.pre.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ detailData.pre.image_path }}"></a>
              <a class="title" [class.padding-top]="!detailData.pre.image_path" routerLink="/case/{{ detailData.pre.id }}">{{ detailData.pre.title }}</a>
              <div class="content">{{ ellipsisText(detailData.pre.content, 49) }}</div>
            </ng-container>
          </div>
          <div class="col right" [class.no-data]="!detailData.next">
            <ng-container *ngIf="detailData.next">
              <a routerLink="/case/{{ detailData.next.id }}" *ngIf="detailData.next" class="textlink">次の活用例　＞＞</a>
              <a routerLink="/case/{{ detailData.next.id }}"><img *ngIf="detailData.next.image_path" class="image" lazyLoad="{{ columnImgUrl }}{{ detailData.next.image_path }}"></a>
              <a class="title" [class.padding-top]="!detailData.next.image_path" routerLink="/case/{{ detailData.next.id }}">{{ detailData.next.title }}</a>
              <div class="content">{{ ellipsisText(detailData.next.content, 49) }}</div>
            </ng-container>
          </div>
        </div>
        <div class="back-bottom">
          <a routerLink="/case">CaLinの活用例一覧に戻る</a>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
<app-lp-footer></app-lp-footer>
