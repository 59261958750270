<div *ngIf="showSpinner" class="spinner">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<!-- 横長・モバイル用PDF -->
<pdf-viewer *ngIf="!tate" [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="pdfPage" [render-text]="false"
[fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true"
(after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()" [class.hidden]="spinner"></pdf-viewer>
<!-- 縦長用PDF -->
<pdf-viewer *ngIf="tate" [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="pdfPage" [render-text]="false"
[fit-to-page]="true" [original-size]="true" [autoresize]="true"
(after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"></pdf-viewer>
<div class="pdf-viewer-navi" *ngIf="pdfTotalPage>0">
  <div class="leftbtn">
    <button mat-flat-button (click)="prevBtn()" [disabled]="pdfPage==1">＜</button>
    <button mat-flat-button (click)="nextBtn()" [disabled]="pdfPage==pdfTotalPage">＞</button>
  </div>
  <span>{{ pdfPage }} / {{ pdfTotalPage }}</span>
  <div class="rightbtn">
    <button mat-icon-button mat-dialog-close><mat-icon>fullscreen_exit</mat-icon></button>
  </div>
</div>
