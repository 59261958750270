import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { PaginationInstance } from 'ngx-pagination';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { TalentListPagingCondition } from '../model/TalentListPagingCondition';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';

@Component({
  selector: 'app-multi-message-talent',
  templateUrl: './multi-message-talent.component.html',
  styleUrls: ['./multi-message-talent.component.css']
})
export class MultiMessageTalentComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public searchContainerData;
  public condition = [];
  public conditions;
  public talents = null;
  public selectedTalentsSet = new Set();
  public showSpinner = true;
  public talentData;
  private messageId;
  private isSearching = false;
  private currentOffset = 0;
  private apiPath = '/multimessage';
  public config: PaginationInstance = {
    id: 'talents',
    itemsPerPage: 50,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private router: Router,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    // メッセージ詳細情報
    const message = JSON.parse(localStorage.getItem(Constant.lsMultiMessageDetail));
    if (!message) {
      // メッセージ情報なしの場合、一覧画面に戻す
      this.router.navigate(['/message']);
      return;
    }
    this.messageId = message.id;

    // 選択済みタレントID取得
    this.getSelectedTalents();

    // 除外タレントの条件設定
    const condition1 = {
      type: Constant.tlMultiMsgExceptType,
      id: 0
    };
    const condition2 = {
      type: Constant.tlTalentRegistType,
      id: message.talent_csv_flag
    };
    const condition3 = {
      type: Constant.tlDataStatusType,
      id: Constant.tlDataStatusTypeIdUnsubscribe
    };
    this.condition = [condition1, condition2, condition3];

    // 検索コンポーネント用
    this.searchContainerData = {
      condition: this.condition,
      conditions: this.conditions,
      msgExceptFlg: true,
      msgSelectTalentType: message.talent_csv_flag
    };

    // タレントリスト用
    this.talentData = {
      talents: this.talents,
      pagingConfig: this.config,
      checkFlg: true,
      allCheckFlg: false,
      listFlg: false,
    };
  }

  ngOnDestroy() {
    localStorage.removeItem(Constant.lsMultiMessageDetail);
  }

  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  // 検索コンポーネントより検索ボタン押下
  searchTalent(cond) {
    if (cond === true) {
      this.showSpinner = true;
    } else if (cond === false) {
      this.showSpinner = false;
    } else {
      this.condition = cond;
      this.talents = [];
      this.onSearch();
    }
  }

  // タレントリストコンポーネントよりタレントチェック
  onCheckTalent(idx) {
    if (this.talents[idx].selected) {
      this.selectedTalentsSet.add(this.talents[idx].id);
    } else {
      this.selectedTalentsSet.delete(this.talents[idx].id);
      this.talentData.allCheckFlg = false;
    }
  }

  onCheckTalentAll(check: boolean) {
    if (check) {
      this.talents.forEach(talent => {
        talent.selected = true;
        this.selectedTalentsSet.add(talent.id);
      });
    } else {
      this.talents.forEach(talent => {
        talent.selected = false;
        this.selectedTalentsSet.delete(talent.id);
      });
    }
  }

  // ページング
  getSelectedPageData(selectedPage) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    window.scroll(0, 0);
    this.currentOffset = (selectedPage - 1) * this.config.itemsPerPage;
    this.config.currentPage = selectedPage;
    this.getList();
  }

  onSearch() {
    this.showSpinner = true;

    // ページング
    this.currentOffset = 0;

    // 検索
    this.getList();
  }

  // タレントカルテ表示
  onShowTalentKarte(id: number) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    window.open('detail/' + id);
  }

  // キャンセル
  onCancel() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['/message']);
  }

  // 保存
  onSave() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {id: this.messageId, talents: Array.from(this.selectedTalentsSet)};

      this.commonUtil.apiPut(this.apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
        this.router.navigate(['/message']);
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        if (err.status === Constant.NG) {
          // 送信済みor削除済みの場合、一覧に戻る
          this.toastUtil.showErrorToast('', Constant.msgResultSaveFailed, Constant.toastShowMiliSec);
          this.router.navigate(['/message']);
        } else {
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
        }
      });
    });
  }

  /* API */
  private getSelectedTalents() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/multimessage/detail/' + this.messageId;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        // 選択済みタレントのSet作成
        if (res.data.talent_ids) {
          this.selectedTalentsSet = new Set(res.data.talent_ids.map(item => item.talent_id));
        } else {
          this.selectedTalentsSet = new Set();
        }
        // デフォルトチェック
        this.setDefaultCheck();
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
      });
    });

  }

  private getList(): void {
    // ネットワークチェック
    if (!this.commonUtil.checkOnline()) {
      this.showSpinner = false;
      this.isSearching = false;
      return;
    }

    if (this.isSearching) {
      return;
    }

    // ページング
    const pagingReqParam: TalentListPagingCondition = {
      offset: this.currentOffset,
      limit: this.config.itemsPerPage
    };

    this.isSearching = true;
    Auth.currentSession().then(session => {
      const apiPath = '/talentlist';
      const options = this.auth.createApiHeader(session);
      if (!this.condition) {
        this.condition = [];
      }
      options['body'] = {
        Conditions: this.condition,
        paging: pagingReqParam
      };

      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.commonUtil.debug().log(res.data);
        this.getListExec(res, options);
        this.isSearching = false;
      })
      .catch(err => {
        this.isSearching = false;
        this.showSpinner = false;
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  /* その他プラベート関数 */
  // リストAPI取得後の処理
  private getListExec(data, options) {
    this.talents = data.data.list;
    this.talentData.talents = this.talents;
    this.talentData.allCheckFlg = false;
    this.conditions = data.data.Conditions;
    this.searchContainerData.conditions = data.data.Conditions;
    this.setConfigData(data);

    if (data.data.list) {
      // 各タレントの画像URLを取得、デフォルトチェック
      setTimeout(() => {
        this.commonUtil.getTalentImg(data.data.list, options);
      });
      this.setDefaultCheck();
    }

    this.showSpinner = false;
  }

  // タレント選択状態の設定
  private setDefaultCheck() {
    if (!this.talents || this.selectedTalentsSet.size === 0) {
      return;
    }

    this.talents.forEach(talent => {
      // チェック状態の設定
      if (this.selectedTalentsSet.has(talent.id)) {
        talent.selected = true;
      } else {
        talent.selected = false;
      }
    });
  }

  // ページング用のデータを設定する
  private setConfigData(data: any) {
    this.config.totalItems = data.data.paging.total;
    this.config.currentPage = data.data.paging.offset / data.data.paging.limit + 1;
    this.config.itemsPerPage = data.data.paging.limit;
    this.talentData.pagingConfig = this.config;
  }

}
