<footer>
  <a class="logo" routerLink="/" (click)="onTopPage()">
    <img src="../../assets/lp/logo_blue.png" width="100">
  </a>
  <div class="flex menu">
    <a class="col" routerLink="/" (click)="onTopPage()">トップ</a>
    <a class="col" routerLink="/{{Constant.rtLpNewgrad}}" >新卒採用</a>
    <a class="col" routerLink="/{{Constant.rtLpCareer}}" >中途採用</a>
    <a class="col" routerLink="/case" >活用例</a>
    <a class="col" routerLink="/faq" >FAQ</a>
    <a class="col" href="{{Constant.footerUrlContact}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtContact)">お問い合わせ <img src="../../assets/lp/launch_gray.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlAgreement}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtAgreement)">利用規約 <img src="../../assets/lp/launch_gray.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlPrivacy}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtPrivacy)">プライバシーポリシー <img src="../../assets/lp/launch_gray.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlCalin}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtCalin)">運営企業 <img src="../../assets/lp/launch_gray.png" width="13" height="13"></a>
    <a class="col" href="{{Constant.footerUrlTalent}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionFtTalentLP)">候補者の方はこちら  <img src="../../assets/lp/launch_gray.png" width="13" height="13"></a>
  </div>
  <div class="copyright">©CaLin, Inc.</div>
</footer>
