import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastUtil {
  constructor(private toastr: ToastrService) {}

  // エラーのトーストを表示する
  public showErrorToast(title, text, timeout): void {
    this.toastr.error(text, title, {
      closeButton: false,
      timeOut: timeout,
      enableHtml: true,
      positionClass: 'toast-top-center'
    });
  }
  public showInformationToast(title, text, timeout): void {
    this.toastr.info(text, title, {
      closeButton: false,
      timeOut: timeout,
      enableHtml: true,
      positionClass: 'toast-top-center'
    });
  }
  public clearAllShowingToast() {
    this.toastr.clear();
  }
}
