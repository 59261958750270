import { Component, OnInit, Renderer2, Inject, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './auth/auth.service';
import { Constant } from './constant';
import { CommonUtil } from './util/common-util';
import { Meta } from '@angular/platform-browser';
import { environment } from './../environments/environment';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  public calinTellNo = Constant.calinTellNo;
  public calinWorkTime = Constant.calinWorkTime;
  public calinSupportMail = Constant.calinSupportMail;
  lp = false;
  menuDisabled = false;
  public newsDate = '';
  public readonly Constant = Constant;

  constructor(
    public auth: AuthService,
    private commonUtil: CommonUtil,
    private router: Router,
    private meta: Meta,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    // 単独ページ判断（LP、エラー）
    this.router.events.forEach((event: NavigationEvent) => {
      // NavigationEnd
      if (event instanceof NavigationEnd) {
        if (
          event.urlAfterRedirects === '/' ||
          event.urlAfterRedirects === '/error' ||
          event.urlAfterRedirects === '/maintenance' ||
          event.urlAfterRedirects.indexOf(Constant.rtLpNewgrad) >= 0 ||
          event.urlAfterRedirects.indexOf(Constant.rtLpCareer) >= 0 ||
          event.urlAfterRedirects === '/faq' ||
          event.urlAfterRedirects.indexOf(Constant.rtCase) >= 0 ||
          event.urlAfterRedirects.indexOf(Constant.rtRequest) >= 0 ||
          event.urlAfterRedirects === '/' + Constant.rtDownload ||
          event.urlAfterRedirects.indexOf(Constant.rtMsgFile) >= 0 ||
          event.urlAfterRedirects.indexOf(Constant.rtTmpFile) >= 0 ||
          event.urlAfterRedirects.indexOf(Constant.rtAttachment) >= 0 ||
          event.url.indexOf('#') > -1 ||
          event.url.indexOf('/?') > -1
        ) {
          this.lp = true;
        } else {
          this.lp = false;
        }

        // サイドメニュー非表示
        // 一斉メッセージ関連、タレントカルテ、質問管理、コンテンツカテゴリー
        if (event.urlAfterRedirects.indexOf('/message_') >= 0 ||
            event.urlAfterRedirects.indexOf('/detail') >= 0 ||
            event.urlAfterRedirects.indexOf('/question') >= 0 ||
            event.urlAfterRedirects.indexOf(Constant.rtPrContentsCategory) >= 0 ||
            event.urlAfterRedirects.indexOf('/import') >= 0) {
          this.menuDisabled = true;
        } else {
          this.menuDisabled = false;
        }

        // noindex
        if ( environment.production && (
          event.urlAfterRedirects === '/' ||
          event.urlAfterRedirects.indexOf(Constant.rtLpNewgrad) >= 0 ||
          event.urlAfterRedirects.indexOf(Constant.rtLpCareer) >= 0 ||
          event.urlAfterRedirects === '/request/whitepaper' ||
          event.urlAfterRedirects === '/request/account' ||
          event.urlAfterRedirects === '/faq' ||
          event.urlAfterRedirects.indexOf(Constant.rtCase) >= 0)
        ) {
          this.meta.updateTag({ name: 'robots', content: 'index,follow' });
        } else {
          this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' });
        }

        // description
        this.meta.updateTag({name: 'description', content: Constant.pageDescriptionRoot});

        // お知らせの最新日付を取得
        if (!this.lp) {
          this.getNews();
        }

        // LP、ログイン画面以外の場合、アクセス日時を登録
        if (!this.lp && event.urlAfterRedirects.indexOf('/login/') === -1) {
          this.putLastAccessDate();
        }

        //　フォーカスを外す
        const elm = <HTMLElement>document.activeElement;
        elm.blur();
      }
    });

    // アナリティクストラッキングID
    gtag('js', new Date());
    gtag('config', environment.AnalyticsTrackingId);
  }

  ngAfterViewInit(): void {
    // google font 読み込み
    const link = this.renderer.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    this.renderer.appendChild(this.document.head, link);
  }

  onTalentList() {
    // タレントリスト遷移時、検索条件をクリアする
    localStorage.removeItem(Constant.lsTalentListCondition);
  }

  onRequestForCaLin() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionRequest);

    window.open(Constant.requestForCalinUrl);
  }

  onCalinInformation() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionNews);

    window.open(Constant.calinInformationUrl);
  }

  onAgreement() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionAgreement);

    window.open(Constant.footerUrlAgreement);
  }

  onContact() {
    window.open(Constant.footerUrlContact);
  }

  onCalin() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompany);

    window.open(Constant.footerUrlCalin);
  }

  onPrivacy() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPolicy);

    window.open(Constant.footerUrlPrivacy);
  }

  // お知らせ最新日時を取得
  private getNews() {
    const baseDatetime = this.commonUtil.createCurrentDateString('', 'YYYY/MM/DD hh:mm:ss');
    const options = this.auth.createApiHeaderBeforeLoginForCorp();
    let apiPath = '/news?BaseDateTime=' + baseDatetime;
    apiPath = apiPath + '&LpShowFlag=1';

    this.commonUtil.apiGetForCorp(apiPath, options).then(res => {
      if (res.data[0]) {
        this.newsDate = res.data[0].ShowDateFormat;
      }
    })
    .catch(err => {
      this.commonUtil.debug().log(err);
    });
  }

  // 最終アクセス日時の記録
  private putLastAccessDate() {
    Auth.currentSession().then(session => {
      const apiPath = '/access/lastdate';
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {})
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    });
  }
}
