<mat-dialog-content>
  <div class="title" *ngIf="mode==Constant.csvTalentTypeAdd">タレントカルテ 新規作成</div>
  <div class="title" *ngIf="mode==Constant.csvTalentTypeEdit">タレントカルテ 編集</div>
  <div *ngIf="showSpinner" class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  <form [formGroup]="form" *ngIf="!showSpinner">
  <div class="title-sub">基本情報</div>
  <div class="content flex">
    <div class="column">
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 名前</span>
          <span class="required">{{ Constant.prefixRequire }}</span>
          <span class="desc">※下段はひらがな入力</span>
        </div>
        <div class="flex indent">
          <div class="name">
            <div class="label-count" [class.length-red]="lastName.value.length>maxLenName">{{ lastName.value.length }}/{{ maxLenName }}</div>
            <input
              type="text"
              class="input-text"
              [class.red]="form.get(Constant.apLastName).invalid && form.get(Constant.apLastName).dirty"
              [formControlName]="Constant.apLastName"
              #lastName
              placeholder="姓"
              [maxlength]="maxLenName"
              required
              (keydown.enter)="onKeyDown($event)"
            />
          </div>
          <div class="name">
            <div class="label-count" [class.length-red]="firstName.value.length>maxLenName">{{ firstName.value.length }}/{{ maxLenName }}</div>
            <input
              type="text"
              class="input-text"
              [class.red]="form.get(Constant.apFirstName).invalid && form.get(Constant.apFirstName).dirty"
              [formControlName]="Constant.apFirstName"
              #firstName
              placeholder="名"
              [maxlength]="maxLenName"
              (keydown.enter)="onKeyDown($event)"
            />
          </div>
        </div>
        <div class="flex indent">
          <div class="name">
            <div class="label-count" [class.length-red]="lastNameKana.value.length>maxLenName">{{ lastNameKana.value.length }}/{{ maxLenName }}</div>
            <input
              type="text"
              class="input-text"
              [class.red]="form.get(Constant.apLastNameKana).invalid && form.get(Constant.apLastNameKana).dirty"
              [formControlName]="Constant.apLastNameKana"
              #lastNameKana
              name="lastNameKana"
              placeholder="姓：ひらがな"
              [maxlength]="maxLenName"
              (keydown.enter)="onKeyDown($event)"
            />
          </div>
          <div class="name">
            <div class="label-count" [class.length-red]="firstNameKana.value.length>maxLenName">{{ firstNameKana.value.length }}/{{ maxLenName }}</div>
            <input
              type="text"
              class="input-text"
              [class.red]="form.get(Constant.apFirstNameKana).invalid && form.get(Constant.apFirstNameKana).dirty"
              [formControlName]="Constant.apFirstNameKana"
              #firstNameKana
              placeholder="名：ひらがな"
              [maxlength]="maxLenName"
              (keydown.enter)="onKeyDown($event)"
            />
          </div>
        </div>
        <mat-error *ngIf="(form.get(Constant.apLastNameKana).invalid && form.get(Constant.apLastNameKana).dirty) || form.get(Constant.apFirstNameKana).invalid && form.get(Constant.apFirstNameKana).dirty"
        class="indent">
          <ng-container *ngIf="form.get(Constant.apLastNameKana).errors?.pattern || form.get(Constant.apFirstNameKana).errors?.pattern">ひらがなで入力してください。</ng-container>
        </mat-error>
      </div>
      <!-- 電話番号 -->
      <div class="input-container tel">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 電話番号</span>
          <span class="desc">※半角数字・ハイフンなし</span>
        </div>
        <div class="indent tel">
          <div class="label-count" [class.length-red]="telno.value.length>maxLenTel">{{ telno.value.length }}/{{ maxLenTel }}</div>
          <input
            class="input-text"
            [formControlName]="Constant.apTelno"
            #telno
            placeholder="08012345678"
            maxlength="11"
            type="tel"
            (keydown.enter)="onKeyDown($event)"
          />
        </div>
        <mat-error *ngIf="form.get(Constant.apTelno).invalid && form.get(Constant.apTelno).dirty" class="indent">{{ Constant.msgErrorInvalidType }}
        </mat-error>
      </div>
      <!-- メールアドレス -->
      <div class="input-container middle">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> メールアドレス</span>
          <span class="required">{{ Constant.prefixRequire }}</span>
          <span class="desc">※半角</span>
        </div>
        <div class="indent">
          <div class="label-count" [class.length-red]="email.value.length>maxLenMail">{{ email.value.length }}/{{ maxLenMail }}</div>
          <input
            class="input-text"
            [class.red]="form.get(Constant.apMailAddress).invalid && form.get(Constant.apMailAddress).dirty"
            [formControlName]="Constant.apMailAddress"
            #email
            placeholder="support@calin.co.jp"
            [maxlength]="maxLenMail"
            type="text"
            (keydown.enter)="onKeyDown($event)"
          />
          <mat-error *ngIf="form.get(Constant.apMailAddress).invalid && form.get(Constant.apMailAddress).dirty">{{ Constant.msgErrorInvalidType }}
          </mat-error>
        </div>
      </div>
    </div>
    <div class="column right">
      <!-- 性別 -->
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 性別</span>
        </div>
        <div class="indent-radio">
          <mat-radio-group [formControlName]="Constant.apSexId">
          <mat-radio-button *ngFor="let item of master[Constant.tlSexType]" color="primary"
            [value]="item.id" (click)="onRadio(item.id, form, Constant.apSexId, $event)">{{ item.item_value }}性</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- 生年月日 -->
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 生年月日</span>
          <span class="desc">{{ Constant.prefixOnlyNumberAD }}</span>
        </div>
        <div class="indent margintop">
          <input
            type="tel"
            class="input-text year"
            formControlName="birthday_year"
            placeholder="1990"
            [min]="Constant.fvMinYear"
            [max]="Constant.fvMaxYear"
            maxlength="4"
            (keydown.enter)="onKeyDown($event)"
            (blur)="onBlurBirthday()"
          />年
          <input
            type="tel"
            class="input-text month"
            formControlName="birthday_month"
            placeholder="10"
            [min]="Constant.fvMinMonth"
            [max]="Constant.fvMaxMonth"
            maxlength="2"
            (keydown.enter)="onKeyDown($event)"
            (blur)="onBlurBirthday()"
          />月
          <input
            type="tel"
            class="input-text month"
            formControlName="birthday_day"
            placeholder="1"
            [min]="Constant.fvMinDay"
            [max]="Constant.fvMaxDay"
            maxlength="2"
            (keydown.enter)="onKeyDown($event)"
            (blur)="onBlurBirthday()"
          />日

        </div>
        <mat-error *ngIf="errorMessage[Constant.apBirthday]" class="indent">{{ errorMessage[Constant.apBirthday] }}
        </mat-error>
        <mat-error *ngIf="!errorMessage[Constant.apBirthday] && (
          (form.get(Constant.apBirthdayYear).invalid && form.get(Constant.apBirthdayYear).dirty) ||
        (form.get(Constant.apBirthdayMonth).invalid && form.get(Constant.apBirthdayMonth).dirty) ||
        (form.get(Constant.apBirthdayDay).invalid && form.get(Constant.apBirthdayDay).dirty) )" class="indent">{{ Constant.msgErrorInvalidType }}
        </mat-error>
      </div>
      <!-- 居住地 -->
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 居住地</span>
        </div>
        <div class="indent flex top">
          <div class="place-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select
                formControlName="address1"
                placeholder="都道府県"
                (selectionChange) = "onBlurCity()"
              >
                <mat-option *ngFor="let place of master['DESIRE_JOB_PLACE_TYPE']" [value]="place.item_value">
                  {{ place.item_value }}
                </mat-option>
                <mat-option value="">
                  未登録
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="place-city-area">
            <div class="label-count" [class.length-red]="placecity.value.length>maxLenPlace">{{ placecity.value.length }}/{{ maxLenPlace }}</div>
            <input
              class="input-text place-city"
              formControlName="address2"
              #placecity
              placeholder="市区町村"
              type="text"
              [maxlength]="maxLenPlace"
              (blur)="onBlurCity()"
            />
          </div>
        </div>
        <mat-error *ngIf="errorMessage[Constant.apAddress1]" class="indent">{{ errorMessage[Constant.apAddress1] }}
        </mat-error>
      </div>

      <!-- 卒業年 -->
      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          />
          <span class="font-bold"> 新卒採用枠（卒業年）</span>
        </div>
        <div class="indent">
          <div class="select-area">
            <mat-form-field floatLabel="never" class="select-value graduate">
              <mat-select
                [formControlName]="Constant.apGraduateYearType"
                placeholder="選択"
              >
                <mat-option *ngFor="let item of master['GRADUATE_YEAR_TYPE']" [value]="item.id">
                  {{ item.item_value }}
                </mat-option>
                <mat-option value="">
                  未登録
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div class="content">
    <!-- メモ -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span class="font-bold"> メモ</span>
      </div>
      <div class="indent memo">
        <div class="label-count" [class.length-red]="memotalent.value.length>Constant.fvMaxMemo">{{ memotalent.value.length }}/{{ Constant.fvMaxMemo }}</div>
        <textarea class="textarea" matInput rows="8" [formControlName]="Constant.apCommentTalent"
        #memotalent placeholder="メモ"></textarea>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="title-sub">希望情報</div>
  <div class="content">
    <!-- 活動予定時期 -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span class="font-bold"> 就業/転職 活動予定時期</span>
        <span class="desc">{{Constant.prefixOnlyNumberAD}}</span>
      </div>
      <div class="indent margintop flex">
        <div>
        <input
          type="tel"
          class="input-text year"
          [formControlName]="Constant.tfStartYear"
          placeholder="2023"
          [min]="Constant.fvMinYear"
          [max]="Constant.fvMaxYear"
          maxlength="4"
          (keydown.enter)="onKeyDown($event)"
          (blur)="onBlurActiveTerm()"
        />年
        <input
          type="tel"
          class="input-text month"
          [formControlName]="Constant.tfStartMonth"
          placeholder="4"
          [min]="Constant.fvMinMonth"
          [max]="Constant.fvMaxMonth"
          maxlength="2"
          (keydown.enter)="onKeyDown($event)"
          (blur)="onBlurActiveTerm()"
        />月　〜
        <input
          type="tel"
          class="input-text year left"
          [formControlName]="Constant.tfEndYear"
          placeholder="2024"
          [min]="Constant.fvMinYear"
          [max]="Constant.fvMaxYear"
          maxlength="4"
          (keydown.enter)="onKeyDown($event)"
          (blur)="onBlurActiveTerm()"
        />年
        <input
          type="tel"
          class="input-text month"
          [formControlName]="Constant.tfEndMonth"
          placeholder="3"
          [min]="Constant.fvMinMonth"
          [max]="Constant.fvMaxMonth"
          maxlength="2"
          (keydown.enter)="onKeyDown($event)"
          (blur)="onBlurActiveTerm()"
        />月
        </div>
        <div class="marginleft">
          <mat-checkbox labelPosition="before" color="primary" [formControlName]="Constant.apActiveUndecidedFlag" class="gray"
              (change)="onChangeUndecided()">未定の場合はこちらにチェック</mat-checkbox>
        </div>
      </div>

      <mat-error *ngIf="errorMessage[Constant.apActivePlannedStart]" class="indent">{{ errorMessage[Constant.apActivePlannedStart] }}
      </mat-error>
      <mat-error *ngIf="!errorMessage[Constant.apActivePlannedStart] && (
      (form.get(Constant.tfStartYear).invalid && form.get(Constant.tfStartYear).dirty) ||
      (form.get(Constant.tfStartMonth).invalid && form.get(Constant.tfStartMonth).dirty) ||
      (form.get(Constant.tfEndYear).invalid && form.get(Constant.tfEndYear).dirty) ||
      (form.get(Constant.tfEndMonth).invalid && form.get(Constant.tfEndMonth).dirty) )" class="indent">{{ Constant.msgErrorInvalidType }}
      </mat-error>
    </div>

    <!-- 希望雇用形態 -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span class="font-bold"> 希望 雇用形態</span>
      </div>
      <div class="indent margintop">
        <mat-checkbox color="primary" *ngFor="let item of master[Constant.joEmploymentType]"
          formControlName="desire_employeement_{{ item.id }}"
          class="button-type">
          {{ item[Constant.apItemValue] }}
        </mat-checkbox>
      </div>
    </div>

    <!-- 希望勤務地 -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x">
        <span class="font-bold"> 希望 勤務地</span>
      </div>
      <div class="indent pref">
        <mat-checkbox color="primary" *ngFor="let item of master[Constant.joJobPlaceType]"
          formControlName="desirejobplace_{{ item.id }}"
          class="button-type">
          {{ item[Constant.apItemValue] }}
        </mat-checkbox>
      </div>
    </div>

    <!-- 希望職種 -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span class="font-bold"> 希望 職種</span>
      </div>
      <div class="indent flex job">
        <div class="col">
          <ng-container *ngFor="let item of jobMaster; let i = index;">
            <div *ngIf="i % 2 == 0" class="job-select">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  {{ item[Constant.apLargeItemValue] }}
                </mat-expansion-panel-header>
                <div class="item">
                  <mat-checkbox color="primary" class="job-large"
                    formControlName="desirejob_{{ item[Constant.apLargeId] }}"
                    (change)="onLargeId($event.checked, item[Constant.apLargeId], 'desirejob_', form)"
                    >{{ item[Constant.apLargeItemValue] }}全て</mat-checkbox
                  >
                </div>
                <div class="item" *ngFor="let middle of item.middle">
                  <mat-checkbox color="primary"
                  formControlName="desirejob_{{ item[Constant.apLargeId] }}_{{ middle.id }}"
                  (change)="onMiddleId(item[Constant.apLargeId], 'desirejob_', form)"
                  >{{
                    middle[Constant.apItemValue]
                  }}</mat-checkbox>
                </div>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </div>
        <div class="col">
          <ng-container *ngFor="let item of jobMaster; let i = index;">
            <div *ngIf="i % 2 == 1" class="job-select">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  {{ item[Constant.apLargeItemValue] }}
                </mat-expansion-panel-header>
                <div class="item">
                  <mat-checkbox color="primary" class="job-large"
                    formControlName="desirejob_{{ item[Constant.apLargeId] }}"
                    (change)="onLargeId($event.checked, item[Constant.apLargeId], 'desirejob_', form)"
                    >{{ item[Constant.apLargeItemValue] }}全て</mat-checkbox
                  >
                </div>
                <div class="item" *ngFor="let middle of item.middle">
                  <mat-checkbox color="primary"
                  formControlName="desirejob_{{ item[Constant.apLargeId] }}_{{ middle.id }}"
                  (change)="onMiddleId(item[Constant.apLargeId], 'desirejob_', form)"
                  >{{
                    middle[Constant.apItemValue]
                  }}</mat-checkbox>
                </div>
              </mat-expansion-panel>
            </div>
          </ng-container>
          <div class="job-select undecide">
            <div class="right">
              <mat-checkbox labelPosition="before" color="primary" [formControlName]="Constant.apDesirejobUndecidedFlag" class="gray"
                  (change)="onChangeUndecidedJob()">未定の場合はこちらにチェック</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="title-sub">職歴情報</div>

  <div class="content">
    <!-- 職歴登録フォーム -->
    <ng-container formArrayName="careers">
      <ng-container *ngFor="let val of careers.controls; let i = index;">
        <ng-container formGroupName="{{ i }}">
          <div class="flex title-history">
            <div class="">職歴({{ val.get(Constant.apOrderNo).value }})</div>
            <div class="del-btn">
              <button mat-raised-button type="button" (click)="onDelCareer(i, val.get(Constant.apOrderNo).value)">
                削除
              </button>
            </div>
          </div>

          <!-- 企業名 -->
          <div class="input-container margintop middle">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 企業名</span>
              <span class="required">{{ Constant.prefixRequire }}</span>
            </div>
            <div class="indent career-name">
              <div class="label-count" [class.length-red]="companyName.value.length>Constant.fvMaxCompanyName">{{ companyName.value.length }}/{{ Constant.fvMaxCompanyName }}</div>
              <input
                class="input-text"
                [class.red]="val.get(Constant.apCompanyName).invalid && val.get(Constant.apCompanyName).dirty"
                [formControlName]="Constant.apCompanyName"
                #companyName
                placeholder="キャリン株式会社"
                [maxlength]="Constant.fvMaxCompanyName"
                type="text"
                (keydown.enter)="onKeyDown($event)"
              />
            </div>
          </div>

          <!-- 期間 -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 期間</span>
              <span class="required">{{ Constant.prefixRequire }}</span>
              <span class="desc">{{Constant.prefixOnlyNumberAD}}</span>
            </div>
            <div class="indent margintop flex">
              <div>
              <input
                class="input-text year"
                [formControlName]="Constant.tfStartYear"
                placeholder="2023"
                min="{{ Constant.fvMinYear }}"
                type="tel"
                required
                #startyear
                (keydown.enter)="onKeyDown($event)"
                (blur)="onCareerTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)"
                maxlength="4" /> 年
              <input
                class="input-text month"
                [formControlName]="Constant.tfStartMonth"
                placeholder="4"
                min="{{ Constant.fvMinMonth }}"
                max="{{ Constant.fvMaxMonth }}"
                type="tel"
                required
                #startmonth
                (keydown.enter)="onKeyDown($event)"
                (blur)="onCareerTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)"
                maxlength="2" /> 月　〜
              <input
                class="input-text year left"
                [formControlName]="Constant.tfEndYear"
                placeholder="2024"
                min="{{ Constant.fvMinYear }}"
                type="tel"
                #endyear
                (keydown.enter)="onKeyDown($event)"
                (blur)="onCareerTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)"
                maxlength="4" /> 年
              <input
                class="input-text month"
                [formControlName]="Constant.tfEndMonth"
                placeholder="3"
                min="{{ Constant.fvMinMonth }}"
                max="{{ Constant.fvMaxMonth }}"
                type="tel"
                #endmonth
                maxlength="2"
                (keydown.enter)="onKeyDown($event)"
                (blur)="onCareerTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)" /> 月
              </div>
              <div class="gray marginleft">※継続中の場合は、期末を入力しないでください。</div>

            </div>
            <mat-error *ngIf="errorMessage[Constant.apJoinDate + '-' + i]" class="indent">{{ errorMessage[Constant.apJoinDate + '-' + i] }}
            </mat-error>
            <mat-error *ngIf="!errorMessage[Constant.apJoinDate + '-' + i]
            && ((val.get(Constant.tfStartYear).invalid && val.get(Constant.tfStartYear).dirty)
            || (val.get(Constant.tfStartMonth).invalid && val.get(Constant.tfStartMonth).dirty)
            || (val.get(Constant.tfEndYear).invalid && val.get(Constant.tfEndYear).dirty)
            || (val.get(Constant.tfEndMonth).invalid && val.get(Constant.tfEndMonth).dirty))" class="indent">{{ Constant.msgErrorInvalidType }}
            </mat-error>

          </div>

          <!-- 雇用形態 -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 雇用形態</span>
            </div>
            <div class="indent-radio">
              <mat-radio-group [formControlName]="Constant.apEmployeementTypeId">
                <mat-radio-button *ngFor="let item of master[Constant.joEmploymentType]" color="primary"
                  [value]="item.id" (click)="onRadio(item.id, careers.controls[i], Constant.apEmployeementTypeId, $event)"
                >{{ item[Constant.apItemValue] }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <!-- 年収 -->
          <div class="input-container flex">
            <div class="col income">
              <div class="label">
                <img
                  src="assets/notice/mark_blue.png"
                  srcset="
                    assets/notice/mark_blue.png    1x,
                    assets/notice/mark_blue@2x.png 2x,
                    assets/notice/mark_blue@3x.svg 3x
                  "
                />
                <span class="font-bold"> 年収</span>
                <span class="desc">{{ Constant.prefixOnlyNumber }}</span>
              </div>
              <div class="indent">
                <div class="label-count income" [class.length-red]="income.value.length>Constant.fvMaxLenIncome">{{ income.value.length }}/{{ Constant.fvMaxLenIncome }}</div>
                <input
                  class="input-text year"
                  [formControlName]="Constant.apAnnualIncome"
                  #income
                  placeholder="500"
                  [maxlength]="Constant.fvMaxLenIncome"
                  type="text"
                  (keydown.enter)="onKeyDown($event)"
                /> 万円
              </div>
              <mat-error *ngIf="val.get(Constant.apAnnualIncome).invalid && val.get(Constant.apAnnualIncome).dirty" class="indent">{{ Constant.msgErrorInvalidType }}
              </mat-error>
            </div>

            <!-- 役職 -->
            <div class="col">
              <div class="label">
                <img
                  src="assets/notice/mark_blue.png"
                  srcset="
                    assets/notice/mark_blue.png    1x,
                    assets/notice/mark_blue@2x.png 2x,
                    assets/notice/mark_blue@3x.svg 3x
                  "
                />
                <span class="font-bold"> 役職</span>
              </div>
              <div class="middle2">
                <div class="label-count" [class.length-red]="position.value.length>Constant.fvMaxCompanyName">{{ position.value.length }}/{{ Constant.fvMaxCompanyName }}</div>
                <input
                  class="input-text"
                  [formControlName]="Constant.apPosition"
                  #position
                  [maxlength]="Constant.fvMaxCompanyName"
                  type="text"
                  (keydown.enter)="onKeyDown($event)"
                />
              </div>
            </div>

            <!-- 部署 -->
            <div>
              <div class="label">
                <img
                  src="assets/notice/mark_blue.png"
                  srcset="
                    assets/notice/mark_blue.png    1x,
                    assets/notice/mark_blue@2x.png 2x,
                    assets/notice/mark_blue@3x.svg 3x
                  "
                />
                <span class="font-bold"> 部署</span>
              </div>
              <div class="middle2">
                <div class="label-count" [class.length-red]="department.value.length>Constant.fvMaxCompanyName">{{ department.value.length }}/{{ Constant.fvMaxCompanyName }}</div>
                <input
                  class="input-text"
                  [formControlName]="Constant.apDepartment"
                  #department
                  [maxlength]="Constant.fvMaxCompanyName"
                  type="text"
                  (keydown.enter)="onKeyDown($event)"
                />
              </div>
            </div>
          </div>

          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 主な職種</span>
            </div>
            <div class="indent flex job">
              <div class="col">
                <ng-container *ngFor="let item of jobMaster; let job_i = index;">
                  <div *ngIf="job_i % 2 == 0" class="job-select">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        {{ item[Constant.apLargeItemValue] }}
                      </mat-expansion-panel-header>
                      <div class="item">
                        <mat-checkbox color="primary" class="job-large"
                        formControlName="careerjob_{{ item[Constant.apLargeId] }}"
                        (change)="onLargeId($event.checked, item[Constant.apLargeId], 'careerjob_', careers.controls[i])"
                          >{{ item[Constant.apLargeItemValue] }}全て</mat-checkbox
                        >
                      </div>
                      <div class="item" *ngFor="let middle of item.middle">
                        <mat-checkbox color="primary"
                        formControlName="careerjob_{{ item[Constant.apLargeId] }}_{{ middle.id }}"
                        (change)="onMiddleId(item[Constant.apLargeId], 'careerjob_', careers.controls[i])">{{
                          middle[Constant.apItemValue]
                        }}</mat-checkbox>
                      </div>
                    </mat-expansion-panel>
                  </div>
                </ng-container>
              </div>
              <div class="col">
                <ng-container *ngFor="let item of jobMaster; let job_i = index;">
                  <div *ngIf="job_i % 2 == 1" class="job-select">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        {{ item[Constant.apLargeItemValue] }}
                      </mat-expansion-panel-header>
                      <div class="item">
                        <mat-checkbox color="primary" class="job-large"
                        formControlName="careerjob_{{ item[Constant.apLargeId] }}"
                        (change)="onLargeId($event.checked, item[Constant.apLargeId], 'careerjob_', careers.controls[i])"
                          >{{ item[Constant.apLargeItemValue] }}全て</mat-checkbox
                        >
                      </div>
                      <div class="item" *ngFor="let middle of item.middle">
                        <mat-checkbox color="primary"
                        formControlName="careerjob_{{ item[Constant.apLargeId] }}_{{ middle.id }}"
                        (change)="onMiddleId(item[Constant.apLargeId], 'careerjob_', careers.controls[i])">{{
                          middle[Constant.apItemValue]
                        }}</mat-checkbox>
                      </div>
                    </mat-expansion-panel>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- 主な活動 -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 主な活動</span>
            </div>
            <div class="indent career-memo">
              <div class="label-count" [class.length-red]="jobdetail.value.length>Constant.fvMaxLenCompanyDetail">{{ jobdetail.value.length }}/{{ Constant.fvMaxLenCompanyDetail }}</div>
              <textarea class="textarea" matInput rows="5" [formControlName]="Constant.apJobDetail"
              #jobdetail placeholder=""></textarea>
            </div>
          </div>

          <!-- 活かしたスキル -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 活かしたスキル</span>
            </div>
            <div class="indent career-memo">
              <div class="label-count" [class.length-red]="skill.value.length>Constant.fvMaxLenCompanyDetail">{{ skill.value.length }}/{{ Constant.fvMaxLenCompanyDetail }}</div>
              <textarea class="textarea" matInput rows="5" [formControlName]="Constant.apSkill"
              #skill placeholder=""></textarea>
            </div>
          </div>

        </ng-container>

        <mat-divider class="narrow"></mat-divider>

      </ng-container>

    </ng-container>
    <div class="form-btn-area" *ngIf="careers.controls.length < maxWorkCareer">
      <button mat-raised-button type="button" (click)="onAddCareer()">
        <ng-container *ngIf="careers.controls.length == 0">職歴をフォームから登録する</ng-container>
        <ng-container *ngIf="careers.controls.length > 0">職歴を追加する</ng-container>
      </button>
    </div>

    <!-- 職歴メモ -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span class="font-bold"> 職歴メモ</span>
      </div>
      <div class="indent memo">
        <div class="label-count" [class.length-red]="memocareer.value.length>Constant.fvMaxMemo">{{ memocareer.value.length }}/{{ Constant.fvMaxMemo }}</div>
        <textarea class="textarea" matInput rows="8" [formControlName]="Constant.apCommentCareer"
        #memocareer placeholder="職歴メモ"></textarea>
      </div>
    </div>

  </div>

  <mat-divider></mat-divider>

  <div class="title-sub">学歴情報</div>

  <div class="content">
    <!-- 学歴フォーム -->
    <ng-container formArrayName="schools">
      <ng-container *ngFor="let val of schools.controls; let i = index;">
        <ng-container formGroupName="{{ i }}">
          <div class="flex title-history">
            <div class="">学歴({{ val.get(Constant.apOrderNo).value }})</div>
            <div class="del-btn">
              <button mat-raised-button type="button" (click)="onDelSchool(i, val.get(Constant.apOrderNo).value)">
                削除
              </button>
            </div>
          </div>

          <!-- 学校名 -->
          <div class="input-container middle margintop">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 学校名</span>
              <span class="required">{{ Constant.prefixRequire }}</span>
            </div>
            <div class="indent career-name">
              <div class="label-count" [class.length-red]="schoolName.value.length>Constant.fvMaxCompanyName">{{ schoolName.value.length }}/{{ Constant.fvMaxCompanyName }}</div>
              <input
                class="input-text"
                [class.red]="val.get(Constant.apSchoolName).invalid && val.get(Constant.apSchoolName).dirty"
                [formControlName]="Constant.apSchoolName"
                #schoolName
                required
                [maxlength]="Constant.fvMaxCompanyName"
                type="text"
                (keydown.enter)="onKeyDown($event)"
              />
            </div>
          </div>

          <!-- 学校区分 -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 学校区分</span>
            </div>
            <div class="indent-radio schooltype margintop">
              <mat-radio-group [formControlName]="Constant.apSchoolTypeId">
              <mat-radio-button *ngFor="let item of master[Constant.tlSchoolType]" color="primary"
                [value]="item.id" (click)="onRadio(item.id, schools.controls[i], Constant.apSchoolTypeId, $event)"
              >{{ item.item_value }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <!-- 期間 -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 期間</span>
              <span class="required">{{ Constant.prefixRequire }}</span>
              <span class="desc">{{ Constant.prefixOnlyNumberAD }}</span>
            </div>
            <div class="indent margintop flex">
              <div>
              <input
                class="input-text year"
                [formControlName]="Constant.tfStartYear"
                placeholder="2023"
                min="{{ Constant.fvMinYear }}"
                type="tel"
                #startyear
                (keydown.enter)="onKeyDown($event)"
                (blur)="onSchoolTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)"
                maxlength="4" /> 年
              <input
                class="input-text month"
                [formControlName]="Constant.tfStartMonth"
                placeholder="4"
                min="{{ Constant.fvMinMonth }}"
                max="{{ Constant.fvMaxMonth }}"
                type="tel"
                #startmonth
                (keydown.enter)="onKeyDown($event)"
                (blur)="onSchoolTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)"
                maxlength="2" /> 月　〜
              <input
                class="input-text year left"
                [formControlName]="Constant.tfEndYear"
                placeholder="2024"
                min="{{ Constant.fvMinYear }}"
                type="tel"
                #endyear
                (keydown.enter)="onKeyDown($event)"
                (blur)="onSchoolTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)"
                maxlength="4" /> 年
              <input
                class="input-text month"
                [formControlName]="Constant.tfEndMonth"
                placeholder="3"
                min="{{ Constant.fvMinMonth }}"
                max="{{ Constant.fvMaxMonth }}"
                type="tel"
                #endmonth
                maxlength="2"
                (keydown.enter)="onKeyDown($event)"
                (blur)="onSchoolTerm(startyear.value, startmonth.value, endyear.value, endmonth.value, i)" /> 月
              </div>
              <div class="gray marginleft">※在学中の場合は卒業予定を記入してください。</div>

            </div>

            <mat-error *ngIf="errorMessage[Constant.apAdmissionDate + '-' + i]" class="indent">{{ errorMessage[Constant.apAdmissionDate + '-' + i] }}
            </mat-error>
            <mat-error *ngIf="!errorMessage[Constant.apAdmissionDate + '-' + i]
            && ((val.get(Constant.tfStartYear).invalid && val.get(Constant.tfStartYear).dirty)
            || (val.get(Constant.tfStartMonth).invalid && val.get(Constant.tfStartMonth).dirty)
            || (val.get(Constant.tfEndYear).invalid && val.get(Constant.tfEndYear).dirty)
            || (val.get(Constant.tfEndMonth).invalid && val.get(Constant.tfEndMonth).dirty))" class="indent">{{ Constant.msgErrorInvalidType }}
            </mat-error>

            <div class="indent desc2">
              <mat-checkbox color="primary" labelPosition="before" [formControlName]="Constant.apDropout" class="gray">※中退・転学の場合はこちらをチェック</mat-checkbox>
            </div>

          </div>

          <div class="input-container flex">
            <!-- 文理 -->
            <div class="col2">
              <div class="label">
                <img
                  src="assets/notice/mark_blue.png"
                  srcset="
                    assets/notice/mark_blue.png    1x,
                    assets/notice/mark_blue@2x.png 2x,
                    assets/notice/mark_blue@3x.svg 3x
                  "
                />
                <span class="font-bold"> 文理</span>
              </div>
              <div class="indent-radio">
                <mat-radio-group [formControlName]="Constant.apBunriTypeId">
                <mat-radio-button *ngFor="let item of master[Constant.tlBunriType]" color="primary"
                  [value]="item.id" (click)="onRadio(item.id, schools.controls[i], Constant.apBunriTypeId, $event)"
                >{{ item.item_value }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <!-- 学部・専攻 -->
            <div>
              <div class="label">
                <img
                  src="assets/notice/mark_blue.png"
                  srcset="
                    assets/notice/mark_blue.png    1x,
                    assets/notice/mark_blue@2x.png 2x,
                    assets/notice/mark_blue@3x.svg 3x
                  "
                />
                <span class="font-bold"> 学部・専攻など</span>
              </div>
              <div class="middle">
                <div class="label-count" [class.length-red]="department.value.length>Constant.fvMaxCompanyName">{{ department.value.length }}/{{ Constant.fvMaxCompanyName }}</div>
                <input
                  class="input-text"
                  [formControlName]="Constant.apDepartment"
                  #department
                  [maxlength]="Constant.fvMaxCompanyName"
                  type="text"
                  (keydown.enter)="onKeyDown($event)"
                />
              </div>
            </div>
          </div>

          <!-- 主な活動 -->
          <div class="input-container">
            <div class="label">
              <img
                src="assets/notice/mark_blue.png"
                srcset="
                  assets/notice/mark_blue.png    1x,
                  assets/notice/mark_blue@2x.png 2x,
                  assets/notice/mark_blue@3x.svg 3x
                "
              />
              <span class="font-bold"> 主な活動</span>
            </div>
            <div class="indent career-memo">
              <div class="label-count" [class.length-red]="activity.value.length>Constant.fvMaxLenCompanyDetail">{{ activity.value.length }}/{{ Constant.fvMaxLenCompanyDetail }}</div>
              <textarea class="textarea" matInput rows="5" [formControlName]="Constant.apMainActivities"
              #activity placeholder=""></textarea>
            </div>
          </div>

        </ng-container>

        <mat-divider class="narrow"></mat-divider>

      </ng-container>

    </ng-container>
    <div class="form-btn-area" *ngIf="schools.controls.length < maxSchoolHistory">
      <button mat-raised-button type="button" (click)="onAddSchool()">
        <ng-container *ngIf="schools.controls.length == 0">学歴をフォームから登録する</ng-container>
        <ng-container *ngIf="schools.controls.length > 0">学歴を追加する</ng-container>
      </button>
    </div>

    <!-- 学歴メモ -->
    <div class="input-container">
      <div class="label">
        <img
          src="assets/notice/mark_blue.png"
          srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          "
        />
        <span class="font-bold"> 学歴メモ</span>
      </div>
      <div class="indent memo">
        <div class="label-count" [class.length-red]="memoschool.value.length>Constant.fvMaxMemo">{{ memoschool.value.length }}/{{ Constant.fvMaxMemo }}</div>
        <textarea class="textarea" matInput rows="8" [formControlName]="Constant.apCommentSchool"
        #memoschool placeholder="学歴メモ"></textarea>
      </div>
    </div>

  </div>

  <mat-divider class="last"></mat-divider>

  </form>
  <div class="btn-container" *ngIf="!showSpinner">
    <div class="err">
      <div class="spinner">
        <mat-spinner diameter="30" *ngIf="updateSpinner"></mat-spinner>
      </div>
      <mat-error *ngIf="!updateSpinner && checkFormDisabled()">必須項目が未入力か、エラーが出ています。
      </mat-error>
    </div>
    <button mat-raised-button (click)="onCancel()" [disabled]="updateSpinner">キャンセル</button>
    <button mat-raised-button (click)="onSave()" [disabled]="checkFormDisabled()" class="yes-btn" *ngIf="mode == Constant.csvTalentTypeAdd">
      作成
    </button>
    <button mat-raised-button (click)="onSave()" [disabled]="checkFormDisabled()" class="yes-btn" *ngIf="mode == Constant.csvTalentTypeEdit">
      更新
    </button>
  </div>
</mat-dialog-content>
