import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pass-comp-dialog',
  templateUrl: './pass-comp-dialog.component.html',
  styleUrls: ['./pass-comp-dialog.component.css']
})
export class PassCompDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PassCompDialogComponent>) {}

  ngOnInit() {}

  onLogin() {
    this.dialogRef.close(true);
  }
}
