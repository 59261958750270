import { Component } from '@angular/core';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';

@Component({
  selector: 'app-lp-footer',
  templateUrl: './lp-footer.component.html',
  styleUrls: ['./lp-footer.component.css']
})
export class LpFooterComponent {
  public readonly Constant = Constant;

  constructor(
    private commonUtil: CommonUtil,
  ) { }

  // トップページ
  onTopPage() {
    window.scroll(0, 0);
  }

  // GAイベント
  onClickGAEvent(action) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, action);
  }
}
