import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Constant } from '../constant';
import { CommonUtil } from './../util/common-util';
import { Title } from '@angular/platform-browser';
import { AuthService } from './../auth/auth.service';
import { environment } from './../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lp',
  templateUrl: './lp.component.html',
  styleUrls: ['./lp.component.css']
})
export class LpComponent implements OnInit, AfterViewInit {
  public readonly Constant = Constant;
  public newsList = [];
  public newsMoreUrl = environment.CORP_URL + '/newslist';
  public userList = [];
  public lpMode = Constant.LpModeTop;

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private commonUtil: CommonUtil,
    private auth: AuthService
  ) {
    this.lpMode = this.activatedRoute.snapshot.data['type'];
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.title.setTitle(Constant.pageTitleRoot);
    setTimeout(() => {
      this.getNewsList();
      this.getUserList();
    }, 1000);
  }

  ngAfterViewInit() {
    // HR　NOTE広告用タグ
    this.commonUtil.insertLpAd(Constant.lpHrnoteScriptId, Constant.lpHrnoteAdStart);
  }

  // トップページ
  onTopPage() {
    window.scroll(0, 0);
  }

  // リンククリック時のAnalyticsEvent
  onClickGAEvent(action) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, action);
  }

  // テキスト省略
  ellipsisText(text, maxLen) {
    const len = text.length;

    if (len <= maxLen) {
      return text;
    } else {
      return text.substr(0, maxLen) + '… ';
    }
  }

  private getNewsList() {
    const baseDatetime = this.commonUtil.createCurrentDateString('', 'YYYY/MM/DD hh:mm:ss');
    const options = this.auth.createApiHeaderBeforeLoginForCorp();
    let apiPath = '/news?BaseDateTime=' + baseDatetime;
    apiPath = apiPath + '&LpShowFlag=1';

    this.commonUtil
      .apiGetForCorp(apiPath, options)
      .then(res => {
        this.commonUtil.debug().log(res);
        // 取得したニュースの最新3件を表示
        for (let i = 0; i < 3; i++) {
          const newsData = res.data[i];
          newsData.url = environment.CORP_URL + '/news/' + newsData.ID;
          this.newsList.push(newsData);
        }
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
      });
  }

  // ご利用企業一覧取得
  private getUserList() {
    const apiPath = '/userlist';
    const options = this.auth.createApiHeaderBeforeLogin();
    options['body'] = {
      from_no: 0,
      desire_place: [],
      company_industry: [],
    };

    this.commonUtil.apiPost(apiPath, options)
    .then(res => {
      if (res.data.list.length > 0) {
        this.userList = res.data.list.slice(0, 3);
        this.getImageData();
      }
    })
    .catch(err => {});
  }

  // 企業ロゴとPR画像を取得する
  private getImageData() {
    for (let i = 0; i < this.userList.length; i++) {
      const companyCode = this.userList[i].code;
      // PR画像を取得
      const prImageApiPath = '/user/' + companyCode + '/imageurl';
      const options = this.auth.createApiHeaderBeforeLogin();

      this.commonUtil.apiGet(prImageApiPath, options)
      .then(res => {
        const data = res.data;
        if (data.url === '' || data.url === null) {
          this.userList[i].pr_image_url = '';
        } else {
          this.userList[i].pr_image_url = data.url;
        }
      });

      // ロゴ画像を取得
      const logoUrlApiPath = '/user/' + companyCode + '/logourl';
      this.commonUtil.apiGet(logoUrlApiPath, options)
      .then(res => {
        const data = res.data;
        if (data.url === '' || data.url === null) {
          this.userList[i].logo_url = '';
        } else {
          this.userList[i].logo_url = data.url;
        }
      });
    }
  }
}
