<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="description">ここでは、トップページ上部で表示される情報を登録します。</div>
    </div>
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="form" *ngIf="!showSpinner && form">
      <!-- ヘッドライン -->
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> ヘッドライン
          <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="indent">
          <div class="label-count" *ngIf="headline" [class.length-red]="headline.value.length>maxLenHeadline">{{ headline.value.length }}/{{ maxLenHeadline }}</div>
          <input
            class="input-text" #headline
            formControlName="headline"
            [maxlength]="maxLenHeadline" />
        </div>
      </div>
      <!-- ヘッドラインの説明 -->
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> ヘッドラインの説明・ボディコピーなど
          <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="indent">
          <div class="label-count" *ngIf="desc" [class.length-red]="desc.value.length>maxLenHeadDesc">{{ desc.value.length }}/{{ maxLenHeadDesc }}</div>
          <textarea
            class="input-textarea"
            formControlName="desc"
            rows="6" #desc></textarea>
        </div>
      </div>
      <!-- PDF -->
      <div class="primage-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> 会社紹介資料<span class="normal"> ※PDF形式・容量5Mまで、横長（4:3 or 16:9）推奨</span>
        </div>
        <div class="flex">
          <div class="pdf" *ngIf="companyDocumentPath">
            <div *ngIf="showDocumentSpinner" class="spinner">
              <mat-spinner diameter="60"></mat-spinner>
            </div>
            <pdf-viewer [src]="companyDocumentPath" class="pdf-viewer" [show-all]="false" [page]="companyDocumentPage"
            [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true" [render-text]="false"
            (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"></pdf-viewer>
            <div class="pdf-viewer-navi" *ngIf="companyDocumentTotalPage>0">
              <div class="leftbtn">
                <button mat-flat-button (click)="prevBtn()" [disabled]="companyDocumentPage==1">＜</button>
                <button mat-flat-button (click)="nextBtn()" [disabled]="companyDocumentPage==companyDocumentTotalPage">＞</button>
              </div>
              <span>{{ companyDocumentPage }} / {{ companyDocumentTotalPage }}</span>
              <div class="rightbtn">
                <button mat-icon-button (click)="onCompanyPdf()"><mat-icon>fullscreen</mat-icon></button>
              </div>
            </div>
          </div>
          <div class="pdf" *ngIf="!companyDocumentPath">
            <div class="no-image-frame">
              <div class="noimage">
                No Image
              </div>
            </div>
            <div class="pdf-viewer-navi">
              <div class="leftbtn">
                <button mat-flat-button disabled>＜</button>
                <button mat-flat-button disabled>＞</button>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div mat-icon-button (click)="documentFileInput.click()">
              <img
                src="assets/joboffer/image_upload.png"
                srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                "
              />
              <input
                hidden
                type="file"
                #documentFileInput
                accept="application/pdf"
                (change)="onChangeImage($event)"
              />
            </div>
            <div mat-icon-button (click)="onImageDelete()">
              <img
                src="assets/joboffer/image_delete.png"
                srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()"
  [disabled]="showSpinner || !form || form.invalid || isSaving" class="yes-btn">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
