<mat-toolbar class="background">
  <span>
    <img src="assets/logo.png" class="logo" />
  </span>
</mat-toolbar>
<div class="error">
  現在メンテナンス中です。
  <br />
  <br />
  <a [routerLink]="['/']">トップページに戻る</a>
  <br />
  <br />
  トップページはメンテナンス終了後に表示されるようになります。
  <br />
  <br />
  <br />
  <span class="footer">©CaLin, Inc.</span>
</div>
