import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../../constant';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { ToastUtil } from '../../util/toast-util';
import { CommonUtil } from './../../util/common-util';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-notice-edit-dialog',
  templateUrl: './notice-edit-dialog.component.html',
  styleUrls: ['./notice-edit-dialog.component.css']
})
export class NoticeEditDialogComponent implements OnInit {
  public form: FormGroup;
  public notice;
  public tagId;
  public addQuestions;
  public selectedQuestionIdAry = [];
  public defaultUrlEditFlg = false;
  public readonly Constant = Constant;
  public showSpinner = false;
  public isSaving = false;
  public maxLenTitle = 20;
  public maxLenQuestion = 100;

  constructor(
    public dialogRef: MatDialogRef<NoticeEditDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.notice = data.notice;
    this.tagId = data.tagId;
    if (this.notice.add_question_id) {
      this.selectedQuestionIdAry = this.notice.add_question_id.split(',').map(Number);
    }

    if (data.type === Constant.noticeTypeEdit && data.notice.id === 0) {
      this.defaultUrlEditFlg = true;
    }
  }

  ngOnInit() {
    this.getQuestion();
    this.initForm();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }
    this.isSaving = true;
    let data;
    if (!this.defaultUrlEditFlg) {
      data = {
        title: this.commonUtil.replaceSpace(this.form.value.title),
        question: this.commonUtil.replaceSpace(this.form.value.question)
      };
    } else {
      data = {
        title: '',
        question: ''
      };
    }
    const addQuestion = [];
    this.addQuestions.forEach(item => {
      if (this.form.value['addquestion_' + item.id]) {
        addQuestion.push(item.id);
      }
    });
    data['add_question'] = addQuestion;

    this.updateNoticeUrl(data);
  }

  // 質問内容textareaの改行(エンターキー押下)無効化
  onKeyDown(evt) {
    if (evt.key === 'Enter') {
      return false;
    }
  }

  // 質問内容textareaに貼り付けられたテキストの改行を無効化
  onInput() {
    // 入力内容を取得
    const beforeText = this.form.value.question;
    // 入力内容の改行を空文字に置き換える
    const changeText = beforeText.replace(/\r?\n/g, '');
    // 元の内容と置き換えた内容が等しくなければ、新しい内容に置き換える
    if (changeText !== beforeText) {
      this.form.patchValue({question: changeText});
    }
  }

  /* プライベート */
  // フォーム作成
  private initForm() {
    const formControl = {};
    if (!this.defaultUrlEditFlg) {
      formControl['title'] = new FormControl(Constant.empty, [Validators.required, Validators.maxLength(this.maxLenTitle)]);
      formControl['question'] = new FormControl(Constant.empty, [Validators.required, Validators.maxLength(this.maxLenQuestion)]);

      setTimeout(() => {
        this.form.patchValue({
          title: this.notice.title,
          question: this.notice.question
        });
      });
    }
    this.form = new FormGroup(formControl);
  }

  // 接点経路更新API呼び出し
  private updateNoticeUrl(data) {
    Auth.currentSession().then(session => {
      const apiPath = '/contacttag';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        title: data.title,
        question: data.question,
        add_question: data.add_question
      };

      if (this.tagId || this.defaultUrlEditFlg) {
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUrlEditSave);
        options['body']['contact_tag_id'] = this.tagId;
        this.commonUtil
          .apiPut(apiPath, options)
          .then(res => {
            // 成功トースト表示
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showInformationToast('', Constant.msgNoticeEdit, Constant.toastShowMiliSec);

            this.dialogRef.close(true);
          })
          .catch(err => {
            // 失敗
            this.toastUtil.clearAllShowingToast();
            if (err.data?.cnt === 0) {
              // 削除済みの場合、ダイアログを閉じる
              this.toastUtil.showErrorToast('', Constant.msgNoticeEditFailed, Constant.toastShowMiliSec);
              this.dialogRef.close(true);

            } else {
              this.toastUtil.showErrorToast('', sprintf(Constant.msgNoticeUpdateError, '更新'), Constant.toastShowMiliSec);
              this.isSaving = false;
            }

          });
      } else {
        // GAイベント
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionUrlNewSave);
        this.commonUtil
          .apiPost(apiPath, options)
          .then(res => {
            // 成功トースト表示
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showInformationToast('', Constant.msgNoticeNew, Constant.toastShowMiliSec);

            // 一覧を再読み込み
            this.dialogRef.close(true);
          })
          .catch(err => {
            // 失敗
            this.toastUtil.clearAllShowingToast();
            if(err.data?.max) {
              // 件数が上限の場合、ダイアログを閉じる
              this.toastUtil.showErrorToast('', Constant.msgNoticeNewFailed, Constant.toastShowMiliSec);
              this.dialogRef.close(true);

            } else {
              this.toastUtil.showErrorToast('', sprintf(Constant.msgNoticeUpdateError, '作成'), Constant.toastShowMiliSec);
              this.isSaving = false;
            }
          });
      }
    });
  }

  // 追加質問一覧取得
  private getQuestion() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const apiPath = '/question';
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          this.addQuestions = res.data.data;
          this.initFromQuestion();
          this.showSpinner = false;
        })
        .catch(err => {
          this.showSpinner = false;
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // 追加質問のフォーム作成
  private initFromQuestion() {
    this.addQuestions.forEach(item => {
      const check = this.selectedQuestionIdAry.includes(item.id);
      this.form.addControl('addquestion_' + item.id, new FormControl(check));
    });
  }
}
