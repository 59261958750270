<div *ngIf="this.talentData.talents != null">
  <form>
  <table class="talent-table table">
    <tr>
      <th class="gray-start"></th>
      <th *ngIf="talentData.checkFlg" class="check-cell all"><mat-checkbox color="primary" name="check-all" [(ngModel)]="talentData.allCheckFlg" (change)="onCheckAll()"></mat-checkbox></th>
      <th></th>
      <th class="image-cell"></th>
      <th class="name-cell"><img class="colicon" src="assets/talent-list/user_white.png" />名前</th>
      <th class="univercity-cell"><img class="colicon" src="assets/talent-list/university_white.png" />直近学校</th>
      <th class="company-cell"><img class="colicon" src="assets/talent-list/company_white.png" />直近企業</th>
      <th class="file-cell"><div class="flex"><img class="colicon" src="assets/talent-list/clip_white.png" /><span>添付</span></div></th>
      <th class="message-cell"><div class="flex"><img class="colicon" src="assets/talent-list/mail_white.png" /><span>個別の<br>メッセージ</span></div></th>

      <th class="date"><img class="colicon" src="assets/talent-list/offer_action.png" />応募<br><img class="colicon" src="assets/talent-list/offer_action.png" />気になる</th>
      <th *ngIf="talentData.listFlg" class="action-cell-noreply"></th>
    </tr>
    <ng-container *ngFor="
      let talent of talentData.talents | paginate: this.talentData.pagingConfig; index as idx">
      <tr>
        <td class="gray-start" rowspan="2">
          <div class="gray-row" [class.short]="!talentData.listFlg" *ngIf="talent.bounce_flag!=0 || talent.optout_flag!=0">
            メッセージ配信が拒否/停止されているタレントです。
          </div>
        </td>
        <!-- チェックボックス -->
        <td rowspan="2" class="check-cell" *ngIf="talentData.checkFlg">
          <mat-checkbox color="primary" name="check-{{talent.id}}" [(ngModel)]="talent.selected" (change)="onCheck(idx)"></mat-checkbox>
        </td>
        <!-- 絞り込みフラグ -->
        <td class="favorite-cell" rowspan="2">
          <button mat-icon-button *ngIf="talentData.listFlg else favoriteCellTemplate"
              (click)="onFavoriteButton(talent.id, talent.favorite_flag, idx)"
              [disabled]="favFlgSending"
              class="favorite-btn">
            <!-- フラグ登録あり = 1 -->
            <img *ngIf="talent.favorite_flag == 1" class="icon-star" src="assets/talent-list/bookmark_on.svg"/>
            <!-- フラグ登録なし = 0 -->
            <img *ngIf="talent.favorite_flag == 0" class="icon-star" src="assets/talent-list/bookmark_off.svg"/>
          </button>
          <ng-template #favoriteCellTemplate>
            <!-- フラグ登録あり = 1 -->
            <img *ngIf="talent.favorite_flag == 1" class="icon-star" src="assets/talent-list/bookmark_on.svg"/>
            <!-- フラグ登録なし = 0 -->
            <img *ngIf="talent.favorite_flag == 0" class="icon-star" src="assets/talent-list/bookmark_off.svg"/>
          </ng-template>

        </td>
        <!-- 画像 -->
        <td class="image-cell cursor-pointer" rowspan="2" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <ng-container *ngIf="talent.data_status_type_id == Constant.tlDataStatusTypeIdNew">
            <div class="header-image-box">
              <img class="header-image-new" src="assets/talent-list/new.png" />
            </div>
          </ng-container>
          <ng-container *ngIf="talent.data_status_type_id == Constant.tlDataStatusTypeIdUpdate">
            <div class="header-image-box">
              <img class="header-image-update" src="assets/talent-list/update.png" />
            </div>
          </ng-container>
          <ng-container *ngIf="talent.image_path != Constant.empty; else noTalentImage">
            <div class="profile-image-box">
              <img class="profile-image" src="{{ talent.image_path }}" />
            </div>
          </ng-container>
          <!-- 画像が登録されていない場合はデフォルト画像を表示する -->
          <ng-template #noTalentImage>
            <ng-container *ngIf="talent.csv_flag!=1">
              <ng-container *ngIf="talent.sex_id == Constant.tlSexIDMen">
                <div class="profile-image-box">
                  <img class="profile-image" src="assets/talent-list/icon_User_men.png"
                    srcset="assets/talent-list/icon_User_men.png 1x, assets/talent-list/icon_User_men@2x.png 2x" />
                </div>
              </ng-container>
              <ng-container *ngIf="talent.sex_id == Constant.tlSexIDWomen">
                <div class="profile-image-box">
                  <img class="profile-image" src="assets/talent-list/icon_User_woman.png"
                    srcset="assets/talent-list/icon_User_woman.png 1x, assets/talent-list/icon_User_woman@2x.png 2x" />
                </div>
              </ng-container>
              <ng-container *ngIf="talent.sex_id == Constant.tlSexIDOther">
                <div class="profile-image-box">
                  <img class="profile-image" src="assets/talent-list/icon_User.png"
                    srcset="assets/talent-list/icon_User.png 1x, assets/talent-list/icon_User@2x.png 2x" />
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="talent.csv_flag==1">
              <ng-container *ngIf="talent.sex_id == Constant.tlSexIDMen">
                <div class="profile-image-box">
                  <img class="profile-image" src="assets/talent-list/icon_User_men_csv@2x.png" />
                </div>
              </ng-container>
              <ng-container *ngIf="talent.sex_id == Constant.tlSexIDWomen">
                <div class="profile-image-box">
                  <img class="profile-image" src="assets/talent-list/icon_User_woman_csv@2x.png" />
                </div>
              </ng-container>
              <ng-container *ngIf="talent.sex_id == Constant.tlSexIDOther">
                <div class="profile-image-box">
                  <img class="profile-image" src="assets/talent-list/icon_User_csv@2x.png" />
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
        </td>
        <!-- 名前 -->
        <td class="first-row name-cell cursor-pointer" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div class="talent-name">
            <span>{{ talent.name }}</span>
          </div>
        </td>
        <!-- 直近学校 -->
        <td class="cursor-pointer univercity-cell" rowspan="2" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div class="univercity">
            <img class="colicon" src="assets/talent-list/university_blue.png" />
            <div>
              <div class="univercity-name" *ngIf="talent.univercity != Constant.empty; else dataNone">
                {{ talent.univercity }}
              </div>
              <div class="univercity-name">
                <ng-container *ngIf="talent.bunri_type!=Constant.empty || talent.department!= Constant.empty; else dataNone">
                  <ng-container *ngIf="talent.bunri_type != Constant.empty; else dataNone">
                    {{ talent.bunri_type }}
                  </ng-container>:
                  <ng-container *ngIf="talent.department!= Constant.empty; else dataNone">
                    {{ talent.department }}
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div>
            <span class="sub-text">新卒採用枠(卒業年)：
              <ng-container *ngIf="talent.graduate_year_skip_flag==1">
                <span class="graduate gray">未登録</span>
              </ng-container>
              <ng-container *ngIf="talent.graduate_year_skip_flag==0">
              <span *ngIf="talent.graduate_year_type==Constant.tlGraduateYearMin" class="graduate past">〜{{Constant.tlGraduateYearMin}}年卒</span>
              <span *ngIf="talent.graduate_year_type>Constant.tlGraduateYearMin" class="graduate"
                [class.past]="talent.graduate_year_type < newGraduateYear"
                [class.future]="talent.graduate_year_type >= newGraduateYear"
                >{{talent.graduate_year_type}}年卒</span>
              <span *ngIf="talent.graduate_year_type===Constant.tlGraduateYearOther.toString()" class="graduate gray">その他</span>
              <span *ngIf="talent.graduate_year_type===''" class="graduate gray">未登録</span>
              </ng-container>
            </span>
          </div>
        </td>
        <!-- 直近企業 -->
        <td class="first-row company cursor-pointer" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div class="company">
            <img class="colicon" src="assets/talent-list/company_blue.png" />
            <div *ngIf="talent.last_employee_company != Constant.empty; else dataNone">
              {{ talent.last_employee_company }}
            </div>
          </div>
        </td>
        <!-- 添付ファイル -->
        <td rowspan="2" class="cursor-pointer file-cell" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div>
            <img *ngIf="talent.file_cnt != 0" class="file-icon file-" src="assets/common/file.png" />
            <div *ngIf="talent.file_cnt == 0">-</div>
          </div>
        <!-- メッセージ -->
        <td rowspan="2" class="cursor-pointer" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div class="message-cell">
            <ng-container *ngIf="talent.msg_created_at != Constant.empty">
              <div>{{ talent.msg_created_at | date: 'yyyy/MM/dd' }}</div>
              <ng-container *ngIf="talent.msg_type_id == Constant.tlMsgTypeReceived">
                <div>受信</div>
                <div *ngIf="talent.csv_flag!=1 && talent.msg_readed_flag == Constant.tlMsgNotReaded" class="red">貴社未読</div>
                <div *ngIf="talent.csv_flag!=1 && talent.msg_readed_flag == Constant.tlMsgAlreadyReaded" class="gray">貴社既読</div>
              </ng-container>
              <ng-container *ngIf="talent.msg_type_id != Constant.tlMsgTypeReceived">
                <div>送信</div>
                <div *ngIf="talent.msg_readed_flag == Constant.tlMsgNotReaded" class="gray">タレント未読</div>
                <div *ngIf="talent.msg_readed_flag == Constant.tlMsgAlreadyReaded" class="gray">タレント既読</div>
              </ng-container>
            </ng-container>
            <div *ngIf="talent.msg_created_at == Constant.empty">-</div>
          </div>
        </td>
        <td rowspan="2" class="cursor-pointer" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <!-- アクション-応募 -->
          <div class="action-cell">
            <div *ngIf="talent.ofr1_created_at != Constant.empty">{{ talent.ofr1_created_at | date: 'yyyy/MM/dd' }}
            </div>
            <div *ngIf="talent.ofr1_created_at == Constant.empty">-</div>
          </div>
          <!-- アクション気になる -->
          <div class="action-cell">
            <div *ngIf="talent.ofr2_created_at != Constant.empty">{{ talent.ofr2_created_at | date: 'yyyy/MM/dd' }}
            </div>
            <div *ngIf="talent.ofr2_created_at == Constant.empty">-</div>
          </div>
        </td>
        <!-- 未対応アイコン -->
        <td rowspan="2" class="cursor-pointer action-cell-noreply" (click)="onShowTalentKarte(talent.id, talent.csv_flag)" *ngIf="talentData.listFlg">
          <div>
            <img *ngIf="talent.not_replied_flag == Constant.tlOfferExistNotReply"
              src="assets/talent-list/offer_noreply.png" />
          </div>
        </td>
      </tr>
      <tr class="cursor-pointer">
        <!-- 最終アクセス日・プール登録日 -->
        <td class="second-row" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div class="second-row-top">
            <ng-container *ngIf="talent.last_access_date != null">
              <span class="sub-text">最終アクセス：
                <ng-container *ngIf="talent.csv_flag!=1">{{ talent.last_access_date | date: 'yyyy/MM/dd' }}</ng-container>
                <ng-container *ngIf="talent.csv_flag==1">-</ng-container>
              </span>
            </ng-container>
          </div>
          <div class="second-row-bottom">
            <ng-container *ngIf="talent.talent_created_at != null">
              <span class="sub-text">タレントプール登録：{{ talent.talent_created_at | date: 'yyyy/MM/dd' }}</span>
            </ng-container>
          </div>
        </td>
        <!-- プロフィール更新日・就職活動予定時期 -->
        <td class="second-row" (click)="onShowTalentKarte(talent.id, talent.csv_flag)">
          <div class="second-row-top">
            <ng-container *ngIf="talent.updated_at != null; else profileNotUpdate">
              <span class="sub-text">プロフ更新/タレント操作：
                <ng-container *ngIf="talent.csv_flag!=1">{{ talent.updated_at | date: 'yyyy/MM/dd' }}</ng-container>
                <ng-container *ngIf="talent.csv_flag==1">-</ng-container>
              </span>
            </ng-container>
          </div>
          <div class="second-row-bottom">
            <span class="sub-text">活動予定時期：
              <ng-container *ngIf="talent.active_undecided_flag == Constant.tlActiveUndecided">
                未定
              </ng-container>
              <ng-container *ngIf="talent.active_undecided_flag != Constant.tlActiveUndecided">
                <span
                  *ngIf="talent.active_planned_start && talent.active_planned_end; else dataNone"
                >
                  {{ talent.active_planned_start }} 〜 {{ talent.active_planned_end }}
                </span>
              </ng-container>
            </span>
          </div>
        </td>
      </tr>
      <!-- ** テンプレート **-->
      <!-- 直近企業が登録されていない場合は「-」を表示する -->
      <ng-template #dataNone> <span>- </span> </ng-template>
      <!-- プロフィールが更新されたことがない場合は「-」を表示する -->
      <ng-template #profileNotUpdate>
        <span class="sub-text">プロフィール更新：-</span>
      </ng-template>
    </ng-container>
  </table>
  </form>
</div>
<ng-container *ngIf="!talentData.listFlg">
  <div class="not-hit-talent" *ngIf="talentData.talents && talentData.talents.length === 0 && talentData.checkFlg">
    選択できるタレントはいません。
  </div>
  <div class="not-hit-talent" *ngIf="talentData.talents && talentData.talents.length === 0 && !talentData.checkFlg">
    対象となるタレントがいないか、表示できるタレントがいません。
  </div>
</ng-container>

