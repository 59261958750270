<mat-toolbar class="background">
  <span>
    <img src="assets/logo.png" class="logo" />
  </span>
</mat-toolbar>
<div class="error">
  お探しのページは見つかりませんでした。
  <br /><br />
  URLが変更・削除されたか、一時的に閲覧できない、もしくはタレントがつながりを解消した可能性があります。
  <br />
  <br />
  <a [routerLink]="['/']">トップページに戻る</a>
  <br />
  <br />
  <br />
  <span class="footer">©CaLin, Inc.</span>
</div>
