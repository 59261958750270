import { Component, OnInit, HostListener } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { ToastUtil } from '../util/toast-util';
import { CommonUtil } from './../util/common-util';
import { Title } from '@angular/platform-browser';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [Title]
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errorMessage = '';
  public readonly Constant = Constant;
  public showSpinner = false;
  private companyCode: string;

  constructor(
    public auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastUtil: ToastUtil,
    private commonUtil: CommonUtil,
    private title: Title
  ) {
    this.companyCode = this.activatedRoute.snapshot.params['code'];
    this.title.setTitle(Constant.pageTitleLogin + Constant.pageTitleCommon);
  }

  ngOnInit() {
    // ログイン済み確認
    const companyName = localStorage.getItem(Constant.lsCompanyName);
    if (companyName) {
      this.auth.isAuthenticated().subscribe(result => {
        if (result) {
          // ログイン済み
          this.router.navigate(['map']);
        }
      });
    }
    this.initForm();
    localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログイン済み確認
    const companyName = localStorage.getItem(Constant.lsCompanyName);
    if (companyName) {
      this.auth.isAuthenticated().subscribe(result => {
        if (result) {
          // ログイン済み
          this.router.navigate(['map']);
        }
      });
    }
  }

  initForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  onSubmitLogin(value: any) {
    const loginStatus = localStorage.getItem(Constant.lsLoginStatus);
    if (loginStatus === Constant.lsTrue) {
      this.commonUtil.showReloadDialog();
      return;
    }
    localStorage.setItem(Constant.lsLoginStatus, Constant.lsTrue);
    localStorage.setItem(Constant.lsCompanyCode, this.companyCode);

    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogin);

    const email = value.email,
      password = value.password;
    if (!email || !password) {
      // 未入力チェック
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgMailOrPassEmpty, Constant.toastShowMiliSec);
      this.showSpinner = false;
      localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
      return;
    }

    // フォームのエラーチェック
    if (this.loginForm.invalid) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgFormInvalid, Constant.toastShowMiliSec);
      this.showSpinner = false;
      localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
      return;
    }

    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
      return;
    }

    // 複数ログインを防ぐため、サインイン前にサインアウトする
    Auth.signOut();

    this.showSpinner = true;
    this.auth.signIn(email, password).subscribe(result => {
      if (result) {
        localStorage.setItem(Constant.lsOperator, email);
        this.auth
          .companyLogin()
          .then(res => {
            this.showSpinner = false;
          })
          .catch(err => {
            if (!this.commonUtil.apiStatusOther(err)) {
              this.toastUtil.clearAllShowingToast();
              this.toastUtil.showErrorToast('', Constant.msgMailOrPassWrong, Constant.toastShowMiliSec);
              this.showSpinner = false;
            }
            localStorage.removeItem(Constant.lsCompanyName);
            localStorage.removeItem(Constant.lsOperator);
            localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
          });
      } else if (this.auth.user) {
        localStorage.setItem(Constant.lsOperator, email);
        this.router.navigate(['/password_change']);
      } else {
        if (
          this.auth.error &&
          (this.auth.error['code'] === 'NotAuthorizedException' ||
            this.auth.error['code'] === 'UserNotFoundException')
        ) {
          this.toastUtil.clearAllShowingToast();
          let msg;
          if (this.auth.error['message'].indexOf('expired') > -1) {
            msg = Constant.msgAccountExpired;
          } else if (this.auth.error['message'].indexOf('exceeded') > -1) {
            msg = Constant.msgLoginAttemptExceeded;
          } else {
            msg = Constant.msgMailOrPassWrong;
          }
          this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
          this.showSpinner = false;
          localStorage.setItem(Constant.lsLoginStatus, Constant.lsFalse);
        }
      }
    });
  }

  // 利用規約
  onAgreement() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionAgreement);
    window.open(Constant.footerUrlAgreement);
  }

  // プライバシーポリシー
  onPrivacy() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPolicy);
    window.open(Constant.footerUrlPrivacy);
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
}
