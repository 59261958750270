<div class="all">
  <app-lp-header page="{{ lpMode }}"></app-lp-header>
  <ng-container *ngIf="lpMode==Constant.LpModeTop else eachTop">
    <!-- TOP -->
    <div class="top select">
      <div class="left">
        <div class="title color-navy bold">
          タレントプール採用サービス
        </div>
        <div class="left-image">
          <picture>
            <source srcset="../../assets/lp/logo_blue.webp" type="image/webp">
            <img src="../../assets/lp/logo_blue.png">
          </picture>
        </div>
      </div>
      <div class="right">
        <picture>
          <source srcset="../../assets/lp/top_trim.webp" type="image/webp">
          <img lazyLoad="../../assets/lp/top_trim.png">
        </picture>
      </div>
    </div>

    <section id="example" class="example-area">
      <div class="title-area midashi-font">
        CaLin（キャリン）は、<br>タレントプール採用を 手軽に<br class="show-sp">実現する Webサービスです。</div>
      <div class="comment-area">
        <div class="comment">※タレントプール：将来の採用候補者を含む人材データベース</div>
      </div>
      <div class="picture-area">
        <div class="picture">
          <div class="title color-navy">
            <span>つながる</span>
          </div>
          <div class="image">
            <picture>
              <source lazyLoad="../../assets/lp/pic_01.webp" type="image/webp">
              <img lazyLoad="../../assets/lp/pic_01.png" />
            </picture>
          </div>
          <div class="description">自社に興味がある人を
            <br />集めてプールする</div>
        </div>
        <div class="picture">
          <div class="title color-navy">
            <span>伝える</span>
          </div>
          <div class="image">
            <picture>
              <source lazyLoad="../../assets/lp/pic_02.webp" type="image/webp">
              <img lazyLoad="../../assets/lp/pic_02.png" />
            </picture>
          </div>
          <div class="description">プールしている候補者へ
            <br />自社の魅力を発信する</div>
        </div>
        <div class="picture">
          <div class="title color-navy">
            <span>採用 </span>
          </div>
          <div class="image">
            <picture>
              <source lazyLoad="../../assets/lp/pic_03.webp" type="image/webp">
              <img lazyLoad="../../assets/lp/pic_03.png" />
            </picture>
          </div>
          <div class="description">候補者が「その気」に
            <br />なったタイミングで採用！</div>
        </div>
      </div>
      <div class="footer">
        新卒採用でも中途採用でも！<br>
        営業でもエンジニアでも！<br>
        インターンでも業務委託でも！<br>
        <span class="bold">職種や雇用形態を問わずご利用できます。</span>
      </div>
    </section>
    <div class="select-area">
      <div class="flex">
        <a mat-button routerLink="/{{ Constant.rtLpNewgrad }}" class="select-btn"><span class="color-yellow">新卒採用</span>が<br>主な方はこちら</a>
        <a mat-button routerLink="/{{ Constant.rtLpCareer }}" class="select-btn"><span class="color-yellow">中途採用</span>が<br>主な方はこちら</a>
      </div>
      <div class="image">
        <picture>
          <source srcset="../../assets/lp/top_btm_trim.webp" type="image/webp">
          <img lazyLoad="../../assets/lp/top_btm_trim.jpg">
        </picture>
      </div>
    </div>

  </ng-container>
  <ng-template #eachTop>
  <div class="top" *ngIf="lpMode==Constant.LpModeNewgrad">
    <!-- 新卒採用TOP -->
    <div class="left">
      <div class="show-sp btn-area">
        <div class="flex">
          <a class="rect-btn doc" routerLink="/{{Constant.rtRequest}}/{{Constant.modeWhitePaper}}" (click)="onClickGAEvent(Constant.gaActionTopWhitepaper + '1')">資料請求</a>
          <a class="rect-btn account" routerLink="/{{Constant.rtRequest}}/{{Constant.modeNewAccount}}" (click)="onClickGAEvent(Constant.gaActionTopAccount + '1')">無料ではじめる</a>
        </div>
      </div>
      <div class="title color-navy bold">
        就活前から学生を<br>タレントプール
      </div>
      <div class="text">
        CaLinは、就活前の大学1・2年生や就活生と<br class="show-pc">接点を作りファン化します。
      </div>
      <div class="text">
        人材とのつながりは新卒選考後も失われず、<br class="show-pc">第二新卒・中途採用へと採用機会を拡大します。
      </div>
      <div class="button-area">
        <a mat-button (click)="onClickGAEvent(Constant.gaActionTopAccount + '1')" class="btn new-account-btn"
         routerLink="/{{Constant.rtRequest}}/{{Constant.modeNewAccount}}">
          まずは無料ではじめる
        </a>
      </div>
    </div>
    <div class="right">
      <picture>
        <source srcset="../../assets/lp/main_trim.webp" type="image/webp">
        <img lazyLoad="../../assets/lp/main_trim.jpg">
      </picture>
    </div>
  </div>
  <div class="top career" *ngIf="lpMode==Constant.LpModeCareer">
    <!-- 中途採用TOP -->
    <div class="left">
      <div class="show-sp btn-area">
        <div class="flex">
          <a class="rect-btn doc" routerLink="/{{Constant.rtRequest}}/{{Constant.modeWhitePaper}}" (click)="onClickGAEvent(Constant.gaActionTopWhitepaper + '1')">資料請求</a>
          <a class="rect-btn account" routerLink="/{{Constant.rtRequest}}/{{Constant.modeNewAccount}}" (click)="onClickGAEvent(Constant.gaActionTopAccount + '1')">無料ではじめる</a>
        </div>
      </div>
      <div class="title color-white bold">
        タレントプールで<br>潜在層と つながる
      </div>
      <div class="button-area">
        <a mat-button (click)="onClickGAEvent(Constant.gaActionTopAccount + '1')" class="btn new-account-btn"
          routerLink="/{{Constant.rtRequest}}/{{Constant.modeNewAccount}}">
          まずは無料ではじめる
        </a>
      </div>
      <div class="left-image">
        <picture>
          <source srcset="../../assets/lp/kigyoPC_trim2.webp" type="image/webp">
          <img src="../../assets/lp/kigyoPC_trim2.png">
        </picture>
      </div>
    </div>
    <div class="right">
      <picture>
        <source srcset="../../assets/lp/top_career_trim.webp" type="image/webp">
        <img lazyLoad="../../assets/lp/top_career_trim.jpg">
      </picture>
    </div>
  </div>
  <div *ngIf="newsList.length>0" class="news-area">
    <div class="title bold">
      News　━━━━━━
    </div>
    <div *ngFor="let news of newsList; index as idx" class="news-row">
      <div class="news-date">{{ news.ShowDateFormat }}</div>
      <div class="news-title">
        <a href="{{news.url}}" target="_blank">{{ news.Title }}</a>
      </div>
    </div>
    <div class="news-row-more">
      <a href={{newsMoreUrl}} target="_blank" (click)="onClickGAEvent(Constant.gaActionTopNewsMore)">もっと見る <img src="../../assets/lp/btnMore.png" width="13" height="13"></a>
    </div>
  </div>

  <!-- 新卒向け -->
  <app-lp-newgrad *ngIf="lpMode==Constant.LpModeNewgrad"></app-lp-newgrad>

  <!-- 中途向け -->
  <app-lp-career *ngIf="lpMode==Constant.LpModeCareer"></app-lp-career>

  <section id="feature">
    <div class="title-area midashi-font">
      主な機能
    </div>
    <div class="midashi-font2">──　企業側　管理画面　──</div>
    <div class="image" *ngIf="lpMode==Constant.LpModeNewgrad">
      <picture>
        <source srcset="../../assets/lp/kigyoPC.webp" type="image/webp">
        <img lazyLoad="../../assets/lp/kigyoPC.png" width="90%" />
      </picture>

    </div>
    <div class="flex">
      <div class="col">
        <div class="midashi-font2">候補者情報の閲覧</div>
        <ul class="text">
          <li>名前・性別・年齢・居住地</li>
          <li>電話番号・メールアドレス</li>
          <li>学歴・職歴</li>
          <li>就職/転職の希望情報</li>
          <li>採用PRコンテンツの閲覧状況</li>
          <li>求人へのアクション状況</li>
        </ul>
      </div>
      <div class="col">
        <div class="midashi-font2">候補者の管理</div>
        <ul class="text">
          <li>検索・並び替え</li>
          <li>お気に入りフラグ登録</li>
          <li>求人応募の選考管理</li>
          <li>タレントプール流入経路の管理</li>
          <li>管理メモ</li>
        </ul>
      </div>
    </div>
    <div class="flex">
      <div class="col">
        <div class="midashi-font2">メッセージ</div>
        <ul class="text">
          <li>個別メッセージの送受信</li>
          <li>添付ファイルの送受信</li>
          <li>一斉メッセージの送信</li>
          <li>一斉メッセージ送信除外者の管理</li>
          <li>メッセージ未読・既読の確認</li>
        </ul>
      </div>
      <div class="col">
        <div class="midashi-font2">採用PRコンテンツ</div>
        <ul class="text">
          <li>作成・編集・削除</li>
          <li>閲覧数やいいね数のカウント</li>
          <li>アクションした候補者の抽出</li>
        </ul>
      </div>
      <div class="col">
        <div class="midashi-font2">求人情報<br>
          <span *ngIf="lpMode==Constant.LpModeNewgrad">（インターンシップ募集可）</span>
          <span *ngIf="lpMode==Constant.LpModeCareer">（業務委託/パート募集可）</span>
        </div>
        <ul class="text">
          <li>作成・編集・削除</li>
          <li>閲覧数や応募数などのカウント</li>
          <li>アクションした候補者の抽出</li>
          <li>求人検索サービスへの自動掲載<br><span class="small">→求人ボックス、Googleしごと検索など</span></li>
        </ul>
      </div>
    </div>
    <div class="flex">
      <div class="col">
        <div class="midashi-font2">タレントプール登録用<br>エントリーページの作成</div>
        <ul class="text">
          <li>企業情報の表示</li>
          <li>スライド資料の表示</li>
          <li>採用PRコンテンツの表示</li>
          <li>求人情報の表示</li>
        </ul>
      </div>
      <div class="col">
        <div class="midashi-font2">通知機能</div>
        <ul class="text">
          <li>アクションがあった候補者にアイコンを表示</li>
          <li>登録・応募・メッセージ受信などをメールで通知</li>
        </ul>
      </div>
    </div>
    <div class="desc-right">※ 企業情報は <a href="{{Constant.footerUrlTalent}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopTalentLPText)">CaLinのタレント集客サイト</a> に掲載されます。</div>
  </section>
  <section id="user" *ngIf="userList.length>0">
    <div class="title-area midashi-font">ご利用企業 Pick Up</div>
    <div class="flex">
      <div class="col" *ngFor="let companyInfo of userList; index as i;">
        <a *ngIf="companyInfo.logo_url != Constant.empty" class="name-container"
          href="{{ Constant.footerUrlTalent }}login/{{ companyInfo.code }}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopPickupCompany + (i+1))">
          <img class="logo" [lazyLoad]="companyInfo.logo_url">
          <span class="name"
                [class.small]="companyInfo.name.length>14 && companyInfo.name.length<=16"
                [class.mini]="companyInfo.name.length>16">
                  {{ companyInfo.name }}
          </span>
        </a>
        <div *ngIf="companyInfo.pr_image_url != Constant.empty" class="image-container">
          <a href="{{ Constant.footerUrlTalent }}login/{{ companyInfo.code }}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopPickupCompany + (i+1))">
            <img class="image" [lazyLoad]="companyInfo.pr_image_url" />
          </a>
        </div>
        <div class="detailinfo-area">
          <div *ngIf="companyInfo.employment_area && companyInfo.employment_area != Constant.empty">
            <div class="title">勤務地</div>
            <div class="value">{{ companyInfo.employment_area }}</div>
          </div>
          <div *ngIf="companyInfo.industry && companyInfo.industry != Constant.empty">
            <div class="title">業種</div>
            <div class="value">{{ companyInfo.industry }}</div>
          </div>
        </div>
        <div class="main-copy-area" *ngIf="companyInfo.summary">{{ companyInfo.summary }}</div>
      </div>
    </div>
    <div class="more">
      <a href="{{ Constant.moreCompanyListUrl }}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopPickupMore)">
        もっと見る <img src="../../assets/lp/btnMore.png" width="13" height="13">
      </a>
    </div>
  </section>
  <!-- <section id="plan">
    <div class="title-area midashi-font">料金プラン</div>
    <div class="desc1">まずは<span class="orange">無料</span>でご利用できます。</div>
    <div class="plan-table show-pc">
      <table>
        <tr>
          <td>タレントプールの<br>登録人数</td>
          <td>〜20人</td>
          <td>21人〜<br>100人</td>
          <td>101人〜<br>500人</td>
          <td>501人〜<br>1,000人</td>
          <td>1,001人〜</td>
        </tr>
        <tr>
          <td>月額料金</td>
          <td>0円</td>
          <td>2万円</td>
          <td>5万円</td>
          <td>10万円</td>
          <td>ASK</td>
        </tr>
        <tr>
          <td colspan="6" class="attention">
            <ul>
              <li>記載の金額は全て税抜です。</li>
              <li>契約は1年毎の更新です。</li>
              <li>新卒採用/中途採用、どちらの利用でも料金に変更はありません。</li>
              <li>その他、ご利用に関する詳細は
                <a href="{{Constant.footerUrlAgreement}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopAgreement)">利用規約</a>をご覧ください。
              </li>
            </ul>
          </td>
        </tr>
      </table>

    </div>
    <div class="plan-table show-sp">
      <table>
        <tr>
          <td>タレントプール<br>の登録人数</td>
          <td>月額料金</td>
        </tr>
        <tr>
          <td>〜20人</td>
          <td>0円</td>
        </tr>
        <tr>
          <td>21人〜100人</td>
          <td>2万円</td>
        </tr>
        <tr>
          <td>101人〜<br>500人</td>
          <td>5万円</td>
        </tr>
        <tr>
          <td>501人〜<br>1,000人</td>
          <td>10万円</td>
        </tr>
        <tr>
          <td>1,001人〜</td>
          <td>ASK</td>
        </tr>
        <tr>
          <td colspan="2" class="attention">
            <ul>
              <li>記載の金額は全て税抜です。</li>
              <li>契約は1年毎の更新です。</li>
              <li>新卒採用/中途採用、どちらの利用でも料金に変更はありません。</li>
              <li>その他、ご利用に関する詳細は
                <a href="{{Constant.footerUrlAgreement}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionTopAgreement)">利用規約</a>をご覧ください。
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  </section> -->
  <section id="media">
    <div class="title-area midashi-font">掲載メディア</div>
    <div class="item">
      <a href="https://www.recme.jp/careerhigh/entry/calin" target="_blank"><img lazyLoad="/assets/lp/media/careerhigh.jpg"></a>
      <a href="https://hrtech-guide.jp/calin/" target="_blank"><img lazyLoad="/assets/lp/media/HR Tech guide.png"></a>
      <a href="https://busiconet.co.jp/evowork/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/EvoWork_logo.png"></a>
      <a href="https://2b-connect.jp/tensyoku-connect/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/connect_logo.png"></a>
      <a href="https://hakenreco.com/tenshokusite-recommend/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/CareeReco_logo.png"></a>
      <a href="https://appart.co.jp/upcareer/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/UpCareer_logo.png"></a>
      <a href="https://jobtv.jp/mediatop/recommend-site/#CaLin" target="_blank"><img lazyLoad="/assets/lp/media/tt_logo.png"></a>
    </div>
  </section>
  <section id="footer">
    <div class="desc footer-show-pc">CaLinなら貴社のお役に立てるかもしれません。</div>
    <div class="desc footer-show-sp">CaLinなら貴社のお役に<br>立てるかもしれません。</div>
    <div class="btn-area flex">
      <a mat-button class="btn contact-btn" (click)="onClickGAEvent(Constant.gaActionTopContact)"
        href="{{Constant.footerUrlContact}}" target="_blank">
        お問い合わせ <img src="../../assets/lp/launch_white.png" width="14">
      </a>
      <a mat-button class="btn doc-request-btn" (click)="onClickGAEvent(Constant.gaActionTopWhitepaper + '2')"
        routerLink="/{{Constant.rtRequest}}/{{Constant.modeWhitePaper}}">
        資料請求
      </a>
      <a mat-button class="btn new-account-btn" (click)="onClickGAEvent(Constant.gaActionTopAccount + '2')"
        routerLink="/{{Constant.rtRequest}}/{{Constant.modeNewAccount}}">
        無料ではじめる
      </a>
    </div>
    <div class="desc footer-show-pc">
      <span *ngIf="lpMode==Constant.LpModeNewgrad">新卒採用と中途採用で2度おいしい。</span>
      <span *ngIf="lpMode==Constant.LpModeCareer">採用に潜在層とのつながりを。</span>
      <br>CaLinでタレントプールをはじめましょう！
    </div>
    <div class="desc footer-show-sp">
      <span *ngIf="lpMode==Constant.LpModeNewgrad">新卒採用と中途採用で<br>2度おいしい。</span>
      <span *ngIf="lpMode==Constant.LpModeCareer">採用に潜在層とのつながりを。</span>
      <p>CaLinでタレントプールを<br>はじめましょう！</p>
    </div>
  </section>
  <div id="{{ Constant.lpHrnoteScriptId }}"></div>
  </ng-template>
  <app-lp-footer></app-lp-footer>
</div>
