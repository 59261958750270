<div class="quill-area">
  <div class="label-count" *ngIf="editor && loadFlg" [class.length-red]="onEditorCount()>maxLenText">{{ onEditorCount() }}/{{ maxLenText }}</div>
  <quill-editor placeholder="本文" [(ngModel)]="mainText" [class.hidden]="!loadFlg" class="{{ type }}"
  bounds="self" preserveWhitespace="true"
  [readOnly]="!loadFlg" #quillElm
  (onEditorCreated)="onEditorCreated($event)"
  (onContentChanged)="onContentChanged($event)">
    <div quill-editor-toolbar>
      <span class="ql-formats">
        <button class="ql-bold" title="太字"></button>
        <button class="ql-strike" title="取り消し線"></button>
        <button class="ql-blockquote" title="引用/強調"></button>
        <button class="ql-list" value="bullet" title="箇条書き"></button>
        <button class="ql-list" value="ordered" title="段落番号"></button>
        <button class="ql-align left" value="" title="左揃え"></button>
        <button class="ql-align" value="center" title="中央揃え"></button>
        <button class="ql-align" value="right" title="右揃え"></button>
        <button class="ql-header" value="3" title="大見出し">大見出し</button>
        <button class="ql-header" value="4" title="小見出し">小見出し</button>
        <button class="ql-toc" title="目次の挿入" (click)="onEditorToc()">目次</button>
        <button class="ql-link" title="リンク設定"></button>
        <button class="ql-image" title="画像の挿入" (click)="onImageUpload()"></button>
        <button class="ql-video" title="動画(YouTube)の挿入"></button>
        <button class="ql-clean" title="書式のクリア"></button>
      </span>
    </div>
  </quill-editor>
  <div class="spinner" *ngIf="!loadFlg">
    <mat-spinner></mat-spinner>
  </div>
</div>
