<div class="header">
  <div mat-icon-button (click)="onClose()">
    <img
      src="assets/joboffer/close_button.png"
      srcset="
        assets/joboffer/close_button.png    1x,
        assets/joboffer/close_button@2x.png 2x,
        assets/joboffer/close_button@3x.svg 3x
      "
      class="close-button"
    />
  </div>
</div>
<mat-dialog-content>
  <div class="message">送信対象の登録カテゴリーを選択してください。</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onSelect(Constant.tlRegistTypeTalent)">自己登録</button>
  <button mat-raised-button (click)="onSelect(Constant.tlRegistTypeCsv)">手動/CSV取込</button>
</mat-dialog-actions>
