<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">優先表示の設定</div>
      <div class="desc">
        エントリーページで優先表示したい採用PRコンテンツを指定できます。<br>
        「全件」で設定した順位は採用PRコンテンツの全件一覧ページに、「カテゴリー」で設定した順位はカテゴリー別の一覧ページに反映されます。
      </div>
    </div>
    <div class="list-area">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="!showSpinner && contents?.length == 0" class="no-data">
        採用PRコンテンツがありません。<br>
        採用PRコンテンツを作成してください。
      </div>
      <form [formGroup]="form" *ngIf="!showSpinner && form">
        <table *ngIf="contents?.length > 0">
          <tr>
            <th class="category-title"></th>
            <th class="order_no">全件</th>
            <th class="order_no">カテゴリー</th>
            <th></th>
            <th class="title"></th>
          </tr>
          <ng-container *ngFor="let content of contents">
            <tr [class.border-top]="content.categoryFirst" [class.padding-bottom]="content.categoryLast" >
              <td class="category-title">
                <span *ngIf="content.categoryFirst && content.category_id==0" class="gray">カテゴリー未登録</span>
                <span *ngIf="content.categoryFirst && content.category_id>0">{{ content.category_title }}</span>
              </td>
              <td class="order_no">
                <mat-form-field class="input-select no-line">
                  <mat-select [formControlName]="prefixAll + content.id.toString()" panelWidth=""
                  (selectionChange)="onSortChange($event.value, content.id, true, 0)">
                    <mat-option [value]="0"></mat-option>
                    <ng-container *ngFor="let num of orderNum; let n = index">
                      <mat-option [value]="n+1">
                        {{ n+1 }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td class="order_no category">
                <mat-form-field class="input-select no-line" *ngIf="content.category_id>0">
                  <mat-select [formControlName]="prefixCat + content.id.toString()" panelWidth=""
                  (selectionChange)="onSortChange($event.value, content.id, false, content.category_id)">
                    <mat-option [value]="0"></mat-option>
                    <ng-container *ngFor="let num of orderNum; let n = index">
                      <mat-option [value]="n+1">
                        {{ n+1 }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td *ngIf="content.release_status == Constant.joReleaseStatusNoRelease" class="status">
                <div class="no-release">未公開</div>
              </td>
              <td *ngIf="content.release_status == Constant.joReleaseStatusRelease" class="status">
                <div class="release">公開中</div>
              </td>
              <td *ngIf="content.release_status == Constant.joReleaseStatusStop" class="status">
                <div class="stop">非公開</div>
              </td>
              <td class="title">{{ content.title }}</td>
            </tr>
          </ng-container>
        </table>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="!btnEnable || isSaving" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
