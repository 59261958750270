import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { MatDialog } from '@angular/material/dialog';
import { MessageEditDialogComponent } from '../dialog/message-edit-dialog/message-edit-dialog.component';
import { GeneralYesNoDialogComponent } from '../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { TalentListCondition } from '../model/talent-list-condition';
import { MessageSelectTalentDialogComponent } from '../dialog/message-select-talent-dialog/message-select-talent-dialog.component';

@Component({
  selector: 'app-multi-message',
  templateUrl: './multi-message.component.html',
  styleUrls: ['./multi-message.component.css']
})
export class MultiMessageComponent implements OnInit {
  public Constant = Constant;
  public showSpinner = false;
  public isSearching = false;
  public messages;
  private apiPath = '/multimessage';
  private timer;
  private isNextData = true;
  private currentOffset = 0;
  private scrollElement;
  private itemPerPage = 10;

  constructor(
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private elementRef: ElementRef,
  ) {
    this.title.setTitle(Constant.pageTitleMultiMessage + Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.isNextData) {
      return;
    }

    // スクロール最下部で次データ取得
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (window.pageYOffset + window.innerHeight - 150 >= this.scrollElement.clientHeight) {
        this.getNextData();
      }
    }, 100);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    // 一覧取得
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.getMultiMessageData();

    // スクロールコンテナの設定
    this.scrollElement = this.elementRef.nativeElement.querySelector('.container');
  }

  // 新規作成
  onPost(): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    // 送信先タレント種別選択ダイアログ表示
    const dialogRef = this.dialog.open(MessageSelectTalentDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(type => {
      if (type >= 0) {
        this.postMultiMessage(type);
      }
    });
  }

  // 除外リスト
  onExceptInfo(): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['/message_except']);
  }

  // メッセージ編集
  onEditMessage(idx): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const text = this.messages[idx].message;
    const title = this.messages[idx].mail_title;
    const id = this.messages[idx].id;
    const editFlg = this.messages[idx].send_date ? false: true;
    const type = this.messages[idx].talent_csv_flag === 0 ? Constant.multiMsgEditTypeMessage : Constant.multiMsgEditTypeCsv;
    this.showEditDialog(type, title, text, editFlg, id);
  }

  // メモ編集
  onEditMemo(idx): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const title = this.messages[idx].memo_title;
    const text = this.messages[idx].memo;
    const id = this.messages[idx].id;
    const editFlg = true;
    this.showEditDialog(Constant.multiMsgEditTypeMemo, title, text, editFlg, id);
  }

  // 送信先編集
  onEditTalent(idx) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    localStorage.setItem(Constant.lsMultiMessageDetail, JSON.stringify(this.messages[idx]));
    this.router.navigate(['/message_talent']);
  }

  // メニュー：送信
  onSend(idx) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    localStorage.setItem(Constant.lsMultiMessageDetail, JSON.stringify(this.messages[idx]));
    this.router.navigate(['/message_send']);
  }

  // メニュー：コピー
  onCopy(idx) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.multiMsgConfirmCopy }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        const id = this.messages[idx].id;
        this.copyMultiMessage(id);
      }
    });
  }

  // メニュー：削除
  onDel(idx) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.multiMsgComfirmDel }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        const id = this.messages[idx].id;
        this.deleteMultiMessage(id);
      }
    });
  }

  // 送信済みタレントリストへ
  onToList(id, readId) {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.tlMultiMsgSendType,
      id: id
    };
    if (readId) {
      condition[1] = {
        type: Constant.tlMultiMsgActionType,
        id: readId
      };
    } else {
      condition[1] = {
        type: Constant.tlMultiMsgActionType,
        id: Constant.multiMsgActionRead
      };
      condition[2] = {
        type: Constant.tlMultiMsgActionType,
        id: Constant.multiMsgActionUnRead
      };
    }
    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    window.open('list');
  }

  // テキスト省略
  ellipsisText(text, maxLen) {
    const len = text.length;

    if (len <= maxLen) {
      return text;
    } else {
      return text.substr(0, maxLen) + '… ';
    }
  }

  // 既読率計算
  calcReadedRatio(read, unread) {
    return Math.round(read / (read + unread) * 100);
  }

  /* API */
  // 一覧取得
  private getMultiMessageData() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(this.apiPath + '/page/' + this.currentOffset, options).then(res => {
        if (!this.messages) {
          this.messages = res.data;
        } else {
          this.messages.push(...res.data);
        }

        // 次データ有無の設定
        if (res.data.length < this.itemPerPage) {
          this.isNextData = false;
        }

        this.showSpinner = false;
        this.isSearching = false;
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.isSearching = false;
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  // 新規作成
  private postMultiMessage(type) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    this.clearData();
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {type: type};
      this.commonUtil.apiPost(this.apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgNoticeNew, Constant.toastShowMiliSec);
        this.getMultiMessageData();
      })
      .catch(err => {
        if (err.status === Constant.NG) {
          this.toastUtil.showErrorToast('', Constant.msgNoticeNewFailed, Constant.toastShowMiliSec);
        } else {
          this.toastUtil.showErrorToast('', Constant.msgNoticeNewFailedRetry, Constant.toastShowMiliSec);
        }
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
      });
    });
  }

  // 削除
  private deleteMultiMessage(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    this.clearData();
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {id: id};
      this.commonUtil.apiDel(this.apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgNoticeDel, Constant.toastShowMiliSec);
        this.getMultiMessageData();
      })
      .catch(err => {
        if (err.status === Constant.NG) {
          this.toastUtil.showErrorToast('', Constant.msgNoticeDelFailed, Constant.toastShowMiliSec);
        } else {
          this.toastUtil.showErrorToast('', Constant.msgNoticeDelFailed2, Constant.toastShowMiliSec);
        }
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.getMultiMessageData();
      });
    });
  }

  // コピー
  private copyMultiMessage(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    this.clearData();
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {id: id};
      this.commonUtil.apiPost(this.apiPath + '/copy', options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
        this.getMultiMessageData();
      })
      .catch(err => {
        if (err.status === Constant.NG) {
          this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
        } else {
          this.toastUtil.showErrorToast('', Constant.msgCopyErrorRetry, Constant.toastShowMiliSec);
        }
        this.commonUtil.debug().log(err);
        this.getMultiMessageData();
      });
    });
  }

  /* private */
  // 編集ダイアログ表示
  private showEditDialog(type, title, text, editFlg, id) {
    const dialogRef = this.dialog.open(MessageEditDialogComponent, {
      width: Constant.offerDialogWidth,
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        id: id,
        title: title,
        text: text,
        editFlg: editFlg
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clearData();
        this.getMultiMessageData();
      }
    });
  }

  // スクロールで次のデータ取得
  private getNextData() {
    if (this.showSpinner || this.isSearching || !this.commonUtil.checkOnline()) {
      return;
    }

    this.currentOffset += this.itemPerPage;
    this.isSearching = true;
    this.getMultiMessageData();
  }

  // 一覧クリア
  private clearData() {
    this.messages = null;
    this.currentOffset = 0;
    this.isNextData = true;
  }
}
