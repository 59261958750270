import { Constant } from './../constant';
import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { ToastUtil } from '../util/toast-util';
import { CommonUtil } from './../util/common-util';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PassCompDialogComponent } from './../dialog/pass-comp-dialog/pass-comp-dialog.component';
import { Auth } from '@aws-amplify/auth';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css'],
  providers: [Title]
})
export class PasswordChangeComponent implements OnInit {
  public readonly Constant = Constant;
  public changeForm: FormGroup;
  public showSpinner = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private toastUtil: ToastUtil,
    private commonUtil: CommonUtil,
    private title: Title,
    private dialog: MatDialog
  ) {
    this.title.setTitle(Constant.pageTitlePasswordChange + Constant.pageTitleCommon);
  }

  ngOnInit() {
    // ユーザー情報が取得できなければ、ログイン画面へ遷移
    if (!this.auth.user) {
      this.auth.signOut();
      return;
    }

    this.changeForm = new FormGroup(
      {
        newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
        newPasswordConfirm: new FormControl('', [Validators.required])
      },
      this.pwdMatchValidator
    );
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログイン済み確認
    this.auth.isAuthenticated().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
        this.router.navigate(['map']);
      }
    });
  }

  onSubmit(value: any) {
    this.showSpinner = true;

    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.showError(Constant.msgNetworkError);
      return;
    }

    const password = value.newPassword;
    this.auth.completeNewPassword(password).subscribe(result => {
      if (result) {
        // パスワード変更完了ダイアログ表示
        this.showPassCompDialog();
      } else if (this.auth.error && this.auth.error.code === 'InvalidPasswordException') {
        this.showError(Constant.msgPassInvalid);
      } else {
        this.showError(Constant.msgPassChangeError);
      }
    });
  }

  // 利用規約
  onAgreement() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionAgreement);
    window.open(Constant.footerUrlAgreement);
  }

  // プライバシーポリシー
  onPrivacy() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPolicy);
    window.open(Constant.footerUrlPrivacy);
  }

  get newPassword() {
    return this.changeForm.get('newPassword');
  }

  get newPasswordConfirm() {
    return this.changeForm.get('newPasswordConfirm');
  }

  pwdMatchValidator(frm: FormGroup) {
    if (frm.get('newPassword').value !== frm.get('newPasswordConfirm').value) {
      return { mismatch: true };
    }
    return null;
  }

  // トースト表示
  private showError(msg: string) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
    this.showSpinner = false;
  }

  // パスワード変更完了ダイアログ表示
  private showPassCompDialog() {
    const dialogRef = this.dialog.open(PassCompDialogComponent, {
      width: '400px',
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.auth
          .companyLogin()
          .then(res => {})
          .catch(err => {
            if (this.commonUtil.apiStatusOther(err)) {
              // メンテナンスエラー、バージョンエラー時はawsからのログアウトのみ
              Auth.signOut();
            } else {
              this.showError(Constant.msgMailOrPassWrong);

              // 企業ログインエラーなので、ログアウトする
              this.auth.signOut();
            }
          });
      }
    });
  }
}
