import { Injectable } from "@angular/core";
import { Auth } from "@aws-amplify/auth";
import { AuthService } from "../auth/auth.service";
import { CommonUtil } from '../util/common-util';
import { Constant } from './../constant';
import { ToastUtil } from './toast-util';

import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed')
const Embed = Quill.import('blots/embed');

export class TocBlot extends BlockEmbed {
    static create (value) {
      // brタグの削除
      value = value.replaceAll('<br class="Apple-interchange-newline">', '');
      let node = super.create();
      node.innerHTML = value;
      node.setAttribute('contenteditable', 'false');
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }

    length () {
      return 1
    }
}

TocBlot['blotName'] = 'toc-div';
TocBlot['className'] = 'toc-div';
TocBlot['tagName'] = 'div';
Quill.register(TocBlot);

export class OgpLoadingBlot extends BlockEmbed {
  static create (value) {
    let node = super.create();
    node.setAttribute('data-src', value);
    node.innerHTML = '<div class="loading"></div>';
    return node;
  }

  static value(node) {
    return node;
  }
}

OgpLoadingBlot['blotName'] = 'ogp-loading';
OgpLoadingBlot['className'] = 'ogp-loading';
OgpLoadingBlot['tagName'] = 'div';
Quill.register(OgpLoadingBlot);


export class OgpBlot extends BlockEmbed {
  static create (value) {
    // brタグの削除
    value = value.replaceAll('<br class="Apple-interchange-newline">', '');
    let node = super.create();

    node.setAttribute('contenteditable', 'false');
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }

  length () {
    return 1
  }
}

OgpBlot['blotName'] = 'ogp-div';
OgpBlot['className'] = 'ogp-div';
OgpBlot['tagName'] = 'div';
Quill.register(OgpBlot);


export class MyImageBlot extends BlockEmbed {
  static create(value) {
    if (!value['data-path']) {
      const pTag = document.createElement('p');
      pTag.innerHTML = '<br>'
      return pTag;
    }
    const node = super.create();
    node.setAttribute('data-path', value['data-path']);
    node.setAttribute('src', value.src);
    return node;
  }
  static value(node) {
    return {
        'data-path': node.getAttribute('data-path'),
        src: node.getAttribute('src') ? node.getAttribute('src') : 'data:image/png;base64,'
    };
  }
}

MyImageBlot['blotName'] = 'image';
MyImageBlot['tagName'] = 'img';
Quill.register(MyImageBlot);

const Break = Quill.import('blots/break');
class SmartBreak extends Break {
  length () {
    return 1
  }
  value () {
    return '\n'
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref)
  }
}
SmartBreak['blotName'] = 'sbreak';
SmartBreak['tagName'] = 'br';
Quill.register(SmartBreak)


const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')
class PlainClipboard extends Clipboard {
  onPaste (e) {
    if (e.defaultPrevented || !this.quill.isEnabled()) return;
    let range = this.quill.getSelection();
    const html = e.clipboardData.getData('text/html')
    const text = e.clipboardData.getData('text/plain')
    let delta = new Delta().retain(range.index);
    let scrollTop = this.quill.scrollingContainer.scrollTop;
    this.container.focus();
    this.quill.selection.update('silent');
    setTimeout(() => {
      if (html.indexOf('<!DOCTYPE html PUBLIC "-//W3C//DTD HTML')>-1
      ||html.indexOf('<html xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"')>-1) {
        // power point テキストのみ貼り付け
        const pastedDelta = this.convert(text.replaceAll('\v', '\n'))
        delta = delta.concat(pastedDelta).delete(range.length)
      } else {
        delta = delta.concat(this.convert()).delete(range.length);
      }
      this.quill.updateContents(delta, 'user');
      // range.length contributes to delta.length()
      this.quill.setSelection(delta.length() - range.length, 'silent');
      this.quill.scrollingContainer.scrollTop = scrollTop;
      this.quill.focus();
    }, 1);
  }
}
Quill.register('modules/clipboard', PlainClipboard, true)


@Injectable()
export class QuillUtil {
  public loadCnt = 0;
  public loadErr = false;

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil
  ) {}

  // 再表示時の画像、OGP表示
  checkReloadQuill(quill, editFlg, nativeElm?) {
    this.loadCnt = 0
    this.loadErr = false;
    if (!nativeElm) {
      nativeElm = document
    }
    const contents = quill.getContents();
    contents.ops.forEach(element => {
      if (element.insert?.image?.['data-path'] && element.insert.image['data-path'] !== 'null') {
        // 画像のURL取得
        this.getImageUrl(element.insert.image['data-path'], nativeElm);
      } else if (element.insert['ogp-div']) {
        // OGP情報取得
        const url = element.insert['ogp-div'];
        this.getOgpHtml(url).then(html => {
          this.setOgpData(html, url, nativeElm);

        }).catch(err => {
          // OGP取得エラーの場合、何もしない
        })
      }
    });

    if (editFlg) {
      // 既存コンテンツの改行をブロックに分ける
      this.updatePtagBr(quill, nativeElm);
    }

  }

  // 画像のURL取得
  private getImageUrl(path, nativeElm) {
    if (this.loadErr) {
      return;
    }
    // 画像のURL取得
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const urlApiPath = '/prcontent/editor/url';
      options['body'] = {
        path: path
      };
      this.commonUtil
        .apiPost(urlApiPath, options)
        .then(res => {
          if (res.data.url) {
            this.updateImageUrl(path, res.data.url, nativeElm);
            this.loadCnt++;
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.loadErr = true;
        });
    });
  }

  // 画像のsrc差し替え
  private updateImageUrl(path, url, nativeElm) {
    const imgDiv = nativeElm.querySelectorAll(
      '.ql-container img[data-path]'
    );
    if (imgDiv.length > 0) {
      imgDiv.forEach(img => {
        const datapath = img.getAttribute('data-path')
        if (datapath === path) {
          img.setAttribute('src', url);
        }
      })
    }
  }

  // OGP取得
  getOgpHtml(url) {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then(session => {
        const apiPath = '/ogp';
        const options = this.auth.createApiHeader(session);
        options['body'] = {
          url: url
        }
        this.commonUtil.apiPost(apiPath, options).then(res => {
          const html = this.createOgpHtml(url, res.data);
          resolve(html);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          reject(err);
        });
      });
    });

  }

  // OGP取得後、HTML作成
  private createOgpHtml(url, ogp) {
    let ogpHtml = '<a href="' + url + '" target="_blank"><strong>' + ogp['og-title'] + '</strong>';
    if (ogp['og-description']) {
      ogpHtml += '<span class="desc">' + ogp['og-description'] + '</span>';
    }
    ogpHtml += '<span class="url">' + url + '</span></a>';
    ogpHtml += '<a href="' + url + '" target="_blank" class="og-image"><img src="';
    if (ogp['og-image']) {
      ogpHtml += ogp['og-image'];
    } else {
      ogpHtml += 'assets/common/ogp-empty.svg';
    }
    ogpHtml += '"></a>';

    return ogpHtml;
  }

  // OGPのHTMLを反映
  private setOgpData(html, url, nativeElm) {
    const ogpDiv = nativeElm.querySelectorAll(
      '.ql-container .ogp-div'
    );
    if (ogpDiv.length > 0) {
      ogpDiv.forEach(div => {
        if (div.innerHTML === url) {
          div.innerHTML = html;
        }
      })
    }
  }

  // pタグ内の改行コード削除（引用解除時の改行をクリアするため）
  updatePtagBr(quill, nativeElm?) {
    if (!nativeElm) {
      nativeElm = document
    }
    const pBr = nativeElm.querySelectorAll(
      '.ql-container p br, .ql-container h3 br, .ql-container h4 br, .ql-container ul br, .ql-container ol br'
    );
    if (pBr.length > 0) {
      pBr.forEach(br => {
        if (br.parentElement && br.parentElement.innerHTML !== '<br>') {
          const blot = Quill.find(br);
          if (blot) {
            const idx = quill.getIndex(blot)
            quill.deleteText(idx, 1)
            quill.insertText(idx, Constant.elemNewLine);
          }
        }
      })
     }
  }
}
