import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from './../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { ImageUtil } from '../../util/image-util';
import { MomentUtil } from '../../util/moment-util';
import { Constant } from './../../constant';
import { environment } from '../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';

@Component({
  selector: 'app-import-talent-dialog',
  templateUrl: './import-talent-dialog.component.html',
  styleUrls: ['./import-talent-dialog.component.css']
})
export class ImportTalentDialogComponent implements OnInit {
  public readonly Constant = Constant;
  public form: FormGroup;
  public showSpinner = false;
  public isSaving = false;
  public tagData;

  public fileName = null;
  private fileData = null;
  private uploadFilePath = null;

  // validation
  public maxLenFileFrom = 50;

  // カレンダー最小値、最大値
  public dateMin = new Date(1930, 0, 1);
  public dateMax = new Date();

  @ViewChild('fileInput')
  private fileInput: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<ImportTalentDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private momentUtil: MomentUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.getSelectData();
  }

  onSend(): void {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.toastUtil.clearAllShowingToast();

    // 確認ダイアログ
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.csvTalentConfirmFileUp }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.isSaving = true;
        this.fileUpload();
      }
    });
  }

  // ファイル選択
  onChangeFile(evt) {
    const file = evt.target.files[0];

    // ファイル形式チェック
    if (file.type !== Constant.fileFormatCsv) {
      this.toastUtil.showErrorToast('', Constant.msgErrorInvalidFile, Constant.toastShowMiliSec);
      this.fileInput.nativeElement.value = '';
      return;
    }

    this.fileData = file;
    this.fileName = this.fileData.name;
  }

  // ファイル削除
  onFileDelete() {
    this.fileName = Constant.empty;
    this.fileData = null;
    this.fileInput.nativeElement.value = '';
  }

  // ファイルダウンロード
  onFileDownload() {
    saveAs(this.fileData, this.fileName);
  }

  // タグ設定開く
  onClickAccordion(accordionId) {
    const accordionElm = this.elementRef.nativeElement.querySelector('#' + accordionId + '-accordion');
    const btnOpenElm = this.elementRef.nativeElement.querySelector('#' + accordionId + '-open');
    const btnCloseElm = this.elementRef.nativeElement.querySelector('#' + accordionId + '-close');

    if (accordionElm.clientHeight > 0) {
      this.renderer.setStyle(accordionElm, Constant.elemPropMaxHeight, Constant.elementValHeightNone);
      this.renderer.setStyle(btnOpenElm, Constant.elemPropDisplay, Constant.elementValBlock);
      this.renderer.setStyle(btnCloseElm, Constant.elemPropDisplay, Constant.elementValNone);
    } else {
      this.renderer.setStyle(accordionElm, Constant.elemPropMaxHeight, Constant.elementValHeight1500);
      this.renderer.setStyle(btnOpenElm, Constant.elemPropDisplay, Constant.elementValNone);
      this.renderer.setStyle(btnCloseElm, Constant.elemPropDisplay, Constant.elementValBlock);
    }
  }

  // 接点タグのチェックを外す
  onContactCheck(checked, id) {
    // 日付入力欄をクリア
    if (!checked) {
      this.form.controls['contactdate_' + id].reset();
    }
  }

  // 送信ボタンの無効化制御
  disabledSaveBtn() {
    if (this.showSpinner || this.form?.invalid || this.isSaving || !this.fileData) {
      return true;
    }

    const len = this.tagData.contacttag.length;
    for (let i = 0; i < len; i++) {
      const tag = this.tagData.contacttag[i];
      if (this.form.value['contacttag_' + tag.id] && !this.form.value['contactdate_' + tag.id]) {
        return true;
      }
    }

    return false;
  }


  /* private */
  // 選択項目取得
  private getSelectData() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const apiPath = '/import/talent/master';
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          this.tagData = res.data;
          this.showSpinner = false;
          this.initForm();
        })
        .catch(err => {
          this.showSpinner = false;
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // フォーム作成
  private initForm() {
    const formControl = {};

    formControl['file_from'] = new FormControl(Constant.empty, [Validators.required, Validators.maxLength(this.maxLenFileFrom)]);
    this.tagData.talenttag.forEach(tag => {
      formControl['talenttag_' + tag.id] = new FormControl(Constant.empty);
    });
    this.tagData.contacttag.forEach(tag => {
      formControl['contacttag_' + tag.id] = new FormControl(Constant.empty);
      formControl['contactdate_' + tag.id] = new FormControl();
    });

    this.form = new FormGroup(formControl);
  }

  // ファイル送信実行
  private fileUpload() {
    if (!this.commonUtil.checkOnline()) {
      this.isSaving = false;
      return;
    }

    const config = environment.amplify.Storage.talentcsv;
    this.imageUtil.uploadTalentCsvToS3(this.fileData, config).then(file_path => {
      this.uploadFilePath = file_path;
      this.dataUpdate();

    })
    .catch(err => {
      let msg = Constant.msgNoticeSendFailedRetry;
      if (err === 'file') {
        msg = Constant.msgNoticeSendFailed;
      }
      this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
      this.isSaving = false;
    });
  }

  // データ送信実行
  private dataUpdate() {
    const talenttag = [];
    this.tagData.talenttag.forEach(tag => {
      if (this.form.value['talenttag_' + tag.id]) {
        talenttag.push(tag.id);
      }
    });

    const contacttag = [];
    this.tagData.contacttag.forEach(tag => {
      if (this.form.value['contacttag_' + tag.id]) {
        const date = this.momentUtil.getDateFormat(this.form.value['contactdate_' + tag.id]);
        contacttag.push({id: tag.id, date: date});
      }
    });

    const data = {
      file_path: this.uploadFilePath,
      file_name: this.fileName,
      file_from: this.commonUtil.replaceSpace(this.form.value.file_from),
      talenttag: talenttag,
      contacttag: contacttag,
    };

    const apiPath = '/import/talent';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = data;
      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.toastUtil.showInformationToast(
            Constant.empty,
            Constant.msgNoticeSend,
            Constant.toastShowMiliSec
          );
          this.dialogRef.close(res.status);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.status === Constant.NG) {
            this.toastUtil.showErrorToast(Constant.empty, Constant.msgNoticeSendFailed, Constant.toastShowMiliSec);
            this.dialogRef.close(err.status);
          } else {
            this.toastUtil.showErrorToast(Constant.empty, Constant.msgNoticeSendFailedRetry, Constant.toastShowMiliSec);
            this.isSaving = false;
          }

        });
    });

  }

}
